import React, { useState, useEffect } from 'react'
import fire from '../../firebase'
import 'firebase'

import { contains } from 'jquery';

const $ = require('jquery');

function Content() {
	const [LineUser, setLineUser] = useState({})

	const db = fire.app('realtime').firestore();

	useEffect(async () => {
		await db.collection('linenotifyToken').where(fire.firestore.FieldPath.documentId(), '==', localStorage.user).onSnapshot(querySnapshot => {
			if (querySnapshot.docs.length > 0) {
				querySnapshot.docChanges().forEach(change => {
					if (change.type == "added") {
						let obj = {
							target: change.doc.data().target,
							targetType: change.doc.data().targetType == "USER" ? "1 on 1 Chat" : "Group Chat",
							perm: change.doc.data().perm
						}
						setLineUser(obj)
						$('.lineInfoLoading').addClass('d-none')
						$('#lineNotifyBtn').removeClass('d-none')
						$('#removeNotifyBtn').removeClass('d-none')
						$('.toggleBtn').removeClass('d-none')
					} else if (change.type == "modified") {
						let obj = {
							target: change.doc.data().target,
							targetType: change.doc.data().targetType == "USER" ? "1 on 1 Chat" : "Group Chat",
							perm: change.doc.data().perm
						}
						setLineUser(obj)
						$('.lineInfoLoading').addClass('d-none')
						$('#lineNotifyBtn').removeClass('d-none')
						$('.toggleBtn').removeClass('d-none')
					}
				})
			} else {
				$('.lineInfoLoading').addClass('d-none')
				$('#lineNotifyBtn').removeClass('d-none')
				$('#removeNotifyBtn').removeClass('d-none')
				$('.toggleBtn').removeClass('d-none')
				setLineUser({ user: "Not set" })
			}
		});
	}, [])

	const activeSubmenu = (i) => {
		$('.submenu .sidebar-nav .sidebar-item').removeClass('active')
		$('.submenu:not(.collapse) .sidebar-nav li')[i].classList.add('active')
		$('.submenu.collapse .sidebar-nav li')[i].classList.add('active')
	}

	const activeSubnav = (e) => {
		$('.subnav .nav .nav-item').removeClass('active')
		e.target.parentElement.classList.add('active')
	}

	const toggleActive = (e) => {
		const cls = e.target.classList.value
		if (cls.toLowerCase().includes('active')) {
			e.target.classList.remove('active')
		} else {
			e.target.classList.add('active')
		}
	}

	const LineNotify = async () => {
		window.location.href = "https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=OUOfT6CtFsZ3YzxHSvLRAg&redirect_uri=https://us-central1-dixell-iot.cloudfunctions.net/line_notify&scope=notify&state=" + localStorage.user
	}

	const SetPermissionLine = (e) => {
		toggleActive(e)
		db.collection('linenotifyToken').doc(localStorage.user).set({
			perm: e.target.className.includes('active') ? true : false,
		}, {
			merge: true
		});
	}

	const RemoveLine = (e) => {
		db.collection('linenotifyToken').doc(localStorage.user).delete()
	}

	return (
		<>
			<div className="container-fluid p-0">
				<div className="row title mb-4 pb-3 mx-0 border-bottom">
					<div className="col-12 px-0">
						<h3 className="align-middle">Notification settings</h3>
						{/* <h3 className="d-none d-md-inline-flex align-middle">Notification settings</h3>

						<div className="d-inline-flex d-md-none align-middle" data-toggle="collapse" href="#submenuCollapse" onClick={()=>{$('.fa-caret-up').toggleClass('fa-rotate-180');}}>
							<h3 className="d-inline-flex align-middle">Notification settings</h3>
							<div className="px-2 pt-1 ml-2">
								<i className="fa fa-caret-up d-inline-block header-menu-icon-rotate fa-rotate-180" style={{ pointerEvents: 'none' }} ></i>
							</div>
						</div>
						
						<div className="submenu collapse" id="submenuCollapse">
							<ul className="sidebar-nav">
								<li className="sidebar-item mx-2 active" onClick={()=>{activeSubmenu(0)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Notifications</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(1)}}>
									<a className="sidebar-link text-muted bg-transparent px-3" >
										<span className="align-middle" style={{ pointerEvents: 'none' }}>List</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(2)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Charts</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(3)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Maps</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(4)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Alarm history</span>
									</a>
								</li>
							</ul>
						</div> */}
					</div>
				</div>
				<div className="row">
					<div className="col-12 mx-auto d-flex">
						<div className="flex-grow-1 text-left px-0 px-xl-3">
							<div className="d-flex mx-0 mx-sm-3 py-2">
								<div className="text-center align-top" style={{ width: '28px' }}>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className="feather align-middle d-inline-block w-auto mt-1" style={{ height: '20px', pointerEvents: 'none' }}>
										<path fill="#20B24B" stroke="" strokeWidth="3" d="M174,200H26c-14.4,0-26-11.6-26-26V26C0,11.6,11.6,0,26,0h148c14.4,0,26,11.6,26,26v148 C200,188.4,188.4,200,174,200z" />
										<path fill="#fff" stroke="" strokeWidth="3" d="M172.5,91.2c0-32.6-32.7-59.1-72.8-59.1c-40.1,0-72.8,26.5-72.8,59.1c0,29.2,25.9,53.7,60.9,58.3 c2.4,0.5,5.6,1.6,6.4,3.6c0.7,1.8,0.5,4.7,0.2,6.6c0,0-0.9,5.1-1,6.2c-0.3,1.8-1.5,7.2,6.3,3.9c7.8-3.3,41.9-24.7,57.2-42.3h0 C167.4,116,172.5,104.2,172.5,91.2" />
										<path fill="#20B24B" d="M84.9,75.5h-5.1c-0.8,0-1.4,0.6-1.4,1.4v31.7c0,0.8,0.6,1.4,1.4,1.4h5.1c0.8,0,1.4-0.6,1.4-1.4V76.9 C86.3,76.1,85.7,75.5,84.9,75.5" />
										<path fill="#20B24B" d="M120,75.5h-5.1c-0.8,0-1.4,0.6-1.4,1.4v18.9L99,76.1c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0 c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0 c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0 c0,0,0,0,0,0h-5.1c-0.8,0-1.4,0.6-1.4,1.4v31.7c0,0.8,0.6,1.4,1.4,1.4h5.1c0.8,0,1.4-0.6,1.4-1.4V89.8l14.6,19.7 c0.1,0.1,0.2,0.3,0.4,0.3c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0 c0,0,0,0,0,0c0.1,0,0.2,0,0.4,0h5.1c0.8,0,1.4-0.6,1.4-1.4V76.9C121.5,76.1,120.8,75.5,120,75.5" />
										<path fill="#20B24B" d="M72.6,102.1H58.7V76.9c0-0.8-0.6-1.4-1.4-1.4h-5.1c-0.8,0-1.4,0.6-1.4,1.4v31.7v0c0,0.4,0.2,0.7,0.4,1 c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0.2,0.6,0.4,1,0.4h0h20.4c0.8,0,1.4-0.6,1.4-1.4v-5.1C74,102.7,73.4,102.1,72.6,102.1" />
										<path fill="#20B24B" d="M148.2,83.4c0.8,0,1.4-0.6,1.4-1.4v-5.1c0-0.8-0.6-1.4-1.4-1.4h-20.4h0c-0.4,0-0.7,0.2-1,0.4 c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.3-0.4,0.6-0.4,1v0v31.7v0c0,0.4,0.2,0.7,0.4,1c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0.2,0.6,0.4,1,0.4 h0h20.4c0.8,0,1.4-0.6,1.4-1.4v-5.1c0-0.8-0.6-1.4-1.4-1.4h-13.9v-5.4h13.9c0.8,0,1.4-0.6,1.4-1.4v-5.1c0-0.8-0.6-1.4-1.4-1.4 h-13.9v-5.4H148.2z" />
									</svg>
								</div>
								<div className="flex-fill align-middle ml-2">
									<div className="flex-fill w-100">
										<p className="text-custom-important mb-1" style={{ fontSize: '125%' }}>
											<span className="align-middle">Line notifications</span>
										</p>
										<span className="text-muted d-none d-sm-flex">Manage your notifications via Line message</span>
									</div>
									<div className="row mt-4">
										<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
											<p className="text-custom mb-0">Account</p>
										</div>
										<div className="col-12 col-md-8 col-xl-10">
											<div className="d-flex mb-4 justify-content-between justify-content-sm-start" style={{ animation: 'opafrom0 0.5s ease' }}>
												{
													Object.keys(LineUser).length > 0 && LineUser.user ?
														<p className="mb-1 mb-sm-0 text-truncate ml-2 ml-sm-0 ml-md-2 ml-xl-0">
															<span className="text-custom-light text1">Not set</span>
														</p>
														:
														<p className={LineUser.target ? localStorage.perm == "Y" ? "mb-1 mb-sm-0  text-truncate ml-2 ml-sm-0 ml-md-2 ml-xl-0" : "mb-1 text-truncate ml-2 ml-sm-0 ml-md-2 ml-xl-0" : "d-none"} id="accountLineShow">
															<span className="text-custom-light text1">{LineUser.targetType}</span>
															<i className={LineUser.targetType ? "fa fa-caret-right text-custom mx-3" : ""}></i>
															<span className="text-custom-important">{LineUser.target}</span>
														</p>
												}
												{
													localStorage.perm == "Y" ?
														<button className={LineUser.target ? "btn btn-snapX-login-clean text-sm ml-0 ml-sm-4 d-none" : "btn btn-snapX-login-clean text-sm ml-0 ml-sm-4 d-none"}
															style={{ letterSpacing: "0px", marginTop: '-3.5px' }}
															onClick={LineNotify}
															id="lineNotifyBtn">
															<span>{LineUser.target ? "Change" : "Set account"}</span>
														</button>
														:
														<>
														</>
												}
												{
													localStorage.perm == "Y" && LineUser.target ?
														<button className="btn btn-cancel text-sm ml-0 ml-sm-2 d-none"
															style={{ letterSpacing: "0px", marginTop: '-3.5px' }}
															// onClick={RemoveLine}
															id="removeNotifyBtn"
															data-toggle="modal" data-target="#confirmRemoveLine"
														>
															<strong>Remove</strong>
														</button>
														:
														<>
														</>
												}
												<div className="w-100 ml-2 ml-sm-0 ml-md-2 ml-xl-0 mb-1 lineInfoLoading">
													<i className="fa fa-circle-notch fa-spin fa-fw" style={{ width: '100px' }}></i>
												</div>
											</div>
										</div>
									</div>
									<div className="row mt-2 mt-md-4">
										<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
											<p className="text-custom mb-0">Permission</p>
										</div>
										<div className="col-12 col-md-8 col-xl-10">
											<div className="d-flex mb-4">
												{
													localStorage.perm == "Y" ?
														<>
															<div className="flex-shrink-1 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
																<i className="fa fa-circle-notch fa-spin fa-fw lineInfoLoading" style={{ width: '35px' }}></i>
																<button className={LineUser.perm ? "toggle-switch-custom rounded-pill active d-none toggleBtn" : "toggle-switch-custom rounded-pill d-none toggleBtn"} onClick={SetPermissionLine}><div className="knob"></div></button>
															</div>
															<div className="w-100 ml-3 ml-sm-4">
																<p className="text-custom-important mb-1 mb-sm-0">Get notifications via Line</p>
																<p className="text-muted mb-0  d-none d-sm-flex">If this setting is turn on, SnapX will send your notifications to your Line Account</p>
															</div>
														</>
														:
														<>
															<div className="flex-shrink-1 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
																<i className="fa fa-circle-notch fa-spin fa-fw lineInfoLoading" style={{ width: '22px' }}></i>
																{
																	LineUser.perm ?
																		<i className="fa fa-check ml-2 text-custom-enable d-none toggleBtn"></i>
																		:
																		<i className="fa fa-times ml-2 mr-1 text-danger d-none toggleBtn"></i>
																}
															</div>
															<div className="w-100 ml-3 ml-sm-4">
																<p className="text-custom-important mb-1 mb-sm-0">Get notifications via Line</p>
																<p className="text-muted mb-0 d-none d-sm-flex">If this setting is turn on, SnapX will send your notifications to your Line Account</p>
															</div>
														</>
												}
											</div>
										</div>
									</div>
									{/* <div className="row mt-2 mt-md-4">
										<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
											<p className="text-custom mb-0">Your preferences</p>
										</div>
										<div className="col-12 col-md-8 col-xl-10">
											<div className="d-flex mb-4">
												<div className="flex-shrink-1 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
													<button className="toggle-switch-custom rounded-pill" onClick={toggleActive}><div className="knob"></div></button>
												</div>
												<div className="w-100 ml-3 ml-sm-4">
													<p className="text-custom-important mb-1 mb-sm-0">Alarm activity</p>
													<p className="text-muted mb-0 d-none d-sm-flex">Notify me about system and devices alarm activity</p>
												</div>
											</div>
											<div className="d-flex mb-4">
												<div className="flex-shrink-1 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
													<button className="toggle-switch-custom rounded-pill" onClick={toggleActive}><div className="knob"></div></button>
												</div>
												<div className="w-100 ml-3 ml-sm-4">
													<p className="text-custom-important mb-1 mb-sm-0 align-middle">Devices activity</p>
													<p className="text-muted mb-0 d-none d-sm-flex">Notify me about devices activity</p>
												</div>
											</div>
										</div>
									</div> */}

									{/* <div className="row mt-2 mt-md-4">
										<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
											<p className="text-custom mb-0">Lin account</p>
										</div>
										<div className="col-12 col-md-8 col-xl-10">
											<div className="d-flex mb-4" id="changePassFormBtn" style={{ animation: 'opafrom0 0.5s ease' }}>
												<div className="flex-shrink-1 mx-0 mx-md-2 mx-xl-0">
													<button className="btn btn-snapX-login-clean text-sm" style={{ letterSpacing: "0px" }}>Link new account</button>
												</div>
											</div>
										</div>
									</div> */}
								</div>
							</div>

							<div className="flex-fill mx-3 border-top d-none mb-2 mb-md-0" style={{ height: '1px' }}></div>
							<div className="d-none mx-3 py-2 pt-4 pt-md-2">
								{/* <div className="flex-fill mx-3 border-top d-flex d-md-none mb-2 mb-md-0" style={{ height: '1px' }}></div>
							<div className="d-flex mx-3 py-2 pt-4 pt-md-2"> */}
								<div className="text-center align-top" style={{ width: '28px' }}>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className="feather align-middle d-inline-block w-auto mt-0 mt-sm-1" style={{ height: '20px', pointerEvents: 'none' }}>
										<linearGradient id="path1178_1_" gradientUnits="userSpaceOnUse" x1="44.329" y1="196.1646" x2="53.8808" y2="196.1646" gradientTransform="matrix(18.8445 0 0 -18.8445 -825.3557 3796.6172)"><stop offset="0" style={{ stopColor: "#2A7CBA" }} /><stop offset="1" style={{ stopColor: "#2DCCB5" }} /></linearGradient>
										<path fill="url(#path1178_1_)" d="M10,27.7L75.1,100L10,172.3h19.9h24.9h5l40.1-44.6l40.1,44.6h29.9H190L59.9,27.7 H39.9h-10H10z M43.3,42.5h10l103.5,114.9h-10L100,105.5l-10,11.1l-36.8,40.8h-10L85,111.1L95,100L43.3,42.5z" />
										<linearGradient id="path1180_1_" gradientUnits="userSpaceOnUse" x1="44.329" y1="198.2293" x2="53.8808" y2="198.2293" gradientTransform="matrix(18.8445 0 0 -18.8445 -825.3557 3796.6172)"> <stop offset="0" style={{ stopColor: "#2A7CBA" }} /> <stop offset="1" style={{ stopColor: "#2DCCB5" }} /> </linearGradient>
										<path fill="url(#path1180_1_)" d="M140.1,27.7l-35.2,39l10,11.1l31.8-35.3h10l-36.8,40.9l10,11.1L190,27.7h-15h-5h-10 h-15H140.1z" />
									</svg>
								</div>
								<div className="flex-fill align-middle ml-2">
									<div className="flex-fill w-100">
										<p className="text-custom-important mb-1" style={{ fontSize: '125%' }}>
											<span className="align-middle">Web push notifications</span>
										</p>
										<span className="text-muted d-none d-sm-flex">Manage your notifications via Web push notifications</span>
									</div>
									<div className="row mt-4">
										<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
											<p className="text-custom mb-0">Permission</p>
										</div>
										<div className="col-12 col-md-8 col-xl-10">
											<div className="d-flex mb-4">
												<div className="flex-shrink-1 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
													<button className="toggle-switch-custom rounded-pill active" onClick={toggleActive}><div className="knob"></div></button>
												</div>
												<div className="w-100 ml-3 ml-sm-4">
													<p className="text-custom-important mb-1 mb-sm-0">Send me notifications</p>
													<p className="text-muted mb-0 d-none d-sm-flex">If this setting is turn on, SnapX will send your notifications to your Line Account</p>
												</div>
											</div>
										</div>
									</div>
									<div className="row mt-2 col-md-4 col-xl-2">
										<div className="col-12 col-md-2 mb-3 mb-md-0">
											<p className="text-custom mb-0">Your preferences</p>
										</div>
										<div className="col-12 col-md-8 col-xl-10">
											<div className="d-flex mb-4">
												<div className="flex-shrink-1 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
													<button className="toggle-switch-custom rounded-pill" onClick={toggleActive}><div className="knob"></div></button>
												</div>
												<div className="w-100 ml-3 ml-sm-4">
													<p className="text-custom-important mb-1 mb-sm-0">Alarm activity</p>
													<p className="text-muted mb-0 d-none d-sm-flex">Notify me about system and devices alarm activity</p>
												</div>
											</div>
											<div className="d-flex mb-4">
												<div className="flex-shrink-1 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
													<button className="toggle-switch-custom rounded-pill" onClick={toggleActive}><div className="knob"></div></button>
												</div>
												<div className="w-100 ml-3 ml-sm-4">
													<p className="text-custom-important mb-1 mb-sm-0 ">Devices activity</p>
													<p className="text-muted mb-0 d-none d-sm-flex">Notify me about devices activity</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="submenu d-none d-md-flex">
							<ul className="sidebar-nav">
								{/* <li className="sidebar-item mx-2 active" onClick={()=>{activeSubmenu(0)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Notifications</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(1)}}>
									<a className="sidebar-link text-muted bg-transparent px-3" >
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Members & Permissions</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(2)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Data & History</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(3)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Help</span>
									</a>
								</li> */}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="modal" tabindex="-1" id="confirmRemoveLine">
				<div className="modal-dialog modal-dialog-centered modal-sm">
					<div className="modal-content">
						<div className="modal-body">
							<div className="row px-3 mt-3 justify-content-center">
								<h5>
									Confirm Remove ?
								</h5>
							</div>
							<div className="row px-3 mt-4 mb-2 justify-content-center">
								<button className="btn btn-snapX-login-clean text-sm" style={{ letterSpacing: "0px", marginTop: '-3.5px' }} data-dismiss="modal">
									<span>Discard</span>
								</button>
								<button className="btn btn-cancel text-sm ml-3"
									style={{ letterSpacing: "0px", marginTop: '-3.5px' }}
									onClick={RemoveLine}
									data-dismiss="modal"
								>
									<strong>Confirm</strong>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Content
