import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Menu from '../../menu/menu'
import Topnav from '../../menu/topnav'
import Footer from '../../menu/footer'
import Content from './content'


function App() {
	const { id } = useParams();

	useEffect(() => {
		document.title = "Device info | SnapX"
		sessionStorage.removeItem('redirectURL')
	}, [])

	if (localStorage.getItem("user")) {
		return (
			<div className="wrapper">
				<Menu active={1} />
				<div className="main">
					<Topnav />
					<main className="content">
						<Content />
					</main>
					<Footer />
				</div>
			</div>
		)
	}
	else {
		window.location.replace("/?i="+id);
	}
}

export default App
