import React, { useEffect } from 'react'
import Menu from '../../menu/menu'
import Topnav from '../../menu/topnav'
import Footer from '../../menu/footer'
import Content from './content'


function App() {

	useEffect(() => {
		sessionStorage.removeItem('redirectURL')
		// const script = document.querySelectorAll('[src="app.js"]');
		// script[0].remove();
		document.title = "Device overview | SnapX"
	}, [])

	if (localStorage.getItem("user")) {
		return (
			<div className="wrapper">
				<Menu active={0} />
				<div className="main">
					<Topnav />
					<main className="content">
						<Content />
					</main>
					<Footer />
				</div>
			</div>
		)
	}
	else{
		window.location.replace("/");
	}
}

export default App
