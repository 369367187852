import React from 'react'

function Content() {

	return (
		<>
			<div className="container-fluid p-0">
				<div className="row" style={{ height: 'calc(100vh - 150px)' }}>
					<div className="my-auto mx-auto text-center">
						<h2 className="text-muted">Error</h2>
						<h1 className="text-muted" style={{fontSize:'4.5rem'}}>503</h1>
						<h3 className="text-muted">Service Unavailable</h3>
					</div>
				</div>
			</div>
		</>
	);
}
export default Content
