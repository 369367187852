export const tsFormat = (d, type) => {
    var d = new Date(d),
        date = d.getDate(),
        month = checkMonth(d.getMonth()),
        year = d.getUTCFullYear(),
        hour = d.getHours(),
        minute = checkTime(d.getMinutes()),
        ss = checkTime(d.getSeconds());

    var time = "";

    if (type == 'auto' && Date.now() - d >= 8640000) {
        return (date + " " + month + " " + year)
    }
    else if (type != 'long') {
        type = 'short'
    }

    function checkTime(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    function checkMonth(m) {
        var MonthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        m = MonthName[m];
        return m;
    }


    if (hour >= 12) {
        if (hour != 12) {
            hour = hour - 12;
            // hour = checkTime(hour);
            // time = (type == 'long' ? date + "-" + month + "-" + year + " " + hour + ":" + minute + " PM" : hour + ":" + minute + " PM");
        }
        // } else {
        // hour = hour - 12;
        hour = checkTime(hour);
        time = (type == 'long' ? date + "-" + month + "-" + year + " " + hour + ":" + minute + " PM" : hour + ":" + minute + " PM");
        // }
    } else {
        hour = checkTime(hour);
        time = (type == 'long' ? date + "-" + month + "-" + year + " " + hour + ":" + minute + " AM" : hour + ":" + minute + " AM");
    }

    // time = date + "-" + month + "-" + year + " " + hour + ":" + minute;
    return time;
}