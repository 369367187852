import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom';
import fire from '../firebase_alm'
import { tsFormat } from '../../customLibs/timeStampFormat'
import 'firebase'
const $ = require('jquery');


async function getAllDeviceList(credentials) {
	return fetch('https://dixell.snapx.cloud/api/deviceListString', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(credentials)
	})
		.then(response => response.json())
}

async function getDeviceAttr(credentials) {
	return fetch('https://dixell.snapx.cloud//api/deviceByIDSQL', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(credentials)
	})
		.then(response => response.json())
}

function Topnav() {

	if (!localStorage.version || localStorage.version != "2.0.3") {
		localStorage.clear();
		window.location.replace('/');
	}

	function logout(e) {
		e.preventDefault();
		localStorage.clear();
		window.location.replace('/');
	}

	const [Alarm, setAlarm] = useState([])
	const [DeviceList, setDeviceList] = useState([]);
	const [Counter, setCounter] = useState([])
	const [Response, setResponse] = useState({});

	let obj = {};
	let deviceCount = 0;
	let counter = 0;
	let db = fire.database();

	useEffect(async () => {
		let resList = await getAllDeviceList(
			{
				user: localStorage.getItem("user")
			}
		)
		setDeviceList(resList.map)
		if (resList.map.length == 0) {
			setResponse({ message: "No Data" })
		}
	}, [])

	useEffect(() => {
		deviceCount = DeviceList.length;
		for (let d of DeviceList) {
			getAlarmData(d.id, d.label == "" ? d.deviceName : d.label)
		}
	}, [DeviceList])

	function getAlarmData(id, name) {
		return db.ref('/' + id).once('value', (snapshot) => {
			snapshot.forEach(childSnapshot => {
				const key = childSnapshot.key;
				const data = childSnapshot.val();
				if (data.name) {
					if (data.name.toLowerCase().includes("temp") || data.name.toLowerCase().includes("pb")) {
						data.icon = "fa-thermometer-half"
					} else if (data.name.toLowerCase().includes("link") || data.name.toLowerCase().includes("connection")) {
						data.icon = "fa-plug"
					} else if (data.name.toLowerCase().includes("error") || data.name.toLowerCase().includes("fail")) {
						data.icon = "fa-exclamation"
					} else if (data.name.toLowerCase().includes("external")) {
						data.icon = "fa-wrench"
					} else if (data.name.toLowerCase().includes("severe")) {
						data.icon = "fa-exclamation-triangle"
					} else {
						data.icon = "fa-exclamation"
					}
				}
				data.id = id;
				data.key = key;
				data.deviceName = name;
				obj[data.timestamp] = data;
			});
			counter++;
			if (counter == deviceCount) {
				sort()
			}
		});
	}

	const sort = () => {
		let Arr = [];
		Object.keys(obj).sort().forEach(function (v, i) {
			Arr.push(obj[v])
		});
		setAlarm(Arr.reverse())
		setResponse({ message: "Success" })
	}

	useEffect(() => {
		SeenCount();
		getNewAlarm(Alarm.length)
		AnyChangeAlarm()
	}, [Alarm])

	function AnyChangeAlarm() {
		for (let d of DeviceList) {
			db.ref('/' + d.id).on('child_changed', (snapshot) => {
				let fetchedTasks = Alarm;
				const key = snapshot.key;
				const data = snapshot.val();
				data.id = d.id
				data.key = key;
				data.deviceName = d.label
				if (data.name.toLowerCase().includes("temp") || data.name.toLowerCase().includes("pb")) {
					data.icon = "fa-thermometer-half"
				} else if (data.name.toLowerCase().includes("link") || data.name.toLowerCase().includes("connection")) {
					data.icon = "fa-plug"
				} else if (data.name.toLowerCase().includes("error") || data.name.toLowerCase().includes("fail")) {
					data.icon = "fa-exclamation"
				} else if (data.name.toLowerCase().includes("external")) {
					data.icon = "fa-wrench"
				} else if (data.name.toLowerCase().includes("severe")) {
					data.icon = "fa-exclamation-triangle"
				}
				for (let v of Object.entries(fetchedTasks)) {
					if (v[1].timestamp == data.timestamp) {
						fetchedTasks[v[0]] = data
					}
				}
				setAlarm(fetchedTasks)
				setResponse({ message: "Success" })
				SeenCount()
			});
		}
	}

	function getNewAlarm(num) {
		let newCount = 0;
		for (let d of DeviceList) {
			db.ref('/' + d.id).on('child_added', (snapshot) => {
				let fetchedTasks = Alarm;
				newCount++
				if (newCount > num) {
					const key = snapshot.key;
					const data = snapshot.val();
					data.id = d.id
					data.key = key;
					data.deviceName = d.label
					if (data.name.toLowerCase().includes("temp") || data.name.toLowerCase().includes("pb")) {
						data.icon = "fa-thermometer-half"
					} else if (data.name.toLowerCase().includes("link") || data.name.toLowerCase().includes("connection")) {
						data.icon = "fa-plug"
					} else if (data.name.toLowerCase().includes("error") || data.name.toLowerCase().includes("fail")) {
						data.icon = "fa-exclamation"
					} else if (data.name.toLowerCase().includes("external")) {
						data.icon = "fa-wrench"
					} else if (data.name.toLowerCase().includes("severe")) {
						data.icon = "fa-exclamation-triangle"
					}
					fetchedTasks.splice(0, 0, data)
					setAlarm(fetchedTasks)
					setResponse({ message: "Success" })
					SeenCount()
				}
			});
		}
	}

	const SeenCount = () => {
		let counter = 0;
		for (let i = 0; i < Alarm.length; i++) {
			if (Alarm[i].prop.seen == false) {
				counter++
			}
		}
		setCounter(counter)
	}

	useEffect(() => {
		const init = () => {
			if ($('.tsCounter[data-ts]').length > 0) {
				setInterval(() => {
					for (let i = 0; i < $('.tsCounter[data-ts]').length; i++) {
						$('.tsCounter[data-ts]')[i].innerText = tsCount($('.tsCounter[data-ts]')[i].dataset.ts)
					}
				}, 1000);
				clearInterval(retry)
			}
		}
		const retry = setInterval(init, 500);
	}, [])

	const tsCount = (d) => {
		let seconds = Math.floor((new Date() - d + 2000) / 1000);

		let interval = seconds / 31536000;

		if (interval > 1) {
			return Math.floor(interval) + " years ago";
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			return Math.floor(interval) + " months ago";
		}
		interval = seconds / 86400;
		if (interval > 1) {
			return Math.floor(interval) + " days ago";
		}
		interval = seconds / 3600;
		if (interval > 1) {
			return Math.floor(interval) + " hours ago";
		}
		interval = seconds / 60;
		if (interval > 2) {
			return Math.floor(interval) + " minutes ago";
		} else if (interval > 1 && interval <= 2) {
			return "about a minutes ago";
		} else {
			return "a few seconds ago";
		}
	}

	const Seen = (length) => {
		for (let i = 0; i < length; i++) {
			if (Alarm[i] && Alarm[i].prop.seen == false) {
				let key = Alarm[i]["key"];
				let updateObj = {};
				if (!updateObj[key]) {
					updateObj[key] = {}
				}
				delete Alarm[i].icon
				updateObj[key] = Object.assign({}, Alarm[i]);
				updateObj[key].prop.seen = true;
				updateObj[key].prop.seenTimestamp = Date.now();
				delete updateObj[key].id;
				delete updateObj[key].key;
				delete updateObj[key].deviceName;
				fire.database().ref('/' + Alarm[i].id).update(updateObj)
			}
		}
	}

	const openSidebar = (e) => {
		if (e.target.className.includes('active')) {
			e.target.classList.remove('active')
			$('#sidebar')[0].removeAttribute('style')
		} else {
			e.target.classList.add('active')
			$('#sidebar')[0].setAttribute('style', 'margin-left:0px')
		}
	}

	if (Response.message && Response.message == "Success") {
		return (
			<>
				<nav className="navbar navbar-expand navbar-light bg-transparent">
					<div className="sidebar-toggle d-flex d-lg-none ml-2" onClick={openSidebar}>
						<i className="align-self-center fa fa-lg fa-bars custom-menu-icon"></i>
					</div>

					<div className="navbar-collapse collapse">
						<ul className="navbar-nav navbar-align">
							<li className="nav-item dropdown">
								<a className="nav-icon dropdown-toggle mr-2" href="#" id="alertsDropdown" data-toggle="dropdown" onClick={() => { Seen(10) }}>
									<div className="position-relative">
										{/* <i className="align-middle" data-feather="bell"></i> */}
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={Counter > 0 ? "#f8f9fa" : "none"} stroke={Counter > 0 ? "#f8f9fa" : "#919397"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
										<span className="indicator sizeFromZero" style={{ display: Counter > 0 ? "block" : "none" }}>{Counter < 10 ? Counter : '10+'}</span>
									</div>
								</a>
								<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0"
									aria-labelledby="alertsDropdown">
									{/* <div className="dropdown-menu-header text-left px-3 dropdown-menu-header-hoverable" style={{cursor:"pointer"}}>
									Alarm notifications
								<span className="float-right"><i className="fa fa-arrow-right" style={{transform:'rotate(-45deg)'}}></i></span>
								</div> */}
									<div className="dropdown-menu-header text-left px-3">
										Alarm notifications
									</div>
									<div className={Object.keys(Alarm).length >= 5 ? "list-group-item-overflow" : "list-group"} style={{ maxHeight: "450px" }}>
										{/* <div className="list-group">
									<a href="#" className="list-group-item">
										<div className="row no-gutters align-items-center">
											<div className="col-2">
												<i className="text-danger" data-feather="alert-circle"></i>
											</div>
											<div className="col-10">
												<div className="text-light">Update completed</div>
												<div className="text-muted-custom small mt-1">
													Restart server 12 to complete the update.
												</div>
												<div className="text-muted-custom small mt-1">30m ago</div>
											</div>
										</div>
									</a>
									<a href="#" className="list-group-item">
										<div className="row no-gutters align-items-center">
											<div className="col-2">
												<i className="text-warning" data-feather="bell"></i>
											</div>
											<div className="col-10">
												<div className="text-light">Lorem ipsum</div>
												<div className="text-muted-custom small mt-1">
													Aliquam ex eros, imperdiet vulputate hendrerit et.
												</div>
												<div className="text-muted-custom small mt-1">2h ago</div>
											</div>
										</div>
									</a>
									<a href="#" className="list-group-item">
										<div className="row no-gutters align-items-center">
											<div className="col-2">
												<i className="text-primary" data-feather="home"></i>
											</div>
											<div className="col-10">
												<div className="text-light">Login from 192.186.1.8</div>
												<div className="text-muted-custom small mt-1">5h ago</div>
											</div>
										</div>
									</a>
									<a href="#" className="list-group-item">
										<div className="row no-gutters align-items-center">
											<div className="col-2">
												<i className="text-success" data-feather="user-plus"></i>
											</div>
											<div className="col-10">
												<div className="text-light">New connection</div>
												<div className="text-muted-custom small mt-1">
													Christina accepted your request.
												</div>
												<div className="text-muted-custom small mt-1">14h ago</div>
											</div>
										</div>
									</a>
								</div> */}
										<div className={Object.keys(Alarm).length > 0 ? 'd-none' : 'list-group'}>
											<a className="list-group-item list-group-item-hoverable">
												<div className="row align-items-center">
													<div className="col-12">
														<div className="text-muted-custom text-center">
															Nothing happened ...
														</div>
													</div>
												</div>
											</a>
										</div>
										{
											Alarm.map((val, index) => {
												if (index < 10)
													return (
														<div className='list-group' key={index}>
															<Link to={{ pathname: "/list/" + val.id }} className="list-group-item list-group-item-hoverable">
																<div className="row align-items-center">
																	<div className="col-2 text-center px-1">
																		<i className={val.icon + " fa align-middle w-100"} style={{ fontSize: '115%', color: val.state ? '#eb3241' : '#858585' }}></i>
																	</div>
																	<div className="col-8 px-1">
																		<div className="text-light">
																			{val.name}
																		</div>
																		<div className="text-muted-custom small mt-1">
																			{val.deviceName}
																		</div>
																		{
																			Date.now() - val.timestamp > 86400000 ?
																				<div className="text-custom small mt-2 tsCounter">{tsFormat(val.timestamp, 'auto')}</div>
																				:
																				<div className="text-custom small mt-2 tsCounter" data-ts={val.timestamp}>...</div>
																		}

																	</div>
																	<span className="col-2" style={{ fontSize: "150%" }}>{val.prop.ack ? "" : "•"}</span>
																</div>
															</Link>
														</div>
													)
											}
											)}
										<Link
											className="dropdown-menu-footer dropdown-menu-footer-hoverable text-decoration-none py-3"
											style={{ cursor: 'pointer', pointerEvents: 'auto', color: '#adb0b3' }}
											to={{ pathname: '/alarm' }}
											onClick={() => { Seen(Alarm.length) }}
										>See all</Link>
									</div>
								</div>
							</li>
							{/* <li className="nav-item dropdown">
							<a className="nav-icon dropdown-toggle" href="#" id="messagesDropdown" data-toggle="dropdown">
								<div className="position-relative">
									<i className="align-middle" data-feather="message-square"></i>
									<span className="indicator">4</span>
								</div>
							</a>
							<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0"
								aria-labelledby="messagesDropdown">
								<div className="dropdown-menu-header">
									<div className="position-relative">4 New Messages</div>
								</div>
								<div className="list-group">
									<a href="#" className="list-group-item">
										<div className="row no-gutters align-items-center">
											<div className="col-2">
												<img src="../img/avatars/jisoo.jpg"
													className="avatar img-fluid rounded-circle" alt="Jisoo Kim" />
											</div>
											<div className="col-10 pl-2">
												<div className="text-light">Jisoo Kim <span className="badge badge-primary ml-1">New</span></div>
												<div className="text-muted-custom small mt-1">
													Happy Valentine's Day, My love. 💕💕💕
												</div>
												<div className="text-muted-custom small mt-1">1m ago</div>
											</div>
										</div>
									</a>
									<a href="#" className="list-group-item">
										<div className="row no-gutters align-items-center">
											<div className="col-2">
												<img src="../img/avatars/lisa.jpg"
													className="avatar img-fluid rounded-circle" alt="Lisa" />
											</div>
											<div className="col-10 pl-2">
												<div className="text-light">Lisa</div>
												<div className="text-muted-custom small mt-1">
													Can we meet?. I'll go back to BKK next month.
												</div>
												<div className="text-muted-custom small mt-1">10m ago</div>
											</div>
										</div>
									</a>
									<a href="#" className="list-group-item">
										<div className="row no-gutters align-items-center">
											<div className="col-2">
												<img src="../img/avatars/jennie.jpg"
													className="avatar img-fluid rounded-circle" alt="Jennie Kim" />
											</div>
											<div className="col-10 pl-2">
												<div className="text-light">Jennie Kim</div>
												<div className="text-muted-custom small mt-1">
													I have an idea for you new dog name.
												</div>
												<div className="text-muted-custom small mt-1">2h ago</div>
											</div>
										</div>
									</a>
									<a href="#" className="list-group-item">
										<div className="row no-gutters align-items-center">
											<div className="col-2">
												<img src="../img/avatars/rose.jpg"
													className="avatar img-fluid rounded-circle" alt="Rose Park" />
											</div>
											<div className="col-10 pl-2">
												<div className="text-light">Rose Park</div>
												<div className="text-muted-custom small mt-1">
													Camtono.
												</div>
												<div className="text-muted-custom small mt-1">4h ago</div>
											</div>
										</div>
									</a>
								</div>
								<div className="dropdown-menu-footer">
									<a href="#" className="text-muted-custom">Show all messages</a>
								</div>
							</div>
						</li> */}
							<li className="nav-item dropdown">
								<a className="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#" data-toggle="dropdown">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
								</a>

								<a className="nav-link dropdown-toggle d-none d-sm-inline-block" href="#" data-toggle="dropdown">
									<img src={localStorage.pic} className="avatar img-fluid rounded-circle mr-1" id="topNavPic" style={{ display: localStorage.pic ? "" : "none" }}></img>
									<span className="avatar avatar-text rounded-circle mr-1 p-2" id="topNavThumbNail" style={{ display: localStorage.pic ? "none" : "" }}>{localStorage.name != "" ? localStorage.name.substring(0, 2).toUpperCase() : localStorage.user.substring(0, 2).toUpperCase()}</span>
									<span className="text-custom mx-2" id="topNavName" style={{ maxWidth: "50px" }}>{localStorage.name == "" ? localStorage.user : localStorage.name}</span>
								</a>
								<div className="dropdown-menu dropdown-menu-right">
									<Link className="dropdown-item px-3" to={{ pathname: "/account" }}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle mr-2 my-1"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
										<span className="align-middle ml-1">Profile</span>
									</Link>
									{/* <a className="dropdown-item px-3" href="/subscription"><i className="align-middle mr-2 my-1"
										data-feather="dollar-sign"></i>
										<span className="align-middle ml-1">Subscriptions</span></a> */}
									<Link className="dropdown-item px-3" to={{ pathname: "/setting" }}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle mr-2 my-1"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
										<span className="align-middle ml-1">Settings</span>
									</Link>
									{/* <Link className="dropdown-item px-3" to={{ pathname: "/manage" }}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle mr-2 my-1"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>
										<span className="align-middle ml-1">Manage Devices</span>
									</Link> */}
									<div className="dropdown-divider"></div>
									<a className="dropdown-item px-3" onClick={logout}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle mr-2 my-1"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
										<span className="align-middle ml-1">Log out</span></a>
								</div>
							</li>
						</ul>
					</div>
				</nav>
			</>
		)
	}
	else {
		return (
			<>
				<nav className="navbar navbar-expand navbar-light bg-transparent">
					<a className="sidebar-toggle d-flex d-lg-none ml-2">
						<i className="align-self-center fa fa-lg fa-bars custom-menu-icon"></i>
					</a>

					<div className="navbar-collapse collapse">
						<ul className="navbar-nav navbar-align">
							<li className="nav-item dropdown">
								<a className="nav-icon dropdown-toggle mr-2" href="#" id="alertsDropdown" data-toggle="dropdown">
									<div className="position-relative">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#919397" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
										<span className="indicator sizeFromZero" style={{ display: "none" }}></span>
									</div>
								</a>
								<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0"
									aria-labelledby="alertsDropdown">
									<div className="dropdown-menu-header text-left px-3">
										Alarm notifications
									</div>
									<div className="list-group" style={{ maxHeight: "450px" }}>
										<div className='list-group'>
											<a className="list-group-item list-group-item-hoverable">
												<div className="row align-items-center">
													<div className="col-12">
														<div className="text-muted-custom text-center">
															Nothing happened ...
														</div>
													</div>
												</div>
											</a>
										</div>
										<Link
											className="dropdown-menu-footer dropdown-menu-footer-hoverable text-decoration-none py-3"
											style={{ cursor: 'pointer', pointerEvents: 'auto', color: '#adb0b3' }}
											to={{ pathname: '/alarm' }}
											onClick={() => { Seen(Alarm.length) }}
										>See all</Link>
									</div>
								</div>
							</li>

							<li className="nav-item dropdown">
								<a className="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#" data-toggle="dropdown">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>

								</a>

								<a className="nav-link dropdown-toggle d-none d-sm-inline-block" href="#" data-toggle="dropdown">
									<img src={localStorage.pic} className="avatar img-fluid rounded-circle mr-1" id="topNavPic" style={{ display: localStorage.pic ? "" : "none" }}></img>
									<span className="avatar avatar-text rounded-circle mr-1 p-2" id="topNavThumbNail" style={{ display: localStorage.pic ? "none" : "" }}>{localStorage.name != "" ? localStorage.name.substring(0, 2).toUpperCase() : localStorage.user.substring(0, 2).toUpperCase()}</span>
									<span className="text-custom mx-2" id="topNavName" style={{ maxWidth: "50px" }}>{localStorage.name == "" ? localStorage.user : localStorage.name}</span>
								</a>
								<div className="dropdown-menu dropdown-menu-right">
									<Link className="dropdown-item px-3" to={{ pathname: "/account" }}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle mr-2 my-1"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
										<span className="align-middle ml-1">Profile</span></Link>
									{/* <a className="dropdown-item px-3" href="/subscription"><i className="align-middle mr-2 my-1"
										data-feather="dollar-sign"></i>
										<span className="align-middle ml-1">Subscriptions</span></a> */}
									<Link className="dropdown-item px-3" to={{ pathname: "/setting" }}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle mr-2 my-1"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
										<span className="align-middle ml-1">Settings</span>
									</Link>
									<div className="dropdown-divider"></div>
									<a className="dropdown-item px-3" onClick={logout}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle mr-2 my-1"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
										<span className="align-middle ml-1">Log out</span></a>
								</div>
							</li>
						</ul>
					</div>
				</nav>
			</>
		)
	}
}

export default Topnav
