import React from 'react';


class MapHeat extends React.PureComponent {

  render() {
    return (
      <>
        <div style={{
          boxShadow: this.props.alarm == 1 ? "0px 0px 10px 10px rgba(211,18, 41, 0.25)" : this.props.status == 1 ? "0px 0px 10px 10px rgba(25,176,98, 0.25)" : "0px 0px 10px 10px rgba(120,120, 120, 0.1)",
          background: this.props.alarm == 1 ? "rgba(179,16, 35, 0.5)" : this.props.status == 1 ? "rgba(30,220,123, 0.5)" : "rgba(100,100,100, 0.4)",
          width: '7.5px', height: '7.5px', borderRadius: '50%'
        }}>
        </div>
      </>
    );
  }
}

export default MapHeat;
