import React, { useState, useEffect, lazy } from 'react';
import fire from '../../firebase_status'
import 'firebase'
import GoogleMap, { MAP } from "./map";
import GoogleMapReact from 'google-map-react';
import mapStyles from './mark/mapStyles.json';
const $ = require('jquery');


async function getAllDeviceList(credentials) {
    return fetch('https://dixell.snapx.cloud/api/deviceListString', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(response => response.json())
}


function Content() {

    const [TimeOut, setTimeOut] = useState(false);
    const [Response, setResponse] = useState({});
    const [resGeo, setGeo] = useState([]);
    const [Filter, setFilter] = useState([]);
    const [Pos, setPos] = useState({});
    const [RefreshMap, setRefreshMap] = useState({});
    const [heatMap, setheatMap] = useState(false);


    useEffect(async () => {
        await getAllDeviceList(
            {
                user: localStorage.user
                // user: localStorage.getItem('p.')
            }
        )
            .then(
                (result) => {
                    // console.log(result)
                    let geo = Object.assign([], result.map);
                    for (let i = 0; i < geo.length; i++) {
                        let Adr = geo[i].adr.split(', ');
                        if (Adr.length > 4) {
                            geo[i].adr1 = Adr[0] + ' ' + Adr[2].replace(' District', '');
                            geo[i].adr2 = Adr[3] + ' ' + Adr[4];
                            geo[i].adr3 = Adr[5];
                        } else if (Adr.length > 2) {
                            geo[i].adr1 = Adr[0];
                            geo[i].adr2 = Adr[1].replace(' Province', '') + ' ' + Adr[2].replace(' Province', '');
                            geo[i].adr3 = Adr[3];
                        } else {
                            geo[i].adr1 = geo[i].adr;
                            geo[i].adr2 = "";
                            geo[i].adr3 = "";
                        }
                        // geo[i].alarm = Math.floor(Math.random() * (2 - 0))
                        geo[i].status = geo[i].attr.lastConnectTime >= geo[i].attr.lastDisconnectTime ? 1 : 0
                    }
                    setGeo(geo);
                    setFilter(geo);
                    if ((Object.keys(geo).length) > 0) {
                        setResponse({ message: "Success" })
                    } else {
                        setResponse({ message: "No Data" })
                    }
                }
            )
    }, []);

    useEffect(() => {
        center()
        for (let [i, d] of Object.entries(resGeo)) {
            getLiveGeo(parseInt(i), d.id)
        }
    }, [resGeo])

    let db = fire.app('status').database();

    const getLiveGeo = (index, id) => {
        db.ref('/' + id).on('child_changed', (snapshot) => {
            let key = snapshot.key;
            let data = snapshot.val();

            if (key == "geo") {
                let adr, adr1, adr2, adr3;
                adr = data.address.split(', ');
                if (adr.length > 4) {
                    adr1 = adr[0] + ' ' + adr[2].replace(' District', '');
                    adr2 = adr[3] + ' ' + adr[4];
                    adr3 = adr[5];
                } else {
                    adr1 = adr[0];
                    adr2 = adr[1].replace(' Province', '') + ' ' + adr[2].replace(' Province', '');
                    adr3 = adr[3];
                }
                let obj = Object.assign([], resGeo);
                obj[index]["adr"] = adr
                obj[index]["adr1"] = adr1
                obj[index]["adr2"] = adr2
                obj[index]["adr3"] = adr3
                obj[index]["lat"] = data.lat
                obj[index]["lng"] = data.lon
                // setGeo(obj)
            } else if (key == 'st') {

            }
        });
    }

    const center = (e) => {
        let c = {}
        if (e == undefined) {
            c = {
                lat: 13,
                lng: 100.5,
                zoom: 6
            }
            setPos(c)
        } else if (e.key === "Enter" || e.type == "click") {
            // let index = resGeo.findIndex(x => x.name === $('[list="maps-search"]')[0].value);
            // let index = resGeo.findIndex(x => x.id === $('#maps-search')[0].dataset.searchId);
            let index = $('#maps-search-input-main')[0].dataset.searchId;
            if (index) {
                c = {
                    lat: resGeo[index].lat,
                    lng: resGeo[index].lng,
                    zoom: 16
                }
            }
        } else if (typeof (e) == 'object' && e.type != 'keypress') {
            c = {
                lat: e.lat,
                lng: e.lng,
                zoom: 16
            }
            setPos(c)
        }
        setRefreshMap(true)

        setTimeout(() => {
            setRefreshMap(false)
        }, 500)
    }

    const onInput = (e, type) => {
        const { value } = e.target;
        const filter = value.toUpperCase();
        if (type == 'main') {
            const group = document.getElementById("maps-search-item");
            const a = group.getElementsByTagName("a");
            for (let i = 0; i < a.length; i++) {
                let txtValue = a[i].innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    a[i].classList.remove('hide')
                } else {
                    a[i].classList.add('hide')
                }
            }
        } else if (type == 'modal') {
            const group = document.getElementById("maps-search-item-modal");
            const a = group.getElementsByClassName("col-12");
            for (let i = 0; i < a.length; i++) {
                let txtValue = a[i].innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    a[i].classList.remove('hide')
                } else {
                    a[i].classList.add('hide')
                }
            }
        }
        if (value.length > 0) {
            $('.clearSearch')[0].style.display = "inline-block"
            $('.clearSearch')[1].style.display = "inline-block"
        } else {
            $('.clearSearch')[0].style.display = "none"
            $('.clearSearch')[1].style.display = "none"
        }
        if ($('#maps-search-item a:not(.hide):not(.notFound)').length == 0) {
            $('#maps-search-item a.notFound')[0].classList.remove('hide')
        } else {
            $('#maps-search-item a.notFound')[0].classList.add('hide')
        }


        if ($('#maps-search-item-modal div.col-12:not(.hide):not(.notFound)').length == 0) {
            $('#maps-search-item-modal div.col-12.notFound')[0].classList.remove('hide')
        } else {
            $('#maps-search-item-modal div.col-12.notFound')[0].classList.add('hide')
        }
    }

    const search = (e) => {
        $('.clearSearch')[0].style.display = "inline-block"
        $('.clearSearch')[1].style.display = "inline-block"
        if (e.target.tagName == "A") {
            $('#maps-search-input-main')[0].value = e.target.children[0].firstChild.firstChild.innerText;
            $('#maps-search-input-main')[0].dataset.searchId = e.target.dataset.id;
        } else if (e.target.tagName == "DIV") {
            $('#maps-search-input-modal')[0].value = e.target.children[0].children[0].children[0].children[0].innerText;
            $('#maps-search-input-modal')[0].dataset.searchId = e.target.dataset.id;
        }
    }


    const clear = () => {
        $('#maps-search-input-main')[0].value = "";
        $('#maps-search-input-modal')[0].value = "";
        $('.clearSearch')[0].style.display = "none";
        $('.clearSearch')[1].style.display = "none";
    }

    const ShowAll = (e) => {
        if (e.target.value.length == 0) {
            $('#maps-search-item a:not(.notFound)').removeClass('hide')
            $('#maps-search-item a.notFound').addClass('hide')

            $('#maps-search-item-modal div.col-12:not(.notFound)').removeClass('hide')
            $('#maps-search-item-modal div.col-12.notFound').addClass('hide')
        }
    }

    const ShowAllModal = () => {
        $('#maps-search-item-modal div.col-12:not(.notFound)').removeClass('hide')
        $('#maps-search-item-modal div.col-12.notFound').addClass('hide')
    }

    const filter = (e, type) => {
        for (let i = 0; i < e.target.parentElement.children.length; i++) {
            e.target.parentElement.children[i].classList.remove('active')
        }
        e.target.classList.add('active')
        if (type == "all") {
            setFilter(resGeo)
        } else if (type == "active") {
            const result = resGeo.filter(data => data.status == 1);
            setFilter(result)
        } else if (type == "inactive") {
            const result = resGeo.filter(data => data.status == 0);
            setFilter(result)
        } else if (type == "fault") {
            const result = resGeo.filter(data => data.alarm == 1);
            setFilter(result)
        } else if (type == "normal") {
            const result = resGeo.filter(data => data.alarm == 0);
            setFilter(result)
        }

        setPos({
            lat: 13.001,
            lng: 100.501,
            zoom: 6
        })
        setRefreshMap(true)
        setTimeout(() => {
            setPos({
                lat: 13,
                lng: 100.5,
                zoom: 6
            })
            setRefreshMap(false)
        }, 500)
    }

    const heatMode = (e) => {
        heatMap ? setheatMap(false) : setheatMap(true)
        if (e.target.className.toLowerCase().includes("active")) {
            e.target.classList.remove('active')
        } else {
            e.target.classList.add('active')
        }
    }

    if (Response.message && Response.message == "Success" && Object.keys(resGeo).length > 0) {
        return (
            <>
                <div className="container-fluid p-0">
                    <div className="row title mb-4 pb-3 mx-0 border-bottom">
                        <div className="col-6 col-sm-4 col-xl-6 px-0">
                            <h3>Device Maps</h3>
                        </div>
                        <div className="col-6 col-sm-8 col-xl-6 px-0 text-right">
                            <div className="d-inline-block mr-1">
                                <div className="sort-hover rounded px-2 py-1 ml-1" title="Show/hide Devices" onClick={(e) => { heatMode(e) }}>
                                    <i className="fa fa-low-vision mr-0 d-inline-block text-muted" style={{ transform: 'scale(0.9,0.9)', pointerEvents: 'none' }}></i>
                                </div>
                            </div>

                            <div className="d-inline-block mr-2">
                                <div className="sort-hover rounded px-2 py-1 ml-1" id="filterMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Sort options" >
                                    <i className="fa fa-filter mr-0 d-inline-block text-muted" style={{ transform: 'scale(0.7,0.7)' }}></i>
                                </div>
                                <div className="dropdown-menu dropdown-menu-right mx-2 py-1" aria-labelledby="filterMenu">
                                    <a className="dropdown-item px-3 py-2 active" style={{ fontSize: '80%' }} onClick={(e) => { filter(e, 'all') }}>
                                        <span className="ml-1 align-middle" style={{ pointerEvents: 'none' }}>All (Default)</span>
                                    </a>
                                    <a className="dropdown-item px-3 py-2" style={{ fontSize: '80%' }} onClick={(e) => { filter(e, 'active') }}>
                                        <span className="ml-1 align-middle" style={{ pointerEvents: 'none' }}>Active</span>
                                    </a>
                                    <a className="dropdown-item px-3 py-2" style={{ fontSize: '80%' }} onClick={(e) => { filter(e, 'inactive') }}>
                                        <span className="ml-1 align-middle" style={{ pointerEvents: 'none' }}>Inactive</span>
                                    </a>
                                    {/* <a className="dropdown-item px-3 py-2" style={{ fontSize: '80%' }} onClick={(e) => { filter(e, 'fault') }}>
                                        <span className="ml-1 align-middle" style={{ pointerEvents: 'none' }}>Fault</span>
                                    </a>
                                    <a className="dropdown-item px-3 py-2" style={{ fontSize: '80%' }} onClick={(e) => { filter(e, 'normal') }}>
                                        <span className="ml-1 align-middle" style={{ pointerEvents: 'none' }}>Normal</span>
                                    </a> */}
                                </div>
                            </div>

                            <div className="d-inline-block d-sm-none">
                                <div className="sort-hover rounded px-2 py-1" title="Search" data-toggle="modal" data-target="#searchModal">
                                    <i className="fa fa-search mr-0 d-inline-block text-muted" style={{ transform: 'scale(0.9,0.9)', pointerEvents: 'none' }}></i>
                                </div>
                            </div>

                            <div className="d-none d-sm-inline-block">
                                <div className="input-group" id="maps-search-group">
                                    <input className="form-control bg-transparent border-0 rounded pr-4 dropdown-toggle" type="search" onKeyPress={(e) => { center(e) }} onKeyUp={(e) => { onInput(e, 'main') }} onFocus={(e) => { ShowAll(e) }} id="maps-search-input-main" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" placeholder="Search..." autoComplete="off">
                                    </input>
                                    <div className="align-middle px-1 text-muted clearSearch" style={{ marginLeft: "-22.5px", marginRight: '5px', paddingTop: "5px", cursor: "pointer", zIndex: "5", display: "none" }} onClick={clear}><i className="fa fa-times" style={{ pointerEvents: 'none' }}></i></div>
                                    <div className="dropdown-menu dropdown-menu-right search-overflow-custom" id="maps-search-item" aria-labelledby="maps-search-input-main" style={{ maxHeight: "450px" }}>
                                        {
                                            resGeo.map((val, index) => (
                                                <a className={index == resGeo.length - 1 ? "dropdown-item ml-1 px-3 py-2" : "dropdown-item ml-1 px-3 py-2 border-bottom border-custom"} key={index} data-id={index} onClick={(e) => { search(e); center({ lat: val.lat, lng: val.lng }) }}>
                                                    <div className="flex-fill" style={{ pointerEvents: 'none' }}>
                                                        <p className="mb-1 link-custom d-inline-block rounded">
                                                            <strong className="text-custom-light">{val.label}</strong>
                                                            <small className="text-muted ml-1">({val.model})</small>
                                                        </p>
                                                        <p className="text-muted text-wrap-custom my-0 d-flex"><small>{val.adr1}</small></p>
                                                        <p className="text-muted text-wrap-custom my-0 d-flex"><small>{val.adr2}</small></p>
                                                        <p className="text-muted text-wrap-custom my-0 d-flex"><small>{val.adr3}</small></p>
                                                    </div>
                                                </a>
                                            ))
                                        }

                                        {/* <a className="dropdown-item ml-1 px-3 py-2">
                                                    <div className="flex-fill" style={{pointerEvents:'none'}}>
                                                        <p className="mb-1 link-custom d-inline-block rounded">
                                                            <strong className="text-custom-light">test</strong>
                                                            <small className="text-muted ml-1">()</small>
                                                        </p>
                                                        <p className="text-muted text-wrap-custom my-0 d-flex"><small>x</small></p>
                                                        <p className="text-muted text-wrap-custom my-0 d-flex"><small>x</small></p>
                                                        <p className="text-muted text-wrap-custom my-0 d-flex"><small>x</small></p>
                                                    </div>
                                                </a> */}
                                        <a className="dropdown-item ml-1 px-3 py-2 notFound hide" style={{ width: "300px", pointerEvents: "none" }}>No Data</a>
                                    </div>
                                    <button type="submit" className="btn btn-hover-custom rounded ml-2" onClick={(e) => { center(e) }}>
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 d-flex">
                            <div className="flex-fill" style={{ height: "calc(100vh - 225px)", minHeight: "250px" }}>
                                <GoogleMap pos={Pos} search={center} geo={Filter} refresh={RefreshMap} heat={heatMap} />
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="searchModal" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog" role="document" style={{ height: "calc(100vh - 20px)" }}>
                            <div className="modal-content h-100">

                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-1 p-0">
                                            <div className="btn btn-hover-custom rounded" data-dismiss="modal">
                                                <i className="fa fa-lg fa-angle-left" style={{ transform: 'scale(1.25,1.25)' }}></i>
                                            </div>
                                        </div>
                                        <div className="col-11 pl-1">
                                            <div className="input-group">
                                                <input className="form-control bg-transparent border-0 rounded-pill pr-4 pl-3" type="search" id="maps-search-input-modal" onKeyPress={(e) => { center(e) }} onKeyUp={(e) => { onInput(e, 'modal') }} onFocus={(e) => { ShowAll(e) }} placeholder="Search..." autoComplete="off">
                                                </input>
                                                <div className="align-middle px-1 text-muted clearSearch" style={{ marginLeft: "-30px", marginRight: '12.5px', paddingTop: "5px", cursor: "pointer", zIndex: "5", display: "none" }} onClick={() => { clear(); ShowAllModal() }}><i className="fa fa-times" style={{ pointerEvents: 'none' }}></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4">
                                        <div className="overflow-custom w-100" id="maps-search-item-modal" style={{ maxHeight: "calc(100vh - 100px)" }}>
                                            {
                                                resGeo.map((val, index) => (
                                                    <div className={index == resGeo.length - 1 ? "col-12 py-1" : "col-12 border-bottom border-custom-dark py-1"} key={index} data-id={index} onClick={(e) => { search(e); center({ lat: val.lat, lng: val.lng }) }} data-dismiss="modal">
                                                        <div className="ml-1 px-3 py-2" style={{ pointerEvents: 'none' }} >
                                                            <div className="flex-fill" style={{ pointerEvents: 'none' }}>
                                                                <p className="mb-1 link-custom d-inline-block rounded">
                                                                    <strong className="text-custom-light">{val.label}</strong>
                                                                    <small className="text-muted ml-1">({val.model})</small>
                                                                </p>
                                                                <p className="text-muted text-wrap-custom my-0 d-flex"><small>{val.adr1}</small></p>
                                                                <p className="text-muted text-wrap-custom my-0 d-flex"><small>{val.adr2}</small></p>
                                                                <p className="text-muted text-wrap-custom my-0 d-flex"><small>{val.adr3}</small></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <div className="col-12 notFound hide">
                                                <div className="ml-1 px-3 py-2" style={{ pointerEvents: 'none' }} >
                                                    <div className="flex-fill text-center" style={{ pointerEvents: 'none' }}>
                                                        <p className="mb-1 link-custom d-inline-block rounded">
                                                            No Data
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else if (Response.message && Response.message == "No Data") {
        return (
            <>
                <div className="container-fluid p-0">
                    <div className="row title mb-4 pb-3 mx-0 border-bottom">
                        <div className="col-6 px-0">
                            <h3>Device Maps</h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 d-flex">
                            <div className="flex-fill" style={{ height: "calc(100vh - 225px)", minHeight: "250px" }}>
                                <GoogleMapReact
                                    defaultZoom={6}
                                    defaultCenter={{ lat: 13, lng: 100.5 }}
                                    options={{
                                        styles: mapStyles,
                                        minZoom: 6,
                                        maxZoom: 6,
                                    }}
                                    yesIWantToUseGoogleMapApiInternals
                                    bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
                                >
                                </GoogleMapReact>

                                <div style={{ background: 'rgba(10,11,13,0.75)', position: 'absolute', width: 'calc(100% - 23px)', height: '100%', top: '50%', left: '50%', transform: "translate(-50%,-50%)" }}>
                                    <div className="h-100">
                                        <div className="text-center">
                                            <h1 className="text-muted" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                                                No devices found
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else if (TimeOut == false) {
        setTimeout(function () {
            setTimeOut(true)
        }, 10000)
        return (
            <>
                <div className="container-fluid p-0 loading">
                    <div className="row" style={{ height: 'calc(100vh - 150px)' }}>
                    </div>
                </div>
            </>
        );
    } else if (TimeOut) {
        return (
            <>
                <div className="container-fluid p-0">
                    <div className="row" style={{ height: 'calc(100vh - 150px)' }}>
                        <div className="my-auto mx-auto text-center">
                            <h2 className="text-muted">Error</h2>
                            <h1 className="text-muted" style={{ fontSize: '4.5rem' }}>524</h1>
                            <h3 className="text-muted">Timeout Occurred</h3>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Content
