import styled from 'styled-components';

const MarkerGroup = styled.div`
  display: flex;
  width: ${props => (props.length === 2 ? '78px' : '106.5px')};
  background: #ff0000;
  border-radius: 25px;
  background-color: rgba(255,255,255,1);
  border: 0.5px solid #f3f2ef;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding:5px 12.5px;
  margin-left: ${props => (props.length === 2 ? '-40px' : '-52.5px')};
  margin-top: -25.5px;
  cursor:pointer;
`;

export default MarkerGroup;
