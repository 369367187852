import firebase from './components/firebase_noti';
import '@firebase/messaging';
import '@firebase/database';
import '@firebase/firestore';

const deviceList = [
  '7284e460-7746-11eb-aaf4-7181de1ac875',
  '72846f31-7746-11eb-aaf4-7181de1ac875',
  '72846f32-7746-11eb-aaf4-7181de1ac875',
  '72877c70-7746-11eb-aaf4-7181de1ac875',
  '77005a60-7746-11eb-aaf4-7181de1ac875',
  '74241570-7746-11eb-aaf4-7181de1ac875',
  '74241571-7746-11eb-aaf4-7181de1ac875',
  '74246390-7746-11eb-aaf4-7181de1ac875',
  '74283420-7746-11eb-aaf4-7181de1ac875',
  '742a08e0-7746-11eb-aaf4-7181de1ac875',
  '74344210-7746-11eb-aaf4-7181de1ac875',
  '74374f50-7746-11eb-aaf4-7181de1ac875',
  '743e2d20-7746-11eb-aaf4-7181de1ac875',
  '744299f0-7746-11eb-aaf4-7181de1ac875',
  '7448b470-7746-11eb-aaf4-7181de1ac875',
  '7448b471-7746-11eb-aaf4-7181de1ac875',
  '744fb950-7746-11eb-aaf4-7181de1ac875',
  '750107f0-7746-11eb-aaf4-7181de1ac875',
  '7501f250-7746-11eb-aaf4-7181de1ac875',
  '764a02b0-7746-11eb-aaf4-7181de1ac875',
  '764c73b0-7746-11eb-aaf4-7181de1ac875',
  '774ea170-7746-11eb-aaf4-7181de1ac875',
  '77509d40-7746-11eb-aaf4-7181de1ac875',
  '78d93910-7746-11eb-aaf4-7181de1ac875'
]

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.app('noti').messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    // console.log(token);
    await getStore(token);
    return token;
  } catch (error) {
    console.error(error);
  }
};
let alarm = {};
async function getStore(token) {
  for (let device of deviceList) {
    alarm = {}
    const store = firebase.app('noti').firestore().collection('FCMToken').where('key', '==', device);
    const doc = await store.get();
    doc.forEach(change => {
      alarm = change.data();
    }, err => {
      // console.log(err)
    })
    // console.log(doc.docs[0]);
    // console.log(doc.docs[0])
    if (Object.keys(alarm).length !== 0) {
      doc.docs[0].ref.update({
        key: alarm.key,
        token: token,
        imei: alarm.imei
      })
    }
  }
}


// function getReal(alarm) {
//   // console.log(alarm)
//   let alm = [];
//   firebase.app('noti').database().ref().once('value', (snapshot) => {
//     // console.log(snapshot.val())
//     let key;
//     let imei;
//     console.log(alarm)
//     for (let a in alarm) {
//       key = alarm[a].key;
//       imei = alarm[a].imei;
//       for (let [k, v] of Object.entries(snapshot.val())) {
//         console.log(k, v)
//         if (v.imei != null && v.imei === imei && k === key) {
//           console.log(key, imei)
//           alm.push({
//             key: k,
//             imei: v.imei
//           });
//           // addNewTokens(alm)
//         }
//       }
//     }
//   }, err => {
//     console.log(err)
//   })
// }

// function addNewTokens(alm) {
//   // let key;
//   // let imei;
//   // console.log(alm)
//   for (let a in alm) {
//     console.log(alm[a].key, alm[a].imei)
//     // key = alm[a].key;
//     // imei = alm[a].imei;
//   }
//   // firebase.app('noti').firestore().collection("FCMToken").where('key', '==', key)({
//   //   imei: imei,
//   //   key: key,
//   //   token: token
//   // })
// }


// pushes = [];
    // for (let [k, v] of Object.entries(snapshot.val())) {
    //   console.log(k, v.imei)
    //   pushes.push({
    //     imei: v.imei,
    //     key: k
    //   })
    // }