import React, { useState, useEffect, useCallback } from 'react'
import fire from '../../firebase'
import 'firebase'
import { RealtimeDataFormat } from "../../../customLibs/dataFormat";
import { useHistory, Link } from 'react-router-dom';

const $ = require('jquery');
let pageSize = 20;
let page = 1;
let order = 0;

async function getDeviceList(credentials) {
    return fetch('https://dixell.snapx.cloud/api/deviceListByUserSQL', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(response => response.json())
}

async function setFavDevice(credentials) {
    return fetch('https://dixell.snapx.cloud/api/updateFavDevice', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(response => response.json())
}

function Content() {

    const [TimeOut, setTimeOut] = useState(false);
    const [Response, setResponse] = useState({});
    const [dataReal, setData] = useState({});
    const [DeviceList, setDeviceList] = useState([]);
    const [PageConfig, setPageConfig] = useState({});
    let obj = {};
    let db = fire.app('realtime').database();
    const history = useHistory();

    useEffect(() => {
        changePage(0)
    }, [])

    const changePage = async (n) => {
        page = page + n
        let resList = await getDeviceList(
            {
                user: localStorage.getItem("user"),
                size: pageSize,
                page: page - 1,
                order: order //0:fav,1:new,2:old
            }
        )

        if ((Object.keys(resList).length) > 0 && resList.deviceID.length > 0) {
            setResponse({ message: "Success" })
        } else {
            setResponse({ message: "No Data" })
        }

        for (let [i, d] of resList.deviceID.entries()) {
            resList.deviceID[i].attr = JSON.parse(d.attr)
            resList.deviceID[i].lib = JSON.parse(d.lib)
        }
        setDeviceList(resList.deviceID)
        delete resList.deviceID
        setPageConfig(resList)
    }

    const setFav = async (e, id, fav) => {
        if (localStorage.perm == "Y") {
            if (fav == "Y") {
                fav = "N"
                e.target.setAttribute('fill', 'none')
                e.target.setAttribute('stroke', '#454752')
            } else {
                fav = "Y"
                e.target.setAttribute('fill', '#348cbf')
                e.target.setAttribute('stroke', '#348cbf')
            }
            let resFav = await setFavDevice(
                {
                    id: id,
                    fav: fav //Y,N
                }
            )

            if (resFav.status) {
                let Arr = DeviceList;
                for (let i = 0; i < Arr.length; i++) {
                    if (Arr[i].id == id) {
                        Arr[i].fav = fav
                    }
                }
                setDeviceList(Arr)
            } else {
                setDeviceList(DeviceList)
            }
        }
    }

    useEffect(async () => {
        for (let d of DeviceList) {
            getLiveData(d.id, d.lib)
        }
    }, [DeviceList, PageConfig])

    function getLiveData(id, lib) {
        const unsubscribe = db.ref('/' + id).on('value', (snapshot) => {
            let fetchedTasks = {};
            snapshot.forEach(childSnapshot => {
                const key = childSnapshot.key;
                const data = childSnapshot.val();
                fetchedTasks[key] = data;
            });
            let FormattedData = RealtimeDataFormat(fetchedTasks.d, lib);
            obj[id] = FormattedData;
            setData(obj)
            return () => db.off('value', unsubscribe);
        });
    }

    // const openScreen = useCallback(e, id) => {
    //     if (e.target.tagName != "svg") {
    //         const history = useHistory();

    //         // var link = document.createElement("Link");

    //         // link.setAttribute('to', 'pathname:/list/' + id)
    //         // document.body.appendChild(link);
    //         // link.click();
    //         // window.location("/list/" + id)
    //         useCallback(() => history.push('/sample'), [history])
    //     }
    // }

    const openScreen = useCallback((e, id) => {
        if (e.target.tagName != "svg") {
            history.push('/list/' + id)
        }
    }, [history]);

    const Request = (e, req, num) => {
        for (let i = 0; i < e.target.parentElement.children.length; i++) {
            e.target.parentElement.children[i].classList.remove('active')
        }
        e.target.classList.add('active')
        if (req == 'size') {
            pageSize = num
            page = 1
            changePage(0)
        } else if (req == 'sort') {
            order = num
            changePage(0)
        }
    }

    const divData = DeviceList.map((val, index) => {
        let key1, value1;
        if (dataReal[val.id] && dataReal[val.id].ai && Object.keys(dataReal[val.id].ai).length > 0) {
            if (dataReal[val.id].ai["Probe 1"]) {
                value1 = dataReal[val.id].ai["Probe 1"]
                key1 = "Probe 1"
            } else if (dataReal[val.id].ai["Probe 2"]) {
                value1 = dataReal[val.id].ai["Probe 2"]
                key1 = "Probe 2";
            } else if (dataReal[val.id].ai[Object.keys(dataReal[val.id].ai).filter(x => x.includes('Current'))[0]]) {
                value1 = dataReal[val.id].ai[Object.keys(dataReal[val.id].ai).filter(x => x.includes('Current'))[0]]
                key1 = Object.keys(dataReal[val.id].ai).filter(x => x.includes('Current'))[0];
            } else {
                key1 = Object.keys(dataReal[val.id].ai)[0];
                value1 = dataReal[val.id].ai[key1];
            }
        }

        return (
            <div className="col-md-6 col-lg-5 col-xl-4 col-xxl-2 px-2" key={index} onClick={(e) => { openScreen(e, val.id) }}>
                {/* <div className="col-md-6 col-lg-5 col-xl-4 col-xxl-2 px-2" key={index}> */}
                <div className="card card-hoverable rounded-lg mb-3 shadow-none">
                    <div className="card-body row pt-3 pb-2 px-4">
                        <div className="col-12">
                            <h5 className="card-title mb-2 text-custom-light d-flex">
                                <div className="flex-shrink-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={val.fav == "Y" ? '#348cbf' : 'none'} stroke={val.fav == "Y" ? '#348cbf' : '#454752'} strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ strokeWidth: '2.5 ', transform: 'scale(0.8,0.8)', verticalAlign: 'middle', marginLeft: '-5px' }} onClick={(e) => { setFav(e, val.id, val.fav) }}><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" style={{ pointerEvents: 'none' }}></polygon></svg>
                                </div>
                                <div className="w-100 text-truncate">
                                    <span className="ml-2 align-middle">{val.label != "" ? val.label : val.attr.alias}</span>
                                </div>
                                {/* <button type="button" className="btn align-middle btn-hover-custom-noBG dropdown-toggle rounded float-right" id={"moreOption" + index} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="More options" style={{ marginTop: '-7.5px', marginRight: '-17.5px' }}>
                                    <i className="fa fa-ellipsis-v" style={{ pointerEvents: 'none' }}></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right mx-2" aria-labelledby={"moreOption" + index}>
                                    <a className="dropdown-item px-3 py-2">
                                        <span className="ml-1 align-middle">Mark favorite</span>
                                    </a>
                                </div> */}
                            </h5>
                            {
                                dataReal[val.id] ?
                                    dataReal[val.id].ai && Object.keys(dataReal[val.id].ai).length > 0 && DeviceList[index].connect === 1 ?
                                        <>
                                            <h1 className="display-5 my-1">
                                                {value1.split(" ")[0]}
                                                <small className="text-custom ml-2"><small>{value1.split(" ")[1]}</small></small>
                                            </h1>
                                            <p className="text-muted mb-1">{key1}</p>
                                        </>
                                        :
                                        <h1 className="display-5 my-3 text-body" style={{ lineHeight: '1.375' }}>Inactive</h1>
                                    :
                                    <div className="flex-fill text-center">
                                        <h3 className="display-5 my-3 text-body" style={{ lineHeight: '1.85' }}>
                                            <i className="fa fa-circle-notch fa-spin fa-lg fa-fw" style={{ opacity: "0.5" }}></i>
                                        </h3>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    });

    if (Response.message && Response.message == "Success" && DeviceList.length > 0) {
        return (
            <>
                <div className="container-fluid p-0">
                    <div className="row title mb-4 pb-3 mx-0 border-bottom">
                        <div className="col-6 px-0">
                            <h3>Overview</h3>
                        </div>
                        <div className="col-6 px-0 text-right">
                            <div className="float-right mt-0 mt-sm-1 mr-2">
                                {/* <div className="mr-2"> */}
                                {/* <form className="form-inline mr-2 d-none d-md-inline">
                                <input className="form-control bg-transparent search-custom border-0 rounded-pill shadow-none" type="text" placeholder="Search..." style={{ marginTop: '-3.5px', marginBottom: '-1.5px', paddingRight: '35px' }}>
                                </input>
                                <button type="submit" className="btn btn-search-custom border-none bg-transparent rounded-pill">
                                    <i className="fa fa-search"></i>
                                </button>
                            </form> */}
                                <div className=" d-inline-block">
                                    {/* <div className="d-none d-sm-inline-block sort-hover rounded px-2 py-1 ml-1 searchHideActive" onClick={()=>{$('.searchHideActive').addClass('active')}}>
                                    <i className="fa fa-search mr-0 text-muted" style={{ transform: 'scale(0.9,0.9)' }}></i>
                                </div>
                                <div className="d-inline-block showOnSearch">
                                    <form className="form-inline mr-2 d-inline">
                                        <input className="form-control bg-transparent search-custom border-0 rounded-pill shadow-none pl-3" type="text" placeholder="Search..." style={{ marginTop: '-3.5px', marginBottom: '-1.5px', paddingRight: '35px' }}>
                                        </input>
                                        <button type="submit" className="btn btn-search-custom border-none bg-transparent rounded-pill">
                                            <i className="fa fa-search"></i>
                                        </button>
                                        <a className="btn border-none bg-transparent rounded-pill ml-2 close-custom text-body" onClick={()=>{$('.searchHideActive').removeClass('active')}}>
                                            <i className="fa fa-times"></i>
                                        </a>
                                    </form>
                                </div> */}
                                    <div className="d-inline-block">
                                        <div className="d-inline-block">
                                            <div className="sort-hover rounded px-2 py-1 ml-1" id="sortMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Sort options" >
                                                {/* <i className="fa fa-filter mr-0 d-inline-block text-muted" style={{ transform: 'scale(0.7,0.7)' }}></i> */}
                                                <i className="fa fa-sort mr-0 d-inline-block text-muted" style={{ transform: 'scale(0.8,0.8)' }}></i>
                                            </div>
                                            <div className="dropdown-menu dropdown-menu-right mx-2 py-1" aria-labelledby="sortMenu">
                                                <a className="dropdown-item px-3 py-2 active" style={{ fontSize: '80%' }} onClick={(e) => { Request(e, 'sort', 0) }}>
                                                    <span className="ml-1 align-middle">Favorite (Default)</span>
                                                </a>
                                                <a className="dropdown-item px-3 py-2" style={{ fontSize: '80%' }} onClick={(e) => { Request(e, 'sort', 1) }}>
                                                    <span className="ml-1 align-middle">Newest</span>
                                                </a>
                                                <a className="dropdown-item px-3 py-2" style={{ fontSize: '80%' }} onClick={(e) => { Request(e, 'sort', 2) }}>
                                                    <span className="ml-1 align-middle">Oldest</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="d-none d-sm-inline-block">
                                            <div className="sort-hover rounded px-2 py-1 ml-1" id="sizeMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Page size" >
                                                {/* <i className="fa fa-sort mr-0 mr-md-2 text-muted" style={{ transform: 'scale(0.8,0.8)' }}></i> */}
                                                <span>{((pageSize * page) - pageSize) + 1}</span>
                                                <span className={DeviceList.length > 0 ? "mx-1" : "d-none"}>-</span>
                                                <span className={DeviceList.length > 0 ? "" : "d-none"}>{((pageSize * page) - pageSize) + DeviceList.length}</span>
                                                <small className="mx-2">from</small>
                                                <span className="">{PageConfig.totalElements}</span>
                                            </div>
                                            <div className="dropdown-menu dropdown-menu-right mx-2 py-1" aria-labelledby="sizeMenu">
                                                <a className="dropdown-item px-3 py-2 active" style={{ fontSize: '80%' }} onClick={(e) => { Request(e, 'size', 20) }}>
                                                    <span className="ml-1 align-middle">20 (Default)</span>
                                                </a>
                                                <a className="dropdown-item px-3 py-2" style={{ fontSize: '80%' }} onClick={(e) => { Request(e, 'size', 50) }}>
                                                    <span className="ml-1 align-middle">50</span>
                                                </a>
                                                <a className="dropdown-item px-3 py-2" style={{ fontSize: '80%' }} onClick={(e) => { Request(e, 'size', 100) }}>
                                                    <span className="ml-1 align-middle">100</span>
                                                </a>
                                            </div>
                                        </div>
                                        <span className="ml-0 ml-md-2">
                                            <a className={page > 1 ? "text-custom-light mx-2 p-1 rounded-circle page-arrow-active" : "text-body mx-2 p-1 rounded-circle"}
                                                style={page > 1 ? { pointerEvents: 'auto', cursor: 'pointer' } : { pointerEvents: 'none', cursor: 'default' }}
                                                onClick={() => { changePage(-1) }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ marginBottom: "2.5px", marginLeft: "-1px", marginRight: "1px", strokeWidth: page == 1 ? "2" : "3", pointerEvents: 'none' }}><polyline points="15 18 9 12 15 6"></polyline></svg>
                                            </a>
                                            <a className={PageConfig.hasNext ? "text-custom-light mx-2 p-1 rounded-circle page-arrow-active" : "text-body mx-2 p-1 rounded-circle"}
                                                style={PageConfig.hasNext ? { pointerEvents: 'auto', cursor: 'pointer' } : { pointerEvents: 'none', cursor: 'default' }}
                                                onClick={() => { changePage(1) }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ marginBottom: "2.5px", marginLeft: "1px", marginRight: "-1px", strokeWidth: PageConfig.hasNext ? "3" : "2", pointerEvents: 'none' }}><polyline points="9 18 15 12 9 6"></polyline></svg>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex">
                            <div className="w-100">
                                <div className="row">
                                    {divData}

                                    {/* Test */}
                                    {/* <div className="col-md-6 col-lg-5 col-xl-4 col-xxl-2 px-2">
                                        <div className="card rounded-lg mb-3 shadow-none">
                                            <div className="card-body row pt-3 pb-2 px-4">
                                                <div className="col-12">
                                                    <h5 className="card-title mb-2 text-custom-light">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill='#348cbf' stroke='#348cbf' strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ strokeWidth: '2.5 ', transform: 'scale(0.8,0.8)', verticalAlign: 'middle', marginLeft: '-5px' }}><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                                                        <span className="ml-2 align-middle">Any active device</span>
                                                        <button type="button" className="btn align-middle btn-hover-custom-noBG dropdown-toggle rounded float-right" id={"moreOption" + 'test'} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="More options" style={{ marginTop: '-7.5px', marginRight: '-17.5px' }}>
                                                            <i className="fa fa-ellipsis-v" style={{ pointerEvents: 'none' }}></i>
                                                        </button>
                                                        <div className="dropdown-menu dropdown-menu-right mx-2" aria-labelledby={"moreOption" + 'test'}>
                                                            <a className="dropdown-item px-3 py-2">
                                                                <span className="ml-1 align-middle">Mark favorite</span>
                                                            </a>
                                                        </div>
                                                    </h5>
                                                    <div id="carouselExampleControls" className="carousel slide text-center" data-ride="carousel" style={{ height: "80px" }}>
                                                            <ol className="carousel-indicators">
                                                                <span className="mx-1 active" data-target="#carouselExampleIndicators" data-slide-to="0">•</span>
                                                                <span className="mx-1" data-target="#carouselExampleIndicators" data-slide-to="1" >•</span>
                                                                <span className="mx-1" data-target="#carouselExampleIndicators" data-slide-to="2" >•</span>
                                                            </ol>
                                                        <div className="carousel-inner mx-auto  text-left">
                                                            <div className="carousel-item active">
                                                                <div>
                                                                    <h1 className="display-5 mt-1 mb-0">
                                                                        20.1
                                                    <small className="text-custom ml-2"><small>°C</small></small>
                                                                    </h1>
                                                                    <p className="text-muted mb-1">Test Value1</p>
                                                                </div>
                                                            </div>
                                                            <div className="carousel-item">

                                                                <div>
                                                                    <h1 className="display-5 my-1">
                                                                        20.2
                                                    <small className="text-custom ml-2"><small>°C</small></small>
                                                                    </h1>
                                                                    <p className="text-muted mb-1">Test Value2</p>
                                                                </div>
                                                            </div>
                                                            <div className="carousel-item">

                                                                <div>
                                                                    <h1 className="display-5 my-1">
                                                                        20.3
                                                    <small className="text-custom ml-2"><small>°C</small></small>
                                                                    </h1>
                                                                    <p className="text-muted mb-1">Test Value3</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else if (Response.message && Response.message == "No Data") {
        return (
            <>
                <div className="container-fluid p-0">
                    <div className="row title mb-4 pb-3 mx-0 border-bottom">
                        <div className="col-12 px-0">
                            <h3>Overview</h3>
                        </div>
                    </div>
                    <div className="row" style={{ height: 'calc(100vh - 250px)' }}>
                        <div className="my-auto mx-auto text-center">
                            <h2 className="text-muted">No devices found</h2>
                        </div>
                    </div>
                </div>
            </>
        );
    } else if (TimeOut == false) {
        setTimeout(function () {
            setTimeOut(true)
        }, 10000)
        return (
            <>
                <div className="container-fluid p-0 loading">
                    <div className="row" style={{ height: 'calc(100vh - 150px)' }}>
                    </div>
                </div>
            </>
        );
    } else if (TimeOut) {
        return (
            <>
                <div className="container-fluid p-0">
                    <div className="row" style={{ height: 'calc(100vh - 150px)' }}>
                        <div className="my-auto mx-auto text-center">
                            <h2 className="text-muted">Error</h2>
                            <h1 className="text-muted" style={{ fontSize: '4.5rem' }}>524</h1>
                            <h3 className="text-muted">Timeout Occurred</h3>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Content