import bitwise from 'bitwise'

export const RealtimeDataFormat = (data, lib) => {
    // data คือ data.d ใน live

    // console.log(data)
    // console.log(lib)

    let res = {};

    // เช็คว่า data กับ library มาครบหรือยัง
    if (data && lib) {

        // วนเอา key(หมวดของค่า) กับ ค่าที่เป็น Array ข้างใน 
        for (const [key, arr] of Object.entries(data)) {

            let ts, info, name, gain, dec, offset, unit;

            // ถ้า key เป็น timestamp
            if (key == "ts") {

                ts = arr.ticks;
                res[key] = ts;

            }

            // เช็คว่าเป็น library มี parameter หมวดนั้นรึเปล่า (**กันพี่ต้นใส่ parameter แปลกๆมาเกิน**)
            else if (lib[key]) {

                // สร้าง obj รอรับค่า
                res[key] = {};

                // วนเอา Adress ของ ค่าในแต่ละ หมวด
                for (let [categoryAdr, value] of Object.entries(arr)) {
                    let adrSplit = categoryAdr.split('_'); // แยกหมวดกับจำนวนอุปกรณ์
                    let adr = parseInt(adrSplit[0]); // ได้หมวด
                    let deviceNum = parseInt(adrSplit[1]); // ได้เลขอุปกรณ์

                    // เอาค่าที่ส่งมาของแต่ละหมวด
                    value = value.v;

                    // ไว้ดูค่าดิบตอนคำนวนค่าเสร็จแล้ว
                    let rawValue = value;

                    // เช็คว่าเป็น Analog หรือ Digital ถ้าเป็น analog ตอนแมพค่าจะใส่ path ไปตรงๆแล้วเจอเลย
                    if (adr && lib[key][adr]) {

                        // เอา information ของ parameter นั้นๆ
                        info = lib[key][adr];
                        name = info.name;
                        gain = info.gain;
                        dec = info.dec;
                        offset = info.offset;
                        unit = info.unit.replace('ยฐ', "°");

                        // เช็คว่าค่าติดลบไหม
                        if (value > 32767) {
                            value = value - 65536
                        }

                        // คำนวนค่าตาม library
                        res[key][name] = ((value * gain) + offset).toFixed(dec) + " " + unit;

                    } else {

                        let showValue; // Parameter For test

                        // ไว้รับค่าเพื่อ Swap Byte
                        let valueRaw;

                        // ค่า cmdr ของ parameter
                        let mapAdr = adrSplit[0];
                        // ex. output => 0102080010

                        //เช็คว่าต้อง mask กี่บิท
                        let bitlength = parseInt(mapAdr.substring(mapAdr.length - 4, mapAdr.length), 16);
                        // ex. output => 16

                        //เช็ค Function Code ว่าเป็น Modbus function code ไหน
                        let fnCode = mapAdr.substring(0, 2);
                        // ex. output => 01

                        //01 Read Coils
                        //02 Read Discrete Input
                        //03 Read Holding Registers
                        //04 Read Input Registers
                        //05 Write Single Coil
                        //06 Write Single Register
                        //15 Write Multiple Coils
                        //16 Write Multiple Registers

                        if (fnCode == "01" || fnCode == "02" || fnCode == "05" || fnCode == "06") {

                            //เทียบ length *1
                            bitlength = bitlength * 1;

                            if (bitlength <= 8) {
                                // 8 Bit

                                valueRaw = value.toString(2).substring(value.length - 8, value.length).padStart(8, "0")
                                // เอาแค่ 8 bit

                                value = valueRaw
                                // เอาแค่ 8 bit

                                showValue = value; // Parameter For test

                            } else if (bitlength < 32) {
                                // 16 Bit

                                valueRaw = value.toString(2).padStart(16, '0').match(/.{1,8}/g);
                                // **8 คือ เอาทีละ 8 ตัว** 32bit => result ["A","B","C","D"] **Array ละ 8 Bit 4 array**

                                value = valueRaw[1] + valueRaw[0];
                                // ["D","C","B","A"] result  => DCBA;

                                showValue = value; // Parameter For test

                            } else {
                                // 32ฺ Bit

                                valueRaw = value.toString(2).padStart(32, '0').match(/.{1,8}/g);
                                // **8 คือ เอาทีละ 8 ตัว** 32bit => result ["A","B","C","D"] **Array ละ 8 Bit 4 array**

                                value = valueRaw[3] + valueRaw[2] + valueRaw[1] + valueRaw[0];
                                // ["D","C","B","A"] result  => DCBA;

                                showValue = value; // Parameter For test
                            }


                        } else if (fnCode == "03" || fnCode == "04" || fnCode == "15" || fnCode == "16") {

                            //เทียบ length *16
                            bitlength = bitlength * 16;

                            if (bitlength <= 8) {
                                // 8 Bit

                                valueRaw = value.toString(2).substring(value.length - 8, value.length).padStart(8, "0")
                                // เอาแค่ 8 bit

                                value = valueRaw
                                // เอาแค่ 8 bit


                                showValue = value; // Parameter For test

                            } else if (bitlength < 32) {
                                // 16 Bit

                                valueRaw = value.toString(2).substring(value.length - 16, value.length).padStart(16, "0")
                                // เอาแค่ 16 bit

                                value = valueRaw
                                // เอาแค่ 16 bit

                                showValue = value; // Parameter For test

                            } else {
                                // 32ฺ Bit

                                valueRaw = value.toString(2).substring(value.length - 32, value.length).padStart(32, "0")
                                // เอาแค่ 32 bit

                                value = valueRaw
                                // เอาแค่ 32 bit

                                showValue = value; // Parameter For test
                            }
                        }

                        // วนเอา Adr(libKey) กับ ค่า Info(libValue) ใน library
                        Object.entries(lib[key]).forEach(([libKey, libValue]) => {
                            adr = libKey; // Adr ของ parameter
                            // ex. output => 40000

                            name = libValue.name; // ชื่อของ parameter
                            // ex. output => Low Value Pb 1

                            // เช็คว่า cmdr แมพเจอไหม
                            if (libValue.cmdr == mapAdr) {

                                // เอา Mask กับ Val ใน library
                                let bitMasker = parseInt(libValue.mask, 16).toString(2)
                                // ex. output => 1 0000 0000

                                let valMasker = parseInt(libValue.val, 16).toString(2)
                                // ex. output => 1 0000 0000

                                let valMaskConvert = "";
                                let result;

                                let showMask = bitMasker; // Parameter For test
                                let showValMask = valMasker; // Parameter For test
                                let showValMaskConvert; // Parameter For test


                                if (bitlength <= 8) {

                                    bitMasker = bitMasker.padStart(8, '0')
                                    // ตัดเอาแค่ 8 ตัวหลัง result  => B;

                                    valMasker = valMasker.padStart(8, '0')
                                    // ตัดเอาแค่ 8 ตัวหลัง result  => B;

                                } else if (bitlength < 32) {

                                    bitMasker = bitMasker.padStart(16, '0')
                                    //เติม bit ให้ครบ16

                                    valMasker = valMasker.padStart(16, '0')
                                    //เติม bit ให้ครบ16

                                } else {

                                    bitMasker = bitMasker.padStart(32, '0')
                                    //เติม bit ให้ครบ32

                                    valMasker = valMasker.padStart(32, '0')
                                    //เติม bit ให้ครบ32

                                }

                                //bitwise ค่าจริง กับ ค่าmask ใน library
                                if (bitwise.bits.and(value, valMasker).indexOf(1) > -1) {

                                    //ถ้า true จำได้ค่า bit ที่ต้อง mask เป็น 1 แค่บิทเดียว
                                    valMaskConvert = bitwise.bits.and(value, valMasker).toString().replace(/,/g, "")
                                    // ex. output => 0000 0001

                                } else {

                                    //ถ้า false ให้คืน value ค่าเดิม
                                    valMaskConvert = value
                                    // ex. output => 0000 1001

                                }

                                result = bitwise.bits.and(bitMasker, value).indexOf(1) > -1 ? valMaskConvert == valMasker : false;
                                //bitwise ค่าจริง กับ ค่าmask ใน library => ถ้า true ให้เทียบกับค่า val ใน library อีกทีว่าตรงกันไหม (**กันกรณีที่ค่า mask มีค่าซ้ำกัน**)

                                let vSplit, mSplit, vmSplit, vmcSplit; // Parameter For test

                                vSplit = value.padStart(16, '-').match(/.{1,8}/g); // Parameter For test
                                mSplit = bitMasker.padStart(16, '-').match(/.{1,8}/g); // Parameter For test
                                vmSplit = valMasker.padStart(16, '-').match(/.{1,8}/g); // Parameter For test
                                vmcSplit = valMaskConvert.padStart(16, '-').match(/.{1,8}/g); // Parameter For test

                                showValue = vSplit[0] + " " + vSplit[1] // Parameter For test
                                showMask = mSplit[0] + " " + mSplit[1] // Parameter For test
                                showValMask = vmSplit[0] + " " + vmSplit[1] // Parameter For test
                                showValMaskConvert = vmcSplit[0] + " " + vmcSplit[1] // Parameter For test

                                //For test
                                // console.log("[" + key + "]", name)
                                // console.log("fnC ", fnCode, "length", mapAdr, bitlength)
                                // console.log('val ', showValue, rawValue)
                                // console.log('mskM', showMask, libValue.mask)
                                // console.log('mskV', showValMask, libValue.val)
                                // console.log('valC', showValMaskConvert, result)

                                res[key][name] = result;
                            } else {

                                // ถ้า mask cmdr ของ value กับ library ไม่เจอเช็คว่าเป็น Analog หรือ Digital

                                //check data type
                                if (libValue.unit == " ") {

                                    //Analog คืนค่่า - Unit
                                    res[key][name] = "- " + libValue.unit.replace('ยฐ', "°");

                                } else {

                                    //Digital คืนค่า boolean
                                    res[key][name] = false

                                }
                            }
                        });
                    }
                }
            }
        }
        // console.log(res)
        return (res)
    } else {
        return (res)
    }
}

export const TimeSeriesDataFormat = (data, lib) => {
    // console.log(data)
    // console.log(lib)

    let ts, v, vCal, info, name, gain, dec, offset, unit;
    let res = {};
    res["timeStamp"] = [];

    res["AI"] = {};
    res["DI"] = {};
    res["AI"]["Series"] = [];
    res["DI"]["Series"] = [];

    const ts_data = data.latest

    for (let val of Object.values(ts_data)) {
        const { ts } = val
        res["timeStamp"].push(ts);
    }

    delete data.latest

    const default_ai = new Array(res["timeStamp"].length).fill(0)
    const default_di = new Array(res["timeStamp"].length).fill(0)

    for (let [key, arr] of Object.entries(data)) {

        // if (key == "latest") {
        //     for (const value of arr) {
        //         ts = value.ts
        //         res["timeStamp"].push(ts);
        //     };

        // } else {

        let adrSplit = key.split('_');
        key = key.substring(0, 2);
        // console.log(adrSplit)

        key = adrSplit[0]
        let adr = (key.substring(2, key.length));
        let deviceNum = parseInt(adrSplit[1]);
        key = adrSplit[0].substring(0, 2);

        if (lib[key] && lib[key][adr]) {
            info = lib[key][adr];
            name = info.name;
            gain = info.gain;
            dec = info.dec;
            offset = info.offset;
            unit = info.unit.replace('ยฐ', "°");

            let obj = {
                name: name,
                // data: [],
                data: [...default_ai],
                key: key,
                unit: unit
            };

            for (let value of arr) {
                ts = value.ts;
                let ts_index = res["timeStamp"].findIndex(x => x === ts)
                v = parseInt(value.value);

                if (v > 32767) {
                    v = v - 65536
                }

                vCal = ((v * gain) + offset).toFixed(dec);

                // obj.data.push(vCal)
                obj.data[ts_index] = vCal
            };
            res["AI"]["Series"].push(obj)

        } else {

            let mapAdr = adr;

            if (lib[key]) {
                Object.entries(lib[key]).forEach(([libKey, libValue]) => {
                    adr = libKey;

                    if (libValue.cmdr == mapAdr) {
                        name = libValue.name;
                        // console.log("[" + key + "]", name)

                        let obj = {
                            name: name,
                            // data: [],
                            data: [...default_di],
                            key: key
                        };

                        for (const value of arr) {
                            ts = value.ts;
                            let ts_index = res["timeStamp"].findIndex(x => x === ts)
                            v = parseInt(value.value);

                            let bitMasker = parseInt(libValue.mask, 16).toString(2);
                            let valMasker = parseInt(libValue.val, 16).toString(2);

                            let bitlength = parseInt(mapAdr.substring(mapAdr.length - 4, mapAdr.length), 16);

                            let showMask = parseInt(libValue.mask, 16);
                            let showValMask = parseInt(libValue.val, 16);

                            let showValue = v;

                            let valueRaw;

                            let valMaskConvert = "";

                            let fnCode = mapAdr.substring(0, 2);

                            if (fnCode == "01" || fnCode == "02" || fnCode == "05" || fnCode == "06") {

                                bitlength = bitlength * 1;

                                if (bitlength > 16) {

                                    valueRaw = v.toString(2).padStart(32, '0').match(/.{1,8}/g);

                                    v = valueRaw[3] + valueRaw[2] + valueRaw[1] + valueRaw[0];

                                } else if (bitlength > 8) {

                                    valueRaw = v.toString(2).padStart(16, '0').match(/.{1,8}/g);

                                    v = valueRaw[1] + valueRaw[0];

                                } else {

                                    v = v.toString(2).padStart(8, '0')
                                }

                            } else if (fnCode == "03" || fnCode == "04" || fnCode == "15" || fnCode == "16") {

                                bitlength = bitlength * 16;



                                if (bitlength > 16) {

                                    valueRaw = v.toString(2).substring(value.length - 32, value.length).padStart(32, "0")

                                    v = valueRaw

                                } else if (bitlength > 8) {

                                    valueRaw = v.toString(2).substring(value.length - 16, value.length).padStart(16, "0")

                                    v = valueRaw

                                } else {

                                    valueRaw = v.toString(2).substring(value.length - 8, value.length).padStart(8, "0")

                                    v = valueRaw

                                }

                            }


                            if (bitlength > 16) {

                                bitMasker = bitMasker.padStart(32, '0');

                                valMasker = valMasker.padStart(32, '0');

                            } else if (bitlength > 8) {

                                bitMasker = bitMasker.padStart(16, '0');

                                valMasker = valMasker.padStart(16, '0');

                            } else {

                                bitMasker = bitMasker.padStart(8, '0');

                                valMasker = valMasker.padStart(8, '0');

                            }

                            //bitwise ค่าจริง กับ ค่าmask ใน library
                            if (bitwise.bits.and(v, valMasker).indexOf(1) > -1) {

                                valMaskConvert = bitwise.bits.and(v, valMasker).toString().replace(/,/g, "")

                            } else {

                                valMaskConvert = v
                                //ถ้า false ให้คืน value ค่าเดิม

                            }

                            vCal = bitwise.bits.and(bitMasker, v).indexOf(1) > -1 ? valMaskConvert == valMasker : false;

                            // console.log('mskM', bitMasker, showMask)
                            // console.log('makV', valMasker, showValMask)
                            // console.log('val ', valMaskConvert, showValue, vCal)

                            // obj.data.push(vCal);
                            obj.data[ts_index] = vCal;
                        };
                        res["DI"]["Series"].push(obj)
                    }
                })
            }
        }
    }
    // }
    return res
}
