import React, { useState, useEffect } from 'react'
import { sortTable } from "../../../customLibs/sortTable";
import { tsFormat } from '../../../customLibs/timeStampFormat';
import fire from '../../firebase'
import 'firebase'
import Converter from '../../../customLibs/dataConverter'

const $ = require('jquery');


function DataTable(props) {
    const [TimeOut, setTimeOut] = useState(false);
    const [Data, setData] = useState({})

    useEffect(() => {
        setData(props.data)
        getDataFireBase()
    }, [props.data])

    const getDataFireBase = () => {
        let database = fire.app('realtime').database().ref('/' + props.id);
        const unsubscribe = database.on('value', (snapshot) => {
            let convertedData = Converter({ data: snapshot.val().d, lib: props.lib })
            convertedData.info = Object.assign(convertedData.info, snapshot.val().d.ts)
            setData(convertedData)
        })
        return () => database.off('value', unsubscribe);
    }

    if (Data) {
        return (
            <>
                {
                    Object.keys(Data).map((key, index) => {
                        if (key != "info") {
                            return (
                                <div className={key == "Alarms" ? "col-12 order-" + (Object.keys(Data).length - 1) : "col-12 col-xl-6 col-xxl-4"} key={index}>
                                    <div className="card p-3">
                                        <div className="card-header py-2 px-2">
                                            <h4 className="text-custom-important">{key}</h4>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table mb-0" id={"tablehead_" + (key.replace(/ /g, ''))}>
                                                <thead className="top-border">
                                                    <tr style={{ cursor: "pointer" }}>
                                                        <th style={{ width: "40%" }} className="align-middle my-auto" onClick={(e) => { sortTable(0, e) }}>Parameter name<small style={{ pointerEvents: "none" }} ><i></i></small></th>
                                                        <th style={{ width: "25%" }} className="text-center align-middle my-auto" onClick={(e) => { sortTable(1, e) }}>Value<small style={{ pointerEvents: "none" }}><i></i></small></th>
                                                        <th style={{ width: "35%" }} className="text-center align-middle my-auto" onClick={(e) => { sortTable(2, e) }}>Last update<small style={{ pointerEvents: "none" }}><i></i></small></th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="table-responsive overflow-custom" id='table' style={{ height: "276px", width: "calc(100% + 7.5px)" }}>
                                            <table className="table mb-0" id={"tablebody_" + (key.replace(/ /g, ''))}>
                                                <tbody>
                                                    {
                                                        typeof Data[key] == "object" ?
                                                            Object.entries(Data[key]).map((data, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td scope="row" className="align-middle" style={{ width: "40%" }}>
                                                                            <div className="text-truncate" style={{ maxWidth: (key.replace(/ /g, '')) == "Alarms" ? "" : "150px" }}>
                                                                                {data[0]}
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-center align-middle" style={{ width: "25%" }}>
                                                                            {
                                                                                typeof data[1] == "string" ?
                                                                                    data[1].split(' ')[0] == "No" ?
                                                                                        <span className="text-body">{data[1]}</span>
                                                                                        :
                                                                                        <span>{data[1].split(" ")[0]}<small className="text-custom-light ml-1">{data[1].split(" ")[1]}</small></span>
                                                                                    :
                                                                                    key == "Alarms" ?
                                                                                        <span style={{ color: data[1] ? "#ee4040" : "" }}>{data[1] ? "Fault" : "Normal"}</span>
                                                                                        :
                                                                                        <span style={{ color: data[1] ? "#2cc0b6" : "" }}>{data[1] ? "Active" : "Inactive"}</span>
                                                                            }
                                                                        </td>
                                                                        <td className="text-center align-middle" style={{ width: "35%" }}>{tsFormat(Data["info"].ticks, 'auto')}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td scope="row" colSpan="3" className="align-middle text-center text-body my-auto" style={{ width: "100%" }}>{Data[key]}</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </>
        )
    } else {
        return (
            <></>
        )
    }
}

export default DataTable