import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './route';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Routes/>
  </React.StrictMode>,
  document.getElementById('root')
);
registerServiceWorker();
 