import styled from 'styled-components';

const MarkerInGroupStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-left: -7px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  // border: 2px solid #6897dd;
  border: 2px solid #ddd;
  background-color: rgba(255,255,255,1);
  border-radius: 50%;
  background-size: cover;
  background-position: center;
`;

export default MarkerInGroupStyled;
