import React from 'react'
import Footer from './components/menu/footer'

function ForgetPass() {
  
  return (
    <>
      <div className="wrapper" id="loginScr">
        <div className="main" id="loginScr-Main">
          <main className="content">
            <div className="container-fluid d-flex h-100">
              <div className="row align-items-center align-self-center mx-auto w-100">
                <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-4 mx-auto mt-5">
                  <div className="card text-center px-3 px-sm-4 pt-4 pt-sm-5 pb-3 pb-sm-4" style={{ background: "rgba(0,0,0,0.35)", fontFamily: "prompt" }}>
                    <p className="display-4 text-custom-important p-0 mt-0 mb-4" style={{fontWeight:'300'}}>ลองนึกดีๆ</p>
                    <a className="mb-2" href="/">Ok! ขอลองใหม่</a>
                  </div>
                </div>
              </div>
            </div>
          </main>
            <Footer />
        </div>
      </div>
    </>
  );
}

export default ForgetPass;
