import styled from 'styled-components';

const MarkerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font-size: 14px;
  color: #fff;
  // text-transform: uppercase;
  transition: transform 0.25s ease;
  // border: 2.5px solid #2a86b9;
  border: 4.5px solid #f3f2ef;
  // background-color: rgba(255,255,255,1);
  background-color: #f8f8f5;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  transform:translate(-50%,-50%);
  cursor:pointer;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  
  // &:hover {
  //   transform: scale(1.5) translate(-35%,-35%);
  // }
`;

export default MarkerStyled;
