import { contains } from 'jquery';
import React, { useEffect, useState } from 'react'
import Footer from './components/menu/footer'
import { askForPermissioToReceiveNotifications } from "./push-notification";
import liff from '@line/liff';
const $ = require('jquery');

async function auth(credentials) {
  return fetch('https://dixell.snapx.cloud/api/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function authWithLine(credentials) {
  return fetch('https://dixell.snapx.cloud/api/authWithCode', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

const liffId = '1655780499-0mnokYEG';
//test = 1655796506-Amwz73NN

const version = "2.0.3";

function App() {
  const [login, setLogin] = useState({
    username: '',
    password: ''
  });

  const [user, setUser] = useState({});
  const [token, setIdToken] = useState("");
  const [lineLogin, setLineLogin] = useState(false);
  const [InviteCode, setInviteCode] = useState("");

  function onChange(e) {
    const { name, value } = e.target;

    setLogin({
      ...login,
      [name]: value
    });
  }

  useEffect(() => {
    let url = window.location.search.substring(1).split('=')[1]
    if (url && url.includes('liffClientId') === false)
      sessionStorage.setItem('redirectURL', url)
  }, [])

  async function onSubmit(e) {
    $('#loginMessage')[0].innerText = null;
    $('#loginScr-Main').addClass('onLoad')
    e.preventDefault()

    const { username, password } = login;
    const token = await auth({
      username: username,
      password: password
    });

    if (token.status) {
      await askForPermissioToReceiveNotifications();
      localStorage.setItem("name", token.name);
      localStorage.setItem("user", token.user);
      localStorage.setItem("code", token.code);
      localStorage.setItem("version", version);
      localStorage.setItem("perm", "Y");
      if (sessionStorage.getItem('redirectURL')) {
        window.location.replace("/list/" + sessionStorage.getItem('redirectURL'));
      } else {
        window.location.replace("/overview");
      }
    }
    else {
      $('#loginScr-Main').removeClass('onLoad')
      $('#loginMessage')[0].innerText = token.message.charAt(0).toUpperCase() + token.message.slice(1);
    }
  }

  const initLine = () => {
    $('#loginScr-Main').addClass('onLoad')
    liff.init({ liffId: liffId }, async () => {
      if (liff.isLoggedIn()) {
        $('#loginScr-Main').addClass('onLoad')
        await runApp();
      } else {
        $('#loginScr-Main').removeClass('onLoad')
        return;
      }
    }, err => console.error(err));
  }

  async function onLine(e) {
    $('#loginMessage')[0].innerText = null;
    $('#loginScr-Main').addClass('onLoad')
    e.preventDefault()

    liff.init({ liffId: liffId }, async () => {
      liff.login();
    }, err => console.error(err));
  }

  const runApp = () => {
    const idToken = liff.getIDToken();
    setIdToken(idToken);

    liff.getProfile().then(profile => {
      $('#loginScr-Main').removeClass('onLoad')
      $('#loginForm').attr('style', 'display:none');
      $('#confirmCode').removeAttr('style');

      setUser({
        name: profile.displayName,
        pic: profile.pictureUrl
      });
    }).catch(err => console.error(err));
  }



  const cancelComfirm = (e) => {
    e.preventDefault();
    localStorage.clear();
    $('#loginForm').removeAttr('style');
    $('#confirmCode').attr('style', 'display:none');
    $('#loginMessage')[0].innerText = ""
  }

  useEffect(() => {
    initLine();
  }, [lineLogin])

  const enterInvite = (e) => {
    const { name, value } = e.target;
    setInviteCode(value)
  }

  const confirmInvite = async (e) => {
    e.preventDefault()
    $('#loginScr-Main').addClass('onLoad')

    await authWithLine({
      code: InviteCode,
      uid: user.name,
      pic: user.pic,
      email: ""
    }).then(async (response) => {
      if (response.status) {
        await askForPermissioToReceiveNotifications();
        localStorage.setItem("name", response.name ? response.name : "");
        localStorage.setItem("user", response.user);
        localStorage.setItem("pic", user.pic);
        localStorage.setItem("perm", response.line_per);
        localStorage.setItem("version", version);
        localStorage.setItem("owner", response.nameOwner);
        if (sessionStorage.redirectURL) {
          window.location.replace("/list/" + sessionStorage.getItem('redirectURL'));
        } else {
          window.location.replace("/overview");
        }
      } else {
        $('#loginScr-Main').removeClass('onLoad')
        $('#loginMessage')[0].innerText = "Invitation code invalid !!"
      }
    })
  }

  const showPass = (e) => {
    e.target.previousElementSibling.type = 'text'
  }
  const hidePass = (e) => {
    e.target.previousElementSibling.type = 'password'
  }

  if (!localStorage.getItem("user")) {
    return (
      <>
        <div className="wrapper" id="loginScr">
          <div className="main" id="loginScr-Main">
            <main className="content">
              <div className="container-fluid d-flex h-100">
                <div className="row align-items-center align-self-center mx-auto w-100">
                  <div className="loginCard-wrapper mx-auto mt-5">
                    <div className="card rounded-lg px-4 py-4 py-md-5" style={{ background: "rgba(0,0,0,0.15)" }}>
                      <div className="row text-center mt-2 mt-md-0">
                        <img src="img/logo/SnapX_logo.svg" alt="SnapX" className="img-fluid col-10 col-sm-9 col-md-8 mb-4 mb-md-5 mx-auto" />
                      </div>
                      <form className="px-md-3 px-md-3 mb-2 text-center" id="loginForm">
                        {/* <form className="px-md-3 px-md-3 mb-2 text-center" id="loginForm" style={{ display: 'none' }}> */}
                        <div className="form-group text-left mb-4">
                          <div className="input-group">
                            <div className="input-group-text-custom">
                              <i className="align-middle fa fa-user" style={{ transform: 'scale(0.9,0.9)', zIndex: '99' }}></i>
                            </div>
                            <input type="text" className="form-control form-control-login rounded-0 bg-transparent" placeholder="Username" name="username" onChange={onChange} />
                          </div>
                        </div>
                        <div className="form-group text-left mb-2 mb-md-0">
                          <div className="input-group">
                            <div className="input-group-text-custom">
                              <i className="align-middle fa fa-lock" style={{ transform: 'scale(0.9,0.9)' }}></i>
                            </div>
                            <input type="password" className="form-control rounded-0 bg-transparent form-control-login" placeholder="Password" name="password" onChange={onChange} />
                            {/* <div className="input-group-text-custom showPasswordBtn inputActionBtn" onMouseDown={showPass} onMouseUp={hidePass}>
                              <i className="align-middle fa fa-eye" style={{ transform: 'scale(0.9,0.9)' }} style={{ pointerEvents: "none" }}></i>
                            </div> */}
                          </div>
                        </div>
                        <div className="form-group mx-1 mx-md-2 mt-3">
                          <div className="row">
                            <div className="col-sm-12 text-center text-sm-right mb-2 mb-sm-0">
                              <a className="loginLink">Forgot Password ?</a>
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-snapX-login-clean btn-sm-lg rounded-lg col-8 col-md-7 mt-2 mt-sm-4 py-2" onClick={onSubmit} type="submit">
                          <strong>
                            LOG IN
                          </strong>
                        </button>
                        <div className="row px-4 mt-3 mt-md-4  mb-0">
                          <hr className="border-top border-top-light flex-fill mx-2"></hr>
                          <p className="text-center mx-3 mt-1">Or</p>
                          <hr className="border-top border-top-light flex-fill mx-2"></hr>
                        </div>
                        <button className="btn btn-line-login btn-sm-lg rounded-lg col-8 col-md-7 mt-1 mt-sm-2 mt-md-3 py-2 text-custom-important" onClick={onLine} type="submit">Log in with LINE account</button>
                      </form>



                      <div id="confirmCode" style={{ display: "none" }}>
                        {/* <div id="confirmCode"> */}
                        <div className="text-center mb-3 mb-sm-4 py-2">
                          <img src={user.pic} className="rounded-circle shadow" style={{ width: '150px' }}></img>
                          <h5 className="mt-4">{user.name}</h5>
                        </div>
                        <form className="px-md-3 mt-2 mb-0 text-center">
                          <div className="form-group text-left">
                            <div className="input-group mb-2 mr-sm-2">
                              {/* <input type="text" className="form-control" placeholder="Enter your confirmation code" name="username" onChange={enterInvite} /> */}

                              <div className="input-group">
                                <div className="input-group-text-custom">
                                  <i className="align-middle fa fa-envelope" style={{ transform: 'scale(0.9,0.9)', zIndex: '99' }}></i>
                                </div>
                                <input type="text" className="form-control form-control-login rounded-0 bg-transparent" placeholder="Enter your invitation code" onChange={enterInvite} />
                              </div>
                              {/* <input type="text" className="form-control form-control-login rounded-lg bg-transparent" placeholder="Enter your confirmation code" onChange={enterInvite} /> */}

                              {/* <input type="text" className="form-control" placeholder="Enter your confirmation code" name="username" onChange={enterInvite} /> */}
                            </div>
                          </div>
                          <button className="btn btn-snapX-login-clean btn-sm-lg rounded-lg col-8 col-md-7 mt-2 mt-sm-4 py-2" onClick={confirmInvite}>
                            Continue
                            <span><i className="fa fa-long-arrow-alt-right ml-2" style={{ transform: 'scale(0.8,0.8)' }}></i></span>
                          </button>

                          <div className="row">
                            <div className="col-sm-12 text-center mt-3">
                              <a className="loginLink" onClick={cancelComfirm}>Cancel</a>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <p id="loginMessage" className="text-center text-danger"></p>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
            <div id="loginScr-loading">
            </div>
          </div>
        </div>
      </>
    );
  }
  else {
    window.location.replace("/overview");
  }
}

export default App;
