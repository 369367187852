import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

function Menu(props) {

	useEffect(() => {
		if (props.active >= 0) {
			document.getElementsByClassName("sidebar-item")[props.active].classList.add('active');
		}
	}, []);

	return (
		<>
			<nav id="sidebar" className="sidebar">
				<div className="sidebar-content js-simplebar">
					<a className="sidebar-brand mx-auto mt-4" href="/">
						<img src="../img/logo/SnapX_logo.svg" className="navbar-brand-img mt-1" alt="Dixell" />
					</a>

					<ul className="sidebar-nav">

						<li className="sidebar-header mb-2 text-muted"><strong>Devices</strong></li>

						<li className="sidebar-item mx-2 rounded">
							<Link className="sidebar-link px-3" to={{pathname:"/overview"}}>
								{/* <i className="align-middle" data-feather="layout"></i> */}
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="3" y1="9" x2="21" y2="9"></line><line x1="9" y1="21" x2="9" y2="9"></line></svg>
								<span className="align-middle">Overview</span>
							</Link>
						</li>

						<li className="sidebar-item rounded mx-2">
							<Link className="sidebar-link px-3" to={{pathname:"/list"}}>
								{/* <i className="align-middle" data-feather="list"></i> */}
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
								<span className="align-middle">List</span>
							</Link>
						</li>

						<li className="sidebar-item rounded mx-2">
							<a className="sidebar-link px-3" href="/chart">
								{/* <i className="align-middle" data-feather="bar-chart-2"></i> */}
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle"><line x1="18" y1="20" x2="18" y2="10"></line><line x1="12" y1="20" x2="12" y2="4"></line><line x1="6" y1="20" x2="6" y2="14"></line></svg>
								<span className="align-middle">Charts</span>
							</a>
						</li>

						<li className="sidebar-item rounded mx-2">
							<Link className="sidebar-link px-3" to={{pathname:"/map"}}>
								{/* <i className="align-middle" data-feather="map"></i> */}
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle"><polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon><line x1="8" y1="2" x2="8" y2="18"></line><line x1="16" y1="6" x2="16" y2="22"></line></svg>
								<span className="align-middle">Maps</span>
							</Link>
						</li>

						<li className="sidebar-header mb-2 text-muted"><strong>System</strong></li>

						<li className="sidebar-item rounded mx-2">
							<Link className="sidebar-link px-3" to={{pathname:"/alarm"}}>
								{/* <i className="align-middle" data-feather="bell"></i> */}
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
								<span className="align-middle">Alarm history</span>
							</Link>
						</li>
					</ul>

					{/* <div className="sidebar-cta">
						<div className="sidebar-cta-content">
							<strong className="d-inline-block mb-2">Renew your subscription ?</strong>
							<div className="mb-3 text-sm">
								Your subscription is running out. Continue your privilage ?
						</div>
							<a href="https://adminkit.io/pricing" target="_blank"
								className="btn btn-outline-custom btn-block">Subscribe</a>
						</div>
					</div> */}

					{/* <small className="text-center mb-3 text-muted">beta-test v0.0.5</small> */}
				</div>
			</nav>
		</>
	)
}

export default Menu
