import React from 'react';
import PropTypes from 'prop-types';

import MarkerStyled from './MarkerStyled';
import MarkerInGroupStyled from './MarkerInGroupStyled';
import XR from '../XR';
const $ = require('jquery');


class Marker extends React.PureComponent {

  constructor(props) {
    super(props);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.link = this.link.bind(this);
  }

  show = (e) => {
    $('.mapPin-wrapper').attr('style', 'display:none');
    e.target.offsetParent.children[0].style.display = "block";
  }

  link = (id) => {
    window.location.href = "/list/" + id
  }

  hide = (e) => {
    e.target.parentElement.parentElement.parentElement.style.display = "none";
  }

  static defaultProps = {
    inGroup: false,
  };

  render() {
    return (
      <div>
        {this.props.inGroup
          ? <MarkerInGroupStyled>
            <XR scale="0.6" color='#333' />
          </MarkerInGroupStyled>
          : <MarkerStyled style={{ border: this.props.alarm == 1 ? '4.5px solid #d31229' : this.props.status == 1 ? '4.5px solid #11d987' : '2.5px solid #ccc', background: this.props.alarm == 1 ? '#e94d5f' : '' }}>
            <div onClick={() => { this.props.info({ id: this.props.id, name: this.props.name, model: this.props.model, lat: this.props.lat, lng: this.props.lng, status: this.props.status, display: 'flex', alarm: this.props.alarm, adr1: this.props.adr1, adr2: this.props.adr2, adr3: this.props.adr3 }) }}>
              <div style={{ pointerEvents: "none" }}>
              <XR scale="0.6" color={this.props.alarm == 1 ? "#fff" : '#333'} />
            </div>
            </div>
          </MarkerStyled>
        }
      </div>
    );
  }
}

Marker.propTypes = {
  inGroup: PropTypes.bool,
};

export default Marker;
