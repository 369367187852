import React from 'react';

import MarkerStyled from './Marker/MarkerStyled';
import XR from './XR';

function MapInfo(props) {


  return (
    <>
      <MarkerStyled style={{ border: props.alarm == 1 ? '4.5px solid #d31229' : props.status == 1 ? '4.5px solid #11d987' : '2.5px solid #ccc', background: props.alarm == 1 ? '#e94d5f' : '', display: props.display ? props.display : "none" }}>
        <div className="mapPin-wrapper">
          <div className="mapPin">
            <h1><span className="mapHeader-link" onClick={() => { window.location.href = "/list/" + props.id }}>{props.name}</span><small className="text-muted ml-1">(XR77CX)</small></h1>
            <a className="close" onClick={() => { props.hide('none') }}>
              <i className="fa fa-times" ></i>
            </a>
            <p className="map-adr">{props.adr1}</p>
            <p className="map-adr">{props.adr2}</p>
            <p className="map-adr">{props.adr3}</p>

            {/* <p>Status :<span className={props.status == 1 ? "badge badge-success mx-1" : "badge badge-secondary mx-1"}>{props.status == 1 ? 'Active' : 'Inactive'}</span></p> */}
            <a href={"https://www.google.com/maps/search/?api=1&query=" + props.lat + "," + props.lng} target="_blank">View on Google Maps</a>
          </div>
        </div>
        <div>
          <div style={{ pointerEvents: "none" }}>
            <XR scale="0.6" color={props.alarm == 1 ? "#fff" : '#333'} />
          </div>
        </div>
      </MarkerStyled>
    </>
  )

}

export default MapInfo;