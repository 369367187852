
const $ = require('jquery');


export const sortTable = (n, e) => {
    let table, head, arrow, rows, switching, j, i, x, y, shouldSwitch, dir, switchcount = 0;
    if (e.type && e.type == 'click') {
        arrow = e.target.lastChild.children[0];
        head = document.getElementById(e.target.offsetParent.id);
        table = document.getElementById(e.target.offsetParent.id.replace("head", "body"));
    } else {
        arrow = $('#tablehead_' + e)[0].children[0].children[0].children[1].lastChild.children[0];
        head = $('#tablehead_' + e)[0];
        table = $('#tablebody_' + e)[0];
    }


    for (j = 0; j < head.children[0].children[0].children.length; j++) {
        head.children[0].children[0].children[j].lastElementChild.children[0].classList.remove('ml-3');
        head.children[0].children[0].children[j].lastElementChild.children[0].setAttribute("style", 'visibility : hidden');
    }

    // if (arrow.className.match(/fa/g) && arrow.className.match(/fa-arrow-down/g)) {
    //     arrow.removeAttribute('style');
    //     arrow.classList.add('ml-3');
    //     arrow.classList.remove('fa-arrow-down');
    //     arrow.classList.add('fa-arrow-up');
    // } else if (arrow.className.match(/fa/g) && arrow.className.match(/fa-arrow-up/g)) {
    //     arrow.removeAttribute('style');
    //     arrow.classList.add('ml-3');
    //     arrow.classList.remove('fa-arrow-up');
    //     arrow.classList.add('fa-arrow-down');
    // } else {
    //     arrow.removeAttribute('style');
    //     arrow.classList.add('ml-3');
    //     arrow.classList.add('fa');
    //     arrow.classList.add('fa-arrow-down');
    // }
    

    switching = true;
    dir = "asc";
    while (switching) {
        switching = false;
        rows = table.rows;
        for (i = 0; i < (rows.length - 1); i++) {
            shouldSwitch = false;
            x = rows[i].getElementsByTagName("TD")[n];
            y = rows[i + 1].getElementsByTagName("TD")[n];
            if (e.target && e.target.className.length > 0 && e.target.className.includes("tableRowResponsiveCustom-2") == 5) {
                if (dir == "asc") {
                    if (x.dataset.ts > y.dataset.ts) {
                        shouldSwitch = true;
                        break;
                    }

                    arrow.removeAttribute('style');
                    // arrow.className.toLowerCase().includes("fa") ? arrow.classList.add('fa') : null;
                    arrow.classList.add('ml-3');
                    arrow.classList.remove('fa-arrow-up');
                    arrow.classList.add('fa-arrow-down');
                } else if (dir == "desc") {
                    if (x.dataset.ts < y.dataset.ts) {
                        shouldSwitch = true;
                        break;
                    }

                    arrow.removeAttribute('style');

                    arrow.classList.add('fa');
                    // arrow.className.toLowerCase().includes("fa") ? arrow.classList.add('fa') : null;
                    arrow.classList.add('ml-3');
                    arrow.classList.remove('fa-arrow-down');
                    arrow.classList.add('fa-arrow-up');
                }
            } else {
                if (dir == "asc") {
                    if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                        shouldSwitch = true;
                        break;
                    }

                    arrow.removeAttribute('style');

                    arrow.classList.add('fa');
                    // arrow.className.toLowerCase().includes("fa") ? arrow.classList.add('fa') : null;
                    arrow.classList.add('ml-3');
                    arrow.classList.remove('fa-arrow-up');
                    arrow.classList.add('fa-arrow-down');
                } else if (dir == "desc") {
                    if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                        shouldSwitch = true;
                        break;
                    }

                    arrow.removeAttribute('style');
                    arrow.classList.add('ml-3');

                    arrow.classList.add('fa');
                    // arrow.className.toLowerCase().includes("fa") ? arrow.classList.add('fa') : null;
                    arrow.classList.remove('fa-arrow-down');
                    arrow.classList.add('fa-arrow-up');
                }
            }
        }
        if (shouldSwitch) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            switchcount++;
        } else {
            if (switchcount == 0 && dir == "asc") {
                dir = "desc";
                switching = true;
            }
        }
    }
}