import React, { useState, useEffect, useCallback } from 'react'
import fire from '../../firebase'
import { tsFormat } from '../../../customLibs/timeStampFormat';
import { sortTable } from "../../../customLibs/sortTable";
import { useHistory, Link } from 'react-router-dom';

import 'firebase'

const $ = require('jquery');
let pageSize = 20;
let page = 1;
let order = 0;

async function getDeviceList(credentials) {
    return fetch('https://dixell.snapx.cloud/api/deviceListByUserSQL', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(response => response.json())
}

async function setFavDevice(credentials) {
    return fetch('https://dixell.snapx.cloud/api/updateFavDevice', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(response => response.json())
}

async function getDeviceInfo(credentials) {
    return fetch('https://dixell.snapx.cloud/api/GetInfo', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(response => response.json())
}

async function setDeviceInfo(credentials) {
    return fetch('https://dixell.snapx.cloud/api/SetInfo', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(response => response.json())
}

function Content() {

    const [TimeOut, setTimeOut] = useState(false);
    const [Response, setResponse] = useState({});
    const [DeviceList, setDeviceList] = useState([]);
    const [PageConfig, setPageConfig] = useState({});
    const [NameEdit, setNameEdit] = useState({ id: '' });
    const history = useHistory();
    //var devTest = ['33251760-8d24-11eb-931a-7516415b8f59','423cec60-6db1-11eb-b7e8-53acf870d7cf','504529b0-9077-11eb-931a-7516415b8f59']

    useEffect(async () => {
        changePage(0)
    }, [])


    const changePage = async (n) => {
        page = page + n
        let resList = await getDeviceList(
            {
                user: localStorage.getItem("user"),
                size: pageSize,
                page: page - 1,
                order: order //0:fav,1:new,2:old
            }
        )

        if ((Object.keys(resList).length) > 0 && resList.deviceID.length > 0) {
            setResponse({ message: "Success" })
        } else {
            setResponse({ message: "No Data" })
        }

        for (let [i, d] of resList.deviceID.entries()) {
            resList.deviceID[i].attr = JSON.parse(d.attr)
            resList.deviceID[i].lib = JSON.parse(d.lib)
        }
        setDeviceList(resList.deviceID)
        delete resList.deviceID
        setPageConfig(resList)
    }

    const setFav = async (e, id, fav) => {
        if (fav == "Y") {
            fav = "N"
            e.target.setAttribute('fill', 'none')
            e.target.setAttribute('stroke', '#454752')
        } else {
            fav = "Y"
            e.target.setAttribute('fill', '#348cbf')
            e.target.setAttribute('stroke', '#348cbf')
        }
        let resFav = await setFavDevice(
            {
                id: id,
                fav: fav //Y,N
            }
        )

        if (resFav.status) {
            let Arr = DeviceList;
            for (let i = 0; i < Arr.length; i++) {
                if (Arr[i].id == id) {
                    Arr[i].fav = fav
                }
            }
            setDeviceList(Arr)
        } else {
            setDeviceList(DeviceList)
        }
    }

    const Request = (e, req, num) => {
        for (let i = 0; i < e.target.parentElement.children.length; i++) {
            e.target.parentElement.children[i].classList.remove('active')
        }
        e.target.classList.add('active')
        if (req == 'size') {
            pageSize = num
            page = 1
            changePage(0)
        } else if (req == 'sort') {
            order = num
            changePage(0)
        }
    }

    const openScreen = useCallback((id) => {
        history.push('/list/' + id)
    }, [history]);

    const changeName = (e, id) => {
        e.preventDefault();
        setNameEdit({ id: id })
        if (e.target.tagName === "TD") {
            openScreen(id)
        } else if (e.target.tagName === "BUTTON") {
            saveNameChange()
        } else if (e.target.tagName === "A") {
            cancelNameChange()
        }
    }

    const saveNameChange = async () => {
        let deviceInfo = Object.assign({}, await getDeviceInfo({ id: NameEdit.id }))
        let newName = $('.nameEditor')[0].value
        deviceInfo.label = newName
        let newInfo = await setDeviceInfo(deviceInfo)
        if (newInfo.status === true) {
            let Arr = DeviceList;
            for (let d of Arr) {
                if (d.id === NameEdit.id) {
                    d.label = newName
                }
            }
            setDeviceList(Arr)
        } else {
            setDeviceList(DeviceList)
        }
        setNameEdit({ id: '' })
    }

    const cancelNameChange = () => {
        setNameEdit({ id: '' })
    }
    
    const divData = DeviceList.map((val, index) => {
        return (
            <tr key={index} style={{ cursor: "pointer" }}>
                <td style={{ width: "10px" }} scope="row" className="align-middle py-3 pl-3 pr-0 my-auto border-bottom-dark">
                    {/* <i className="fa fa-star" onClick={(e) => (Selection(e))}></i> */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={val.fav == "Y" ? '#348cbf' : 'none'} stroke={val.fav == "Y" ? '#348cbf' : '#454752'} strokeLinecap="round" strokeLinejoin="round" className="feather mr-2" style={{ strokeWidth: '2.5 ', transform: 'scale(0.8,0.8)' }} onClick={(e) => { setFav(e, val.id, val.fav) }}><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" style={{ pointerEvents: 'none' }}></polygon></svg>
                </td>
                {/* <td style={{ width: "calc(40% - 10px)" }} scope="row" className="align-middle py-3 my-auto border-bottom-dark tableRowResponsiveCustom-1" onClick={() => { openScreen(val.id) }} > */}
                <td style={{ width: "calc(40% - 10px)" }} scope="row" className="align-middle py-3 my-auto border-bottom-dark tableRowResponsiveCustom-1" onClick={(e) => { !e.target.className.includes('editName') && NameEdit.id != val.id ? openScreen(val.id) : changeName(e, val.id) }} >
                    {
                        NameEdit.id === val.id ?
                            <form className="w-auto">
                                <input className="form-control w-auto nameEditor d-inline" type="text" defaultValue={val.label != "" ? val.label : val.attr.alias} onKeyUp={(e)=>{if(e.keyCode===27){cancelNameChange()}}}/>
                                <button className="btn btn-sm btn-custom rounded-lg ml-4 savename" type='submit'>Save</button>
                                <a className="text-sm cancel-text ml-3">Cancel</a>
                            </form>
                            :
                            <>
                                <span>{val.label != "" ? val.label : val.attr.alias}</span>
                                <span className="editName rounded p-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                                </span>
                            </>
                    }
                </td>
                <td style={{ width: "25%" }} className="align-middle py-3 my-auto border-bottom-dark" onClick={() => { openScreen(val.id) }} >
                    {val.lib.Name}
                </td>
                <td style={{ width: "15%", color: val.connect ? "#2cc0b6" : "#777" }} className="align-middle py-3 my-auto border-bottom-dark" onClick={() => { openScreen(val.id) }}>{val.connect ? "Active" : "Inactive"}</td>
                <td style={{ width: "20%" }} className="align-middle py-3 my-auto border-bottom-dark tableRowResponsiveCustom-2" data-ts={val.attr.lastConnectTime} onClick={() => { openScreen(val.id) }} >{tsFormat(val.attr.lastConnectTime, 'long')}</td>
            </tr >
        )
    });

    const divDataSmall = DeviceList.map((val, index) => {
        return (<div className="col-12" key={index}>
            <div className={index == DeviceList.length - 1 ? "mx-0 py-3 d-flex" : "mx-0 border-bottom border-bottom-dark py-3 d-flex"}>
                <div className="flex-shrink-1 text-center p-0 mx-1" style={{ width: '25px' }}>
                    <div className="my-auto mx-auto text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={val.fav == "Y" ? '#348cbf' : 'none'} stroke={val.fav == "Y" ? '#348cbf' : '#454752'} strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ strokeWidth: '2.5 ', transform: 'scale(0.8,0.8)' }} onClick={(e) => { setFav(e, val.id, val.fav) }}><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" style={{ pointerEvents: 'none' }}></polygon></svg>
                    </div>

                </div>
                <div className="w-100 mx-1" onClick={() => { openScreen(val.id) }}>
                    <div className="d-inline-block">
                        <p className="mb-0">
                            <span className="text-custom-table mr-1 d-flex d-sm-inline-flex">{val.label != "" ? val.label : val.attr.alias}</span>
                            <small className="text-custom mt-1 mt-sm-0 d-flex d-sm-inline-flex">({val.lib.Name})</small>
                        </p>
                    </div>
                    <p className="mb-0">
                        <small className="text-muted">Status : </small>
                        <small className="ml-1" style={{ color: val.connect ? "#2cc0b6" : "" }}>{val.connect ? "Active" : "Inactive"}</small>
                    </p>
                    <p className="mb-0">
                        <small className="text-muted">Connected : </small>
                        <small className="ml-1 text-custom">{tsFormat(val.attr.lastConnectTime, 'auto')}</small>
                    </p>
                </div>
                <div className="flex-shrink-1 text-center p-0 mx-1" style={{ width: '35px' }} onClick={() => { openScreen(val.id) }}>
                    <div className="row h-100">
                        <div className="my-auto mx-auto text-center text-muted">
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ marginBottom: "2.5px", marginLeft: "1px", marginRight: "-1px", strokeWidth: "2", pointerEvents: 'none' }}><polyline points="9 18 15 12 9 6"></polyline></svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    });


    if (Response.message && Response.message == "Success" && Object.keys(DeviceList).length > 0) {
        return (
            <>
                <div className="container-fluid p-0">
                    <div className="row title mb-2 mb-md-4 pb-3 mx-0 border-bottom">
                        <div className="col-6 px-0">
                            <h3>Device List</h3>
                        </div>
                        <div className="col-6 px-0 text-right">
                            <div className="float-right mt-0 mt-sm-1 mr-2">
                                {/* <div className="mr-2"> */}
                                {/* <form className="form-inline mr-2 d-none d-md-inline">
                                <input className="form-control bg-transparent search-custom border-0 rounded-pill shadow-none" type="text" placeholder="Search..." style={{ marginTop: '-3.5px', marginBottom: '-1.5px', paddingRight: '35px' }}>
                                </input>
                                <button type="submit" className="btn btn-search-custom border-none bg-transparent rounded-pill">
                                    <i className="fa fa-search"></i>
                                </button>
                            </form> */}
                                <div className="d-inline-block">
                                    {/* <div className="d-none d-sm-inline-block sort-hover rounded px-2 py-1 ml-1 searchHideActive" onClick={()=>{$('.searchHideActive').addClass('active')}}>
                                    <i className="fa fa-search mr-0 text-muted" style={{ transform: 'scale(0.9,0.9)' }}></i>
                                </div>
                                <div className="d-inline-block showOnSearch">
                                    <form className="form-inline mr-2 d-inline">
                                        <input className="form-control bg-transparent search-custom border-0 rounded-pill shadow-none pl-3" type="text" placeholder="Search..." style={{ marginTop: '-3.5px', marginBottom: '-1.5px', paddingRight: '35px' }}>
                                        </input>
                                        <button type="submit" className="btn btn-search-custom border-none bg-transparent rounded-pill">
                                            <i className="fa fa-search"></i>
                                        </button>
                                        <a className="btn border-none bg-transparent rounded-pill ml-2 close-custom text-body" onClick={()=>{$('.searchHideActive').removeClass('active')}}>
                                            <i className="fa fa-times"></i>
                                        </a>
                                    </form>
                                </div> */}
                                    <div className="d-inline-block">
                                        <div className="d-inline-block">
                                            <div className="sort-hover rounded px-2 py-1 ml-1" id="sortMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Sort options" >
                                                <i className="fa fa-sort mr-0 d-inline-block text-muted" style={{ transform: 'scale(0.8,0.8)' }}></i>
                                            </div>
                                            <div className="dropdown-menu dropdown-menu-right mx-2 py-1" aria-labelledby="sortMenu">
                                                <a className="dropdown-item px-3 py-2 active" style={{ fontSize: '80%' }} onClick={(e) => { Request(e, 'sort', 0) }}>
                                                    <span className="ml-1 align-middle">Favorite (Default)</span>
                                                </a>
                                                <a className="dropdown-item px-3 py-2" style={{ fontSize: '80%' }} onClick={(e) => { Request(e, 'sort', 1) }}>
                                                    <span className="ml-1 align-middle">Newest</span>
                                                </a>
                                                <a className="dropdown-item px-3 py-2" style={{ fontSize: '80%' }} onClick={(e) => { Request(e, 'sort', 2) }}>
                                                    <span className="ml-1 align-middle">Oldest</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="d-none d-sm-inline-block">
                                            <div className="sort-hover rounded px-2 py-1 ml-1" id="sizeMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Content size" >
                                                {/* <i className="fa fa-filter mr-0 mr-md-2 text-muted" style={{ transform: 'scale(0.6,0.6)'}}></i> */}
                                                <span>{((pageSize * page) - pageSize) + 1}</span>
                                                <span className={DeviceList.length > 0 ? "mx-1" : "d-none"}>-</span>
                                                <span className={DeviceList.length > 0 ? "" : "d-none"}>{((pageSize * page) - pageSize) + DeviceList.length}</span>
                                                <small className="mx-2">from</small>
                                                <span className="">{PageConfig.totalElements}</span>
                                            </div>
                                            <div className="dropdown-menu dropdown-menu-right mx-2 py-1" aria-labelledby="sizeMenu">
                                                <a className="dropdown-item px-3 py-2 active" style={{ fontSize: '80%' }} onClick={(e) => { Request(e, 'size', 20) }}>
                                                    <span className="ml-1 align-middle">20 (Default)</span>
                                                </a>
                                                <a className="dropdown-item px-3 py-2" style={{ fontSize: '80%' }} onClick={(e) => { Request(e, 'size', 50) }}>
                                                    <span className="ml-1 align-middle">50</span>
                                                </a>
                                                <a className="dropdown-item px-3 py-2" style={{ fontSize: '80%' }} onClick={(e) => { Request(e, 'size', 100) }}>
                                                    <span className="ml-1 align-middle">100</span>
                                                </a>
                                            </div>
                                        </div>
                                        <span className="ml-0 ml-md-2">
                                            <a className={page > 1 ? "text-custom-light mx-2 p-1 rounded-circle page-arrow-active" : "text-body mx-2 p-1 rounded-circle"}
                                                style={page > 1 ? { pointerEvents: 'auto', cursor: 'pointer' } : { pointerEvents: 'none', cursor: 'default' }}
                                                onClick={() => { changePage(-1) }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ marginBottom: "2.5px", marginLeft: "-1px", marginRight: "1px", strokeWidth: page == 1 ? "2" : "3", pointerEvents: 'none' }}><polyline points="15 18 9 12 15 6"></polyline></svg>
                                            </a>
                                            <a className={PageConfig.hasNext ? "text-custom-light mx-2 p-1 rounded-circle page-arrow-active" : "text-body mx-2 p-1 rounded-circle"}
                                                style={PageConfig.hasNext ? { pointerEvents: 'auto', cursor: 'pointer' } : { pointerEvents: 'none', cursor: 'default' }}
                                                onClick={() => { changePage(1) }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ marginBottom: "2.5px", marginLeft: "1px", marginRight: "-1px", strokeWidth: PageConfig.hasNext ? "3" : "2", pointerEvents: 'none' }}><polyline points="9 18 15 12 9 6"></polyline></svg>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  d-none d-sm-flex">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table table-hover mb-0" id="listTable_head">
                                    <thead>
                                        <tr style={{ cursor: "pointer" }}>
                                            <th style={{ width: "10px", fontWeight: "normal" }} className="align-middle pb-3 pl-3 pr-0 my-auto text-muted">
                                                {/* <i className="fa fa-check custom-checkBox" style={{ verticalAlign: 'text-top' }} onClick={(e) => (Selection('all'))}></i> */}
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#585f66" strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ verticalAlign: 'text-top', strokeWidth: '2.5 ', transform: 'scale(0.8,0.8)' }}><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                                                <small style={{ pointerEvents: "none" }} ><i></i></small></th>
                                            <th style={{ width: "calc(40% - 10px)", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted tableHeader tableRowResponsiveCustom-1" onClick={(e) => { sortTable(1, e) }}><span className="ml-2">Name</span><small style={{ pointerEvents: "none" }} ><i></i></small></th>
                                            <th style={{ width: "25%", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted tableHeader" onClick={(e) => { sortTable(2, e) }}>Model<small style={{ pointerEvents: "none" }} ><i></i></small></th>
                                            <th style={{ width: "15%", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted tableHeader" onClick={(e) => { sortTable(3, e) }}>Status<small style={{ pointerEvents: "none" }} ><i></i></small></th>
                                            <th style={{ width: "20%", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted tableHeader tableRowResponsiveCustom-2" onClick={(e) => { sortTable(4, e) }}>Connected<small style={{ pointerEvents: "none" }} ><i></i></small></th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className="table-responsive overflow-custom" style={{ height: "calc(100vh - 280px)", width: "calc(100% + 7.5px)" }}>
                                <table className="table table-hover mb-0" id="listTable_body">
                                    <tbody>
                                        {divData}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="row d-flex d-sm-none">
                        {divDataSmall}
                        {/* <div className="col-12">
                            <div className="row mx-0 border-bottom border-bottom-dark py-3" style={{ pointerEvents: 'none' }}>
                                <div className="col-auto text-center p-0 mx-1" style={{ width: '25px' }}>
                                    <div className="my-auto mx-auto text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill='#348cbf' stroke='#348cbf' strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ strokeWidth: '2.5 ', transform: 'scale(0.8,0.8)' }} ><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" style={{ pointerEvents: 'none' }}></polygon></svg>
                                    </div>

                                </div>
                                <div className="flex-fill mr-1">
                                    <div className="d-inline-block">
                                        <p className="mb-0">
                                            <span className="text-custom-table">SnapX Test</span>
                                            <small className="ml-1 text-custom">(XR77CX)</small>
                                        </p>
                                    </div>
                                    <p className="mb-0">
                                        <small className="text-muted">Status : </small>
                                        <small className="ml-1" style={{ color: '#2cc0b6' }}>Active</small>
                                    </p>
                                    <p className="mb-0">
                                        <small className="text-muted">Connected : </small>
                                        <small className="ml-1 text-custom">{tsFormat(Date.now(), 'long')}</small>
                                    </p>
                                </div>
                                <div className="col-auto text-center p-0 mx-1" style={{ width: '35px' }}>
                                    <div className="row h-100">
                                        <div className="my-auto mx-auto text-center text-muted">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ marginBottom: "2.5px", marginLeft: "1px", marginRight: "-1px", strokeWidth: "2", pointerEvents: 'none' }}><polyline points="9 18 15 12 9 6"></polyline></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                </div>
            </>
        )
    } else if (Response.message && Response.message == "No Data") {
        return (
            <>
                <div className="container-fluid p-0">
                    <div className="row title mb-4 pb-3 mx-0 border-bottom">
                        <div className="col-12 px-0">
                            <h3>Device List</h3>
                        </div>
                    </div>
                    <div className="row" style={{ height: 'calc(100vh - 250px)' }}>
                        <div className="my-auto mx-auto text-center">
                            <h2 className="text-muted">No devices found</h2>
                        </div>
                    </div>
                </div>
            </>
        );
    } else if (TimeOut == false) {
        setTimeout(function () {
            setTimeOut(true)
        }, 10000)
        return (
            <>
                <div className="container-fluid p-0 loading">
                    <div className="row" style={{ height: 'calc(100vh - 150px)' }}>
                    </div>
                </div>
            </>
        );
    } else if (TimeOut) {
        return (
            <>
                <div className="container-fluid p-0">
                    <div className="row" style={{ height: 'calc(100vh - 150px)' }}>
                        <div className="my-auto mx-auto text-center">
                            <h2 className="text-muted">Error</h2>
                            <h1 className="text-muted" style={{ fontSize: '4.5rem' }}>524</h1>
                            <h3 className="text-muted">Timeout Occurred</h3>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Content
