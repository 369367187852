import React, { useState, useEffect } from 'react'
import fire from '../../firebase'
import 'firebase'
const $ = require('jquery');


async function ChangeName(credentials) {
	return fetch('https://dixell.snapx.cloud/api/changeName', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(credentials)
	})
		.then(data => data.json())
}

async function ChangePass(credentials) {
	return fetch('https://dixell.snapx.cloud/api/changePassword', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(credentials)
	})
		.then(data => data.json())
}


function Content() {
	const [User, setUser] = useState({
		user: localStorage.user,
		name: localStorage.name,
		code: localStorage.code || "",
		perm: localStorage.perm,
		owner: localStorage.owner || null
	});

	const [TimeOut, setTimeOut] = useState(false);
	const [Response, setResponse] = useState({});
	const [nickname, setnickname] = useState("")
	const [password, setpassword] = useState("")

	useEffect(() => {
		if ((Object.keys(User).length) > 0) {
			setResponse({ message: "Success" })
		} else {
			setResponse({ message: "No Data" })
		}

		if (localStorage.name != "") {
			// $('#nickname')[0].value = localStorage.name
		} else {
			// $('#nickname')[0].value = localStorage.user
		}
	}, [User])

	const activeSubmenu = (i) => {
		$('.submenu .sidebar-nav .sidebar-item').removeClass('active')
		// e.target.parentElement.classList.add('active')
		$('.submenu:not(.collapse) .sidebar-nav li')[i].classList.add('active')
		$('.submenu.collapse .sidebar-nav li')[i].classList.add('active')
	}

	const activeSubnav = (e) => {
		$('.subnav .nav .nav-item').removeClass('active')
		e.target.parentElement.classList.add('active')
	}

	const toggleActive = (e) => {
		const cls = e.target.classList.value;
		if (cls.toLowerCase().includes('active')) {
			e.target.classList.remove('active')
		} else {
			e.target.classList.add('active')
		}
	}

	const CopyCode = (e) => {
		let code = e.target.previousElementSibling;
		code.select();
		code.setSelectionRange(0, 99999);
		document.execCommand("copy");
		if (document.execCommand("copy")) {
			e.target.classList.remove('btn-hover-custom-2');
			e.target.classList.add('btn-custom-success');
			e.target.children[0].classList.add('fa');
			e.target.children[0].classList.add('px-2');
			e.target.children[0].classList.add('fa-check');
			e.target.children[0].innerText = ""

			setTimeout(() => {
				e.target.classList.remove('btn-custom-success');
				e.target.classList.add('btn-hover-custom-2');
				e.target.children[0].classList.remove('px-2');
				e.target.children[0].classList.remove('fa');
				e.target.children[0].classList.remove('fa-check');
				e.target.children[0].innerText = "Copy"
			}, 1000)
		}
	}

	const NewName = (e) => {
		const { name, value } = e.target;
		if (value != User.name && value.length > 0) {
			$('#nameInputDes').removeClass('d-none');
			$('#nameInputBtnGroup').removeClass('d-none');
		} else {
			$('#nameInputDes').addClass('d-none');
			$('#nameInputBtnGroup').addClass('d-none');
		}
		setnickname(value)
	}

	const SetNewName = async (e) => {
		if (e.key == "Enter" || e.type == "click") {
			$('#nameInputBtnGroup button span').toggleClass('d-none')
			$('#nameInputBtnGroup button i').toggleClass('d-none')
			if (nickname.length > 0 && nickname.length <= 20) {
				await ChangeName({
					username: User.user,
					name: nickname,
				}).then(async (response) => {
					if (response.status) {
						localStorage.setItem("name", nickname);
						User.name = nickname;
						$('#topNavName')[0].innerText = nickname
						$('#topNavThumbNail')[0].innerText = nickname.substring(0, 2).toUpperCase();
						$('#nameInputDes').addClass('d-none');
						$('#nameInputBtnGroup').addClass('d-none');
						$('#nameInputBtnGroup button span').toggleClass('d-none')
						$('#nameInputBtnGroup button i').toggleClass('d-none')
						$('#nameChangeSuccess').removeClass('d-none');
						setTimeout(() => {
							$('#nameChangeSuccess').addClass('d-none');
						}, 1000)
					} else {
						console.error(response)
					}
				})
			} else {
				$('#nameInputDes').removeClass('text-muted');
				$('#nameInputDes').addClass('text-danger');
			}
		}
	}

	const CancelChangeName = (e) => {
		$('#nameInput')[0].value = User.name
		$('#nameInputDes').addClass('d-none');
		$('#nameInputBtnGroup').addClass('d-none');
	}

	const NewPass = (e) => {
		let currentPassLength = $('#currentPassInput')[0].value.length,
			newPassLength = $('#newPassInput')[0].value.length,
			newPassReLength = $('#newPassInputRetype')[0].value.length,
			newPass = $('#newPassInput')[0].value;

		if (currentPassLength > 0 && newPassLength > 0 && newPassLength <= 20 && newPassReLength > 0) {
			$('#passInputBtnGroup button')[0].removeAttribute('disabled')
			$('#passInputBtnGroup button')[0].style.pointerEvents = 'auto';

		} else {
			$('#passInputBtnGroup button')[0].setAttribute('disabled', '')
			$('#passInputBtnGroup button')[0].style.pointerEvents = 'none';
		}

		if (e.type != 'click') {
			if (e.target.id != "currentPassInput" && newPassLength < 1 || newPassLength > 20) {
				setpassword("");
				$('#passInputDes').removeClass('text-muted');
				$('#passInputDes').addClass('text-danger');
			} else {
				setpassword(newPass);
				$('#passInputDes').removeClass('text-danger');
				$('#passInputDes').addClass('text-muted');
			}
		}
		$('#passInputDes small')[0].innerText = "Password must be between 1-20 characters and no space."
	}

	const SetNewPass = async (e) => {
		let currentPass = $('#currentPassInput')[0].value,
			newPass = $('#newPassInput')[0].value,
			newPassRe = $('#newPassInputRetype')[0].value;

		if (password == newPassRe) {
			$('#passInputDes small')[0].innerText = ""
			$('#passInputBtnGroup button span').toggleClass('d-none')
			$('#passInputBtnGroup button i').toggleClass('d-none')

			await ChangePass({
				username: User.user,
				old: currentPass,
				new: password,
			}).then(async (response) => {
				if (response.status) {
					$('#passInputBtnGroup').addClass('d-none')
					$('#passChangeSuccess').removeClass('d-none')
					setTimeout(() => {
						window.location.replace('/');
					}, 2000)
				} else {
					$('#passInputDes').removeClass('text-muted');
					$('#passInputDes').addClass('text-danger');
					$('#passInputDes small')[0].innerText = "Current password doesn't match !!"
				}
			})

		} else {
			$('#passInputDes').removeClass('text-muted');
			$('#passInputDes').addClass('text-danger');
			$('#passInputDes small')[0].innerText = "New password and Retype doesn't match !!"
		}
	}

	const ChangePassFormVisible = (event) => {
		$('#passInputBtnGroup button')[0].setAttribute('disabled', '')
		$('#passInputBtnGroup button')[0].style.pointerEvents = 'none';
		if (event == "show") {
			$('#changePassFormBtn').removeClass('d-flex')
			$('#changePassForm').removeClass('d-none');
			$('#changePassFormBtn').addClass('d-none')
			$('#changePassForm').addClass('d-flex');
		} else {
			$('#currentPassInput')[0].value = ""
			$('#newPassInput')[0].value = ""
			$('#newPassInputRetype')[0].value = ""
			$('#changePassFormBtn').removeClass('d-none')
			$('#changePassForm').removeClass('d-flex');
			$('#changePassFormBtn').addClass('d-flex')
			$('#changePassForm').addClass('d-none');
		}
	}

	if (Response.message && Response.message == "Success") {
		return (
			<>
				<div className="container-fluid p-0">
					<div className="row title mb-4 pb-3 mx-0 border-bottom">
						<div className="col-12 px-0">
							<h3 className="align-middle">Account</h3>
							{/* <h3 className="d-none d-md-inline-flex align-middle">Notification settings</h3>

						<div className="d-inline-flex d-md-none align-middle" data-toggle="collapse" href="#submenuCollapse" onClick={()=>{$('.fa-caret-up').toggleClass('fa-rotate-180');}}>
							<h3 className="d-inline-flex align-middle">Notification settings</h3>
							<div className="px-2 pt-1 ml-2">
								<i className="fa fa-caret-up d-inline-block header-menu-icon-rotate fa-rotate-180" style={{ pointerEvents: 'none' }} ></i>
							</div>
						</div>
						
						<div className="submenu collapse" id="submenuCollapse">
							<ul className="sidebar-nav">
								<li className="sidebar-item mx-2 active" onClick={()=>{activeSubmenu(0)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Notifications</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(1)}}>
									<a className="sidebar-link text-muted bg-transparent px-3" >
										<span className="align-middle" style={{ pointerEvents: 'none' }}>List</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(2)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Charts</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(3)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Maps</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(4)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Alarm history</span>
									</a>
								</li>
							</ul>
						</div> */}
						</div>
					</div>
					<div className="row">
						<div className="col-12 mx-auto d-flex">
							<div className="flex-grow-1 text-left px-0 px-xl-3">
								{
								User.perm == "Y" ?
									<div className="d-flex mx-0 mx-sm-3 py-2">
										<div className="text-center align-top" style={{ width: '28px' }}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle d-inline-block w-auto mt-1 text-custom-important" style={{ height: '20px', pointerEvents: 'none', strokeWidth: '2' }}>
												<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle>
											</svg>
										</div>
										<div className="flex-fill align-middle ml-2">
											<div className="flex-fill w-100">
												<p className="text-custom-important mb-1" style={{ fontSize: '125%' }}>
													<span className="align-middle">Your account</span>
												</p>
												<span className="text-muted d-none d-sm-flex">Manage your account & profile</span>
											</div>
											<div className="row mt-4">
												<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
													<p className="text-custom mb-0">Username</p>
												</div>
												<div className="col-12 col-md-8 col-xl-10">
													<div className="d-flex mb-4">
														<div className="w-100 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
															<p className="text-custom-important mb-1 mb-sm-0">{User.user}</p>
														</div>
													</div>
												</div>
											</div>
											<div className="row mt-2">
												<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
													<p className="text-custom mb-0">Profile name</p>
												</div>
												<div className="col-12 col-md-8 col-xl-10">
													<div className="d-flex mb-4">
														<div className="w-100 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
															<div className="ml-0 mb-0">
																<input className="form-control bg-transparent rounded-0 px-0 form-control-custom shadow-none"
																	defaultValue={User.name != "" ? User.name : User.user}
																	type="text"
																	autoComplete="off"
																	style={{ marginTop: "-3.5px", maxWidth: "175px" }}
																	onChange={NewName}
																	onKeyPress={SetNewName}
																	onFocus={() => { $('#nameInputDes').addClass('text-muted'); $('#nameInputDes').removeClass('text-danger') }}
																	id="nameInput"
																/>
															</div>
															<div className="mb-0 d-none text-muted"
																id="nameInputDes"
																style={{ maxWidth: "165px", animation: 'opafrom0 0.5s ease', lineHeight: '1' }}>
																<small >Profile name must be between 1-20 characters.</small>
															</div>
															<div className="d-none" id="nameInputBtnGroup">
																<button className="btn text-muted btn-custom px-2 align-middle mt-3 shadow-none" onClick={SetNewName}>
																	<span style={{ width: '95px' }}>Save changes</span>
																	<i className="fa fa-circle-notch fa-spin fa-fw d-none" style={{ width: '92px' }}></i>
																</button>
																<button className="btn mt-3 text-sm text-muted shadow-none btn-cancel ml-2 align-middle" onClick={CancelChangeName}>Cancel</button>
															</div>
															<div className="mt-3 d-none text-center" id="nameChangeSuccess" style={{ maxWidth: "175px", animation: 'opafrom0 0.5s ease' }}>
																<div className="btn text-muted btn-custom-success px-2 align-middle shadow-none w-100" style={{ pointerEvents: 'none' }}>
																	<i className="fa fa-check mr-2"></i>
																	<span>Success</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="row mt-2">
												<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
													<p className="text-custom mb-0">Password</p>
												</div>
												<div className="col-12 col-md-8 col-xl-10">
													<div className="d-flex mb-4" id="changePassFormBtn" style={{ animation: 'opafrom0 0.5s ease' }}>
														<div className="flex-shrink-1 mx-0 mx-md-2 mx-xl-0">
															<button className="btn btn-snapX-login-clean text-sm" style={{ letterSpacing: "0px" }} onClick={() => { ChangePassFormVisible('show') }}>Change password </button>
														</div>
													</div>
													<div className="d-none mb-4" id="changePassForm" style={{ animation: 'opaheightfrom0 0.5s ease' }}>
														<div className="w-100 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
															<div className="ml-0 mb-4">
																<div className="input-group">
																	<input className="form-control bg-transparent rounded-0 px-0 form-control-custom shadow-none"
																		type="password"
																		placeholder="Current password"
																		autoComplete="off"
																		style={{ marginTop: "-3.5px", maxWidth: "175px" }}
																		onChange={NewPass}
																		onClick={NewPass}
																		id="currentPassInput"
																	/>
																</div>
															</div>
															<div className="ml-0 mb-3">
																<div className="input-group">
																	<input className="form-control bg-transparent rounded-0 px-0 form-control-custom shadow-none"
																		type="password"
																		placeholder="New password"
																		autoComplete="off"
																		style={{ marginTop: "-3.5px", maxWidth: "175px" }}
																		onChange={NewPass}
																		onClick={NewPass}
																		id="newPassInput"
																	/>
																</div>
															</div>
															<div className="ml-0 mb-0">
																<div className="input-group">
																	<input className="form-control bg-transparent rounded-0 px-0 form-control-custom shadow-none"
																		type="password"
																		placeholder="Retype new password"
																		autoComplete="off"
																		style={{ marginTop: "-3.5px", maxWidth: "175px" }}
																		onChange={NewPass}
																		onClick={NewPass}
																		id="newPassInputRetype"
																	/>
																</div>
															</div>
															<div className="mt-2 text-muted"
																id="passInputDes"
																style={{ maxWidth: "175px", animation: 'opafrom0 0.5s ease', lineHeight: '1' }}>
																<small>Password must be between 1-20 characters and no space.</small>
															</div>
															<div className="mt-3" id="passInputBtnGroup">
																<button className="btn text-muted btn-custom px-2 align-middle shadow-none" type="submit" onClick={SetNewPass}>
																	<span style={{ width: '95px' }}>Save changes</span>
																	<i className="fa fa-circle-notch fa-spin fa-fw d-none" style={{ width: '92px' }}></i>
																</button>
																<button className="btn text-muted btn-cancel ml-2 align-middle shadow-none text-sm" onClick={() => { ChangePassFormVisible('hide') }}>Cancel</button>
															</div>
															<div className="mt-3 d-none text-center" id="passChangeSuccess" style={{ maxWidth: "175px", animation: 'opafrom0 0.5s ease' }}>
																<div className="btn text-muted btn-custom-success px-2 align-middle shadow-none w-100" style={{ pointerEvents: 'none' }}>
																	<i className="fa fa-check mr-2"></i>
																	<span>Success</span>
																</div>
																<p className="mt-2" style={{ color: '#21b463' }}>Re-login to continue..</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									:
									<div className="d-flex mx-0 mx-sm-3 py-2">
										<div className="text-center align-top" style={{ width: '28px' }}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle d-inline-block w-auto mt-1 text-custom-important" style={{ height: '20px', pointerEvents: 'none', strokeWidth: '2' }}>
												<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle>
											</svg>
										</div>
										<div className="flex-fill align-middle ml-2">
											<div className="flex-fill w-100">
												<p className="text-custom-important mb-1" style={{ fontSize: '125%' }}>
													<span className="align-middle">Your account</span>
												</p>
												<span className="text-muted d-none d-sm-flex">Your profile information</span>
											</div>
											<div className="row mt-4">
												<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
													<p className="text-custom mb-0">Profile name</p>
												</div>
												<div className="col-12 col-md-8 col-xl-10">
													<div className="d-flex mb-4">
														<div className="w-100 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
															<p className="text-custom-important mb-1 mb-sm-0">{User.name}</p>
														</div>
													</div>
												</div>
											</div>

											<div className="row mt-2">
												<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
													<p className="text-custom mb-0">User type</p>
												</div>
												<div className="col-12 col-md-8 col-xl-10">
													<div className="d-flex mb-4">
														<div className="w-100 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
															<p className="text-custom-important mb-1 mb-sm-0">Team member</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								}

								<div className="flex-fill border-top d-flex d-md-none mb-2 mb-md-0" style={{ height: '1px' }}></div>

								{User.perm == "Y" ?
									<div className="d-flex mx-0 mx-sm-3 py-2 pt-4 pt-md-2">
										<div className="text-center align-top" style={{ width: '28px' }}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle d-inline-block w-auto mt-1 text-custom-important" style={{ height: '20px', pointerEvents: 'none', strokeWidth: '2' }}>
												{/* <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path> */}
												{/* <circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle><circle cx="18" cy="19" r="3"></circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line> */}
												<path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line>

											</svg>
										</div>
										<div className="flex-fill align-middle ml-2">
											<div className="flex-fill w-100">
												<p className="text-custom-important mb-1" style={{ fontSize: '125%' }}>
													<span className="align-middle">Your teams</span>
												</p>
												<span className="text-muted d-none d-sm-flex">Manage your teams setting</span>
											</div>
											<div className="row mt-4">
												<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
													<p className="text-custom mb-0">Permission</p>
												</div>
												<div className="col-12 col-md-8 col-xl-10">
													<div className="d-flex mb-4">
														<div className="flex-shrink-1 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
															<button className="toggle-switch-custom rounded-pill active" onClick={toggleActive}><div className="knob"></div></button>
														</div>
														<div className="w-100 ml-3 ml-sm-4">
															<p className="text-custom-important mb-1 mb-sm-0">Join with invitation code</p>
															<p className="text-muted mb-0 d-none d-sm-flex">Turn on to allow your team can join with an invitation code</p>
														</div>
													</div>
												</div>
											</div>
											<div className="row mt-2">
												<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
													<p className="text-custom mb-0">Your invitation code</p>
												</div>
												<div className="col-12 col-md-8 col-xl-10">
													<div className="d-flex mb-4">
														<div className="w-100 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
															<div className="input-group">
																<input className="form-control bg-transparent rounded-lg pr-4 text-input-custom shadow-none text-sm" defaultValue={User.code} type="text" autoComplete="off" readOnly style={{ marginTop: "-3.5px", maxWidth: "175px" }}>
																</input>
																<div className="input-group-text-custom btn text-muted ml-2 btn-hover-custom-2 px-2 align-middle text-center" style={{ marginTop: "-3.5px" }} onClick={CopyCode}>
																	<small style={{ pointerEvents: "none" }}>Copy</small>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									:
									<div className="d-flex mx-0 mx-sm-3 py-2 pt-4 pt-md-2">
										<div className="text-center align-top" style={{ width: '28px' }}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="feather align-middle d-inline-block w-auto mt-1 text-custom-important" style={{ height: '20px', pointerEvents: 'none', strokeWidth: '2' }}>
												<path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line>

											</svg>
										</div>
										<div className="flex-fill align-middle ml-2">
											<div className="flex-fill w-100">
												<p className="text-custom-important mb-1" style={{ fontSize: '125%' }}>
													<span className="align-middle">Your teams</span>
												</p>
												<span className="text-muted d-none d-sm-flex">Your teams infomation</span>
											</div>
											<div className="row mt-4">
												<div className="col-12 col-md-4 col-xl-2 mb-3 mb-md-0">
													<p className="text-custom mb-0">Team leader</p>
												</div>
												<div className="col-12 col-md-8 col-xl-10">
													<div className="d-flex mb-4">
														<div className="w-100 ml-2 ml-sm-0 ml-md-2 ml-xl-0">
															<p className="text-custom-important mb-1 mb-sm-0">{User.owner}</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								}
							</div>
							<div className="submenu d-none d-md-flex">
								<ul className="sidebar-nav">
									{/* <li className="sidebar-item mx-2 active" onClick={()=>{activeSubmenu(0)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Notifications</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(1)}}>
									<a className="sidebar-link text-muted bg-transparent px-3" >
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Members & Permissions</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(2)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Data & History</span>
									</a>
								</li>
								<li className="sidebar-item mx-2" onClick={()=>{activeSubmenu(3)}}>
									<a className="sidebar-link text-muted bg-transparent px-3">
										<span className="align-middle" style={{ pointerEvents: 'none' }}>Help</span>
									</a>
								</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</>
		);

	} else if (Response.message && Response.message == "No Data") {
		return (
			<>
				<div className="container-fluid p-0">
					<div className="row" style={{ height: 'calc(100vh - 150px)' }}>
						<div className="my-auto mx-auto text-center">
							<h2 className="text-muted">Error</h2>
							<h1 className="text-muted" style={{ fontSize: '4.5rem' }}>503</h1>
							<h3 className="text-muted">Service Unavailable</h3>
						</div>
					</div>
				</div>
			</>
		);
	} else if (TimeOut == false) {
		setTimeout(function () {
			setTimeOut(true)
		}, 10000)
		return (
			<>
				<div className="container-fluid p-0 loading">
					<div className="row" style={{ height: 'calc(100vh - 150px)' }}>
					</div>
				</div>
			</>
		);
	} else if (TimeOut) {
		return (
			<>
				<div className="container-fluid p-0">
					<div className="row" style={{ height: 'calc(100vh - 150px)' }}>
						<div className="my-auto mx-auto text-center">
							<h2 className="text-muted">Error</h2>
							<h1 className="text-muted" style={{ fontSize: '4.5rem' }}>524</h1>
							<h3 className="text-muted">Timeout Occurred</h3>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default Content
