import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Chart from "react-apexcharts";
import fire from '../../firebase'
import { TimeSeriesDataFormat } from "../../../customLibs/dataFormat";
const $ = require('jquery');


async function getTimeSeries(credentials) {
    return fetch('https://dixell.snapx.cloud/api/allTimeSeriesByID', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(response => response.json())
}

function ApexChart(props) {

    const { id } = useParams();
    const [dataChart, setDataChart] = useState({});


    useEffect(async () => {
        await getTimeSeries({ id: id, timeframe: 1800000, start: Date.now() - 604800000, end: Date.now() }).then(
            (result) => {
                // setChart(TimeSeriesDataFormat(result, props.lib).AI.Series.filter(x => x.name === "Probe 1" || x.name === "Probe 2" || x.name === "Probe 3" || x.name === "Probe 4"))
                setChart(TimeSeriesDataFormat(result, props.lib))
            }
        );
    }, []);

    const getRandomColorHLS = () => {
        return "hsl(" + Math.floor(Math.random() * (255 - 0) + 0) + "," + Math.floor(Math.random() * (90 - 10) + 10) + "%," + Math.floor(Math.random() * (70 - 40) + 40) + "%)";
    }

    const setChart = async (chartData) => {
        delete chartData["DI"]
        let AIarr = []
        for (let [i, v] of Object.entries(chartData["AI"]["Series"])) {
            // console.log(v.key == "ai")
            if (v.name === "Probe 1" || v.name === "Probe 2" || v.name === "Probe 3" || v.name === "Probe 4") {
                AIarr.push(chartData["AI"]["Series"][i])
            }
            // if (v["name"].toLowerCase().includes('probe') == false) {
            // }
        }
        chartData["AI"]["Series"] = AIarr;

        const colorArr = (type) => {
            let Arr = [];
            if (type == "AI") {
                Arr = ["#4ECDC4", "#3F51B5", "#D4526E", "#13D8AA", "#A5978B"];
                for (let i = 5; i < chartData.AI.Series.length; i++) {
                    Arr.push(getRandomColorHLS());
                }
                return Arr
            }
            else if (type == "DI") {
                Arr = ["#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7"];
                for (let i = 5; i < chartData.DI.Series.length; i++) {
                    Arr.push(getRandomColorHLS());
                }
                return Arr
            }
        }
        $('#chart').addClass('loading')

        const chartConfigAI = {
            series: chartData.AI ? chartData.AI.Series : [],
            options: {
                chart: {
                    id: "AIChart",
                    type: 'area',
                    group: "SyncChart",
                    offsetX: 0,
                    offsetY: -10,
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true
                    },
                    toolbar: {
                        autoSelected: 'zoom',
                        show: true,
                        offsetX: 25,
                        offsetY: -15,
                        tools: {
                            selection: false,
                            download: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                        },
                        export: {
                            csv: {
                                filename: 'SnapX_Data',
                                columnDelimiter: ',',
                                headerCategory: 'Timestamp',
                                headerValue: 'value',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString()
                                }
                            },
                            svg: {
                                filename: 'SnapX_Chart',
                            },
                            png: {
                                filename: "SnapX_Chart",
                            }
                        },
                    },
                    background: '#2d3036',
                    animations: {
                        enabled: false,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: false,
                            delay: 100
                        },
                        dynamicAnimation: {
                            enabled: false,
                            speed: 350
                        }
                    },
                },
                colors: colorArr('AI'),
                stroke: {
                    show: true,
                    curve: 'straight',
                    lineCap: 'round',
                    width: 1.5,
                    dashArray: 0,
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                    strokeWidth: 0,
                    fillOpacity: 0.75,
                    hover: {
                        size: 0,
                    }
                },
                legend: {
                    show: false,
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        inverseColors: false,
                        gradientToColors: ['rgba(44, 47, 53,0)'],
                        opacityFrom: 0.5,
                        opacityTo: 0,
                        stops: [20, 150]
                    },
                },
                yaxis: {
                    labels: {
                        show: true,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: false,
                        style: {
                            colors: 'rgba(255,255,255,0.5)',
                            fontSize: '11px',
                            fontWeight: 400,
                        },
                        formatter: (value) => { return value.toFixed(0) },
                    },
                    title: {
                        text: "Temperature (°C)",
                        rotate: -90,
                        offsetX: -5,
                        offsetY: 0,
                        style: {
                            color: "rgba(255,255,255,0.5)",
                            fontSize: '12px',
                            fontWeight: 400,
                        },
                    },
                    // min: 0,
                    tickAmount: 4
                },
                xaxis: {
                    type: 'datetime',
                    categories: chartData.timeStamp || [],
                    tooltip: {
                        enabled: false,
                    },
                    labels: {
                        show: true,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: true,
                        style: {
                            colors: 'rgba(255,255,255,0.5)',
                            fontSize: '11px',
                            fontWeight: 400,
                        },
                        format: 'dd MMM',
                        formatter: undefined,
                        datetimeUTC: false,
                        datetimeFormatter: {
                            year: 'yyyy',
                            month: "MMM 'yy",
                            day: 'dd MMM',
                            hour: 'HH:mm',
                        },
                    },
                    axisTicks: {
                        show: false,
                        color: 'rgba(255, 255, 255, 0.2)',
                        height: 5,
                    },
                    axisBorder: {
                        show: false
                    }
                },
                grid: {
                    show: true,
                    borderColor: 'rgba(255, 255, 255, 0.05)',
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                },
                theme: {
                    mode: 'dark',
                },
                tooltip: {
                    enabled: true,
                    style: {
                        fontSize: '12px'
                    },
                    x: {
                        show: true,
                        format: 'dd MMM yy HH:mm ',
                        formatter: undefined,
                    },
                    y: {
                        formatter: function (y, obj) {
                            if (typeof y !== "undefined") {
                                return y + " " + chartData.AI.Series[obj.seriesIndex].unit;
                            }
                            return y;
                        },
                        title: {
                            formatter: (seriesName) => seriesName + " : ",
                        },
                    }
                },
                annotations: {
                    yaxis: [
                        {
                            y: 0,
                            strokeDashArray: 0,
                            borderColor: 'rgba(255, 255, 255, 0.05)',
                            borderWidth: 1.5,
                            opacity: 1
                        }
                    ]
                }
            },
        };

        setDataChart(chartConfigAI)

    }

    const colorArr = () => {
        let Arr = [];
        Arr = ["#4ECDC4", "#3F51B5"];
        return Arr
    }

    if (Object.keys(dataChart).length > 0) {
        $('#chart').removeClass('loading')
        return (
            <>
                <Link to={{ pathname: "/chart", value: id }} className="custom-chart-toolbar text-decoration-none rounded-lg">
                    <small className="text-custom ">View more</small>
                </Link>
                <div id="AIChart-wrapper" style={{ height: "235px" }}>
                    <Chart
                        options={dataChart.options}
                        series={dataChart.series}
                        type="area"
                        height="100%"
                    />
                </div>
            </>
        )
    }
    else {
        return (
            <>
                <div id="AIChart-wrapper" style={{ height: '235px' }}>
                    <div className="row loading" style={{ height: '100%' }}>
                    </div>
                </div>
            </>
        )
    }

}

export default ApexChart