import React, { useState, useEffect, useCallback } from 'react'
import fire from '../../firebase_alm'
import { tsFormat } from '../../../customLibs/timeStampFormat';
import { sortTable } from "../../../customLibs/sortTable";
import { useHistory, Link } from 'react-router-dom';
import 'firebase'
import { contains } from 'jquery';

const $ = require('jquery');

async function getAllDeviceList(credentials) {
	return fetch('https://dixell.snapx.cloud/api/deviceListString', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(credentials)
	})
		.then(response => response.json())
}

function Content() {
	const [Alarm, setAlarm] = useState([])
	const [AllAlarm, setAllAlarm] = useState([])
	const [Info, setInfo] = useState({})
	const [DeviceList, setDeviceList] = useState([]);
	const [TimeOut, setTimeOut] = useState(false);
	const [Response, setResponse] = useState({});
	const history = useHistory();


	let obj = {};
	let deviceCount = 0;
	let counter = 0;
	let db = fire.database();

	useEffect(async () => {
		let resList = await getAllDeviceList(
			{
				user: localStorage.user
			}
		)
		setDeviceList(resList.map)
		if (resList.map.length == 0) {
			setResponse({ message: "No Data" })
		}
	}, [])

	useEffect(() => {
		updateData()
	}, [DeviceList])

	const updateData = () => {
		deviceCount = DeviceList.length;
		if (deviceCount > 0) {
			for (let d of DeviceList) {
				getAlarmData(d.id, d.label == "" ? d.deviceName : d.label)
			}
		}
	}

	function getAlarmData(id, name) {
		return db.ref('/' + id).on('value', (snapshot) => {
			snapshot.forEach(childSnapshot => {
				const key = childSnapshot.key;
				const data = childSnapshot.val();
				data.id = id
				data.key = key;
				data.deviceName = name
				obj[data.timestamp] = data;
			});
			counter++;
			if (counter == deviceCount) {
				setAllAlarm(obj)
				sort()
			}
		});
	}

	const sort = () => {
		const sorting = async () => {
			let Arr = [];
			let bufferObj = Object.keys(obj).sort()
			bufferObj.reverse()
			for (let [i, v] of Object.entries(bufferObj)) {
				Arr.push(obj[v])
				if (i == 99) {
					break;
				}
			}
			return (Arr)
		}
		const alarmset = async () => {
			let alarm = await sorting()
			setAlarm(alarm)
			setResponse({ message: "Success" })
		}
		alarmset()
	}

	useEffect(() => {
		Seen(AllAlarm.length)
		getNewAlarm(AllAlarm.length)
	}, [Alarm])

	function getNewAlarm(num) {
		let newCount = 0;
		for (let d of DeviceList) {
			db.ref('/' + d.id).on('child_added', (snapshot) => {
				let fetchedTasks = Alarm;
				newCount++
				if (newCount > num) {
					const key = snapshot.key;
					const data = snapshot.val();
					data.id = d.id
					data.key = key;
					data.deviceName = d.label == "" ? d.deviceName : d.label
					fetchedTasks.splice(0, 0, data)
					setAlarm(fetchedTasks)
					setResponse({ message: "Success" })
				}
			});
		}
	}

	const exportTableCSV = () => {
		const AlarmData = [];

		Alarm.forEach(function (data) {
			let Arr = [data.name, data.deviceName, data.state, tsFormat(data.ts, 'long')];
			AlarmData.push(Arr)
		})

		let csv = 'Message,Device,State,Timestamp\n';

		AlarmData.forEach(function (row) {
			csv += row.join(',');
			csv += "\n";
		});

		var link = document.createElement("a");
		link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
		link.download = "my_data.csv";
		link.target = "_blank";
		document.body.appendChild(link);

		link.click();
	}

	async function request() {
		if (!$('#startReq')[0].valueAsNumber && !$('#endReq')[0].valueAsNumber) {

			alert("No Time range Selected.")

		} else if (!$('#startReq')[0].valueAsNumber && $('#endReq')[0].valueAsNumber) {

			alert("No Start Time Selected.")

		} else if ($('#startReq')[0].valueAsNumber && !$('#endReq')[0].valueAsNumber) {

			alert("No End Time Selected.")

		} else if ($('#startReq')[0].valueAsNumber > $('#endReq')[0].valueAsNumber) {

			alert("Plase Select Time Range Correctly.")

		} else {
			exportDBCSV()
		}
	}

	const exportDBCSV = () => {
		alert('Data Base is Empty')
	}

	const FixedTime = (e, range) => {
		Active(e)
		let local = Date.now();
		let timeOffset = new Date().getTimezoneOffset() * 60 * 1000 * -1;
		let newEndDate = local + timeOffset;
		let newStartDate = new Date(newEndDate);
		if (range < 30) {
			newStartDate.setDate(newStartDate.getDate() - range);
		} else {
			newStartDate.setMonth(newStartDate.getMonth() - range / 30);
		}
		$('#startReq')[0].valueAsNumber = newStartDate -= newStartDate % 60000;
		$('#endReq')[0].valueAsNumber = newEndDate -= newEndDate % 60000;
	}

	const Active = (e) => {
		Inactive()
		e.target.classList.add('active')
	}

	const Inactive = () => {
		$('.fixedTimeSel').removeClass('active')
	}

	const Acknowledge = (alarm) => {
		let updateObj = {};
		let key = alarm["key"];
		if (alarm.prop.ack == false) {
			if (!updateObj[key]) {
				updateObj[key] = {}
			}
			updateObj[key] = Object.assign({}, alarm);
			updateObj[key].prop['ack'] = true;
			updateObj[key].prop['ackTimestamp'] = Date.now();
			delete updateObj[key].id;
			delete updateObj[key].key;
			delete updateObj[key].deviceName;
			fire.database().ref('/' + alarm.id).update(updateObj)
		}
	}

	const Remove = (e) => {
		e.preventDefault();
		let confirm = window.confirm('Confirm Remove ?')
		if (confirm) {
			for (let i = 0; i < $('.table-alarm tbody tr.selected').length; i++) {
				let index = $('.table-alarm tbody tr.selected')[i].dataset.index;
				let key = Alarm[index]["key"];
				let id = Alarm[index]["id"];
				// console.log("id:" + id, "key:" + key)
				fire.database().ref('/' + id).child(key).remove()
			}
		}
		updateData()
		CloseMenu()
	}

	const AcknowledgeAll = () => {
		for (let i = 0; i < $('.table-alarm tbody tr.selected').length; i++) {
			let index = $('.table-alarm tbody tr.selected')[i].dataset.index;
			Acknowledge(Alarm[index])
		}
		CloseMenu()
	}

	const Seen = (length) => {
		for (let i = 0; i < length; i++) {
			let key = Alarm[i]["key"];
			if (Alarm[i].prop.seen == false) {
				let updateObj = {};
				if (!updateObj[key]) {
					updateObj[key] = {}
				}
				delete Alarm[i].icon
				updateObj[key] = Object.assign({}, Alarm[i]);
				updateObj[key].prop.seen = true;
				updateObj[key].prop.seenTimestamp = Date.now();
				delete updateObj[key].id;
				delete updateObj[key].key;
				delete updateObj[key].deviceName;
				fire.database().ref('/' + Alarm[i].id).update(updateObj)
			}
		}
	}

	const Selection = (e) => {
		if (e.type && e.type == 'click') {
			e.target.className.includes('active') ? e.target.classList.remove('active') : e.target.classList.add('active')
			e.target.parentElement.parentElement.className.includes('selected') ? e.target.parentElement.parentElement.classList.remove('selected') : e.target.parentElement.parentElement.classList.add('selected')
		} else if (e == 'all') {
			if ($('#alarmTable_head tr th:first-child i.fa').hasClass('active')) {
				$('.custom-checkBox').removeClass('active')
				$('.table-alarm tbody tr').removeClass('selected')
			} else {
				$('.custom-checkBox').addClass('active')
				$('.table-alarm tbody tr').addClass('selected')
			}
		}

		if ($('.table-alarm tbody tr.selected').length > 0) {
			$('#alarmTable_head thead tr th.tableHeader').addClass('d-none')
			$('.activeMenu').css('display', 'table-cell')
		} else {
			$('#alarmTable_head thead tr th.tableHeader').removeClass('d-none')
			$('.activeMenu').css('display', 'none')
		}
		$('#selCounter')[0].innerText = $('.table-alarm tbody tr.selected').length + ($('.table-alarm tbody tr.selected').length > 1 ? " Rows" : " Row");
	}


	const CloseMenu = () => {
		$('.custom-checkBox').removeClass('active')
		$('.table-alarm tbody tr').removeClass('selected')
		$('#alarmTable_head thead tr th.tableHeader').removeClass('d-none')
		$('.activeMenu').css('display', 'none')
		$('#selCounter')[0].innerText = $('.table-alarm tbody tr.selected').length
	}

	const openScreen = useCallback((id) => {
		$('.modal-backdrop').remove();
		document.body.removeAttribute('class')
		document.body.removeAttribute('style')
		history.push('/list/' + id)
	}, [history]);

	const divData = Alarm.map((val, index) => {
		return (
			<tr key={index} style={{ cursor: "pointer" }} data-index={index} className={val.prop.ack ? '' : 'unAck'}>
				<td style={{ width: "10px" }} scope="row" className="align-middle py-3 pl-3 pr-0 my-auto border-bottom-dark">
					<i className="fa fa-check custom-checkBox" onClick={(e) => (Selection(e))}></i>
				</td>
				<td style={{ width: "0px" }} scope="row" className="align-middle py-3 px-0 my-auto border-bottom-dark AlarmAckDot" onClick={() => { setInfo(Alarm[index]); Acknowledge(Alarm[index]) }} data-toggle="modal" data-target="#Info" >
				</td>
				<td style={{ width: "calc(35% - 10px)" }} scope="row" className="align-middle py-3 my-auto border-bottom-dark tableRowResponsiveCustom-1" onClick={() => { setInfo(Alarm[index]); Acknowledge(Alarm[index]) }} data-toggle="modal" data-target="#Info" >
					<span className="ml-3">{val.name}</span>
				</td>
				<td style={{ width: "30%" }} className="align-middle py-3 pl-3 my-auto border-bottom-dark" onClick={() => { setInfo(Alarm[index]); Acknowledge(Alarm[index]) }} data-toggle="modal" data-target="#Info" >{val.deviceName}</td>
				<td style={{ width: "15%", color: val.state ? "#ee4040" : "" }} className="align-middle py-3  pl-3 my-auto border-bottom-dark" onClick={() => { setInfo(Alarm[index]); Acknowledge(Alarm[index]) }} data-toggle="modal" data-target="#Info" >{val.state ? "Active" : "Inactive"}</td>
				<td style={{ width: "20%" }} className="align-middle py-3 my-auto pl-3 border-bottom-dark tableRowResponsiveCustom-2" data-ts={val.timestamp} onClick={() => { setInfo(Alarm[index]); Acknowledge(Alarm[index]) }} data-toggle="modal" data-target="#Info" >{tsFormat(val.timestamp, 'long')}</td>
			</tr >
		);
	});

	const divDataSmall = Alarm.map((val, index) => {
		return (
			<div key={index} className={val.prop.ack ? 'col-12' : 'col-12 unAck'} data-index={index} onClick={() => { setInfo(Alarm[index]); Acknowledge(Alarm[index]) }} data-toggle="modal" data-target="#Info" >
				<div className={index == Alarm.length - 1 ? "row mx-0 py-3" : "row mx-0 border-bottom border-bottom-dark py-3"} style={{ pointerEvents: 'none' }}>
					<div className="col-auto text-center p-0" style={{ width: '20px' }}>
						<div className="my-auto mx-auto text-center">
							<i className="fa fa-circle" style={{ transform: 'scale(0.5,0.5)', color: val.prop.ack ? 'transparent' : '#548adb' }}></i>
						</div>

					</div>
					<div className="flex-fill mr-2">
						<div className="d-block">
							<div className="d-inline-block">
								<p className="mb-0">
									<span className="text-custom-table">{val.name}</span>
								</p>
							</div>
							<div className="float-right d-inline-block">
								<p className="mb-0 text-muted">
									<small className="mr-1 text-custom">{tsFormat(val.timestamp, 'auto')}</small>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ strokeWidth: "1.5", pointerEvents: 'none' }}><polyline points="9 18 15 12 9 6"></polyline></svg>
								</p>
							</div>
						</div>
						<div className="mb-0 d-flex">
							<div className="flex-shrink-0">
								<small className="text-muted mr-1">Sender : </small>
							</div>
							<div className="w-100 text-truncate">
								<small className="text-custom">{val.deviceName}</small>
							</div>
						</div>
						<p className="mb-0">
							<small className="text-muted">State : </small>
							<small className="ml-1" style={{ color: val.state ? "#ee4040" : "" }}>{val.state ? "Active" : "Inactive"}</small>
						</p>
					</div>
				</div>
			</div>
		)
	});

	if (Response.message && Response.message == "Success" && Object.keys(Alarm).length > 0) {
		return (
			<>
				<div className="container-fluid p-0">
					<div className="row title mb-2 mb-md-4 pb-3 mx-0 border-bottom">
						<div className="col-6 px-0">
							<h3>Alarm History</h3>
						</div>
						<div className="col-6 px-0 text-right">
							<div className="float-right">
								<button type="button" className="btn btn-hover-custom-noBG dropdown-toggle rounded ml-2" id="moreOption" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="More options">
									<i className="fa fa-ellipsis-v"></i>
								</button>
								<div className="dropdown-menu dropdown-menu-right" aria-labelledby="moreOption">
									<a className="dropdown-item px-3" onClick={() => { exportTableCSV() }}><i className="align-middle mr-2 my-1"
										data-feather="file"></i>
										<span className="ml-1 align-middle">Export table to CSV</span></a>
									<a className="dropdown-item px-3" data-toggle="modal" data-target="#DBSelect"><i className="align-middle mr-2 my-1"
										data-feather="database"></i>
										<span className="ml-1 align-middle">Export database to CSV</span></a>
									{/* <a className="dropdown-item px-3" onClick={() => { exportExcel() }}><i className="align-middle mr-2 my-1"
									data-feather="file"></i>
									<span className="ml-1 align-middle">Export Excel</span></a> */}
								</div>
							</div>
						</div>
					</div>
					<div className="row d-none d-sm-flex" id="alarmTable-wrapper">
						<div className="col-12">
							<div className="table-responsive">
								<table className="table mb-0" id="alarmTable_head">
									<thead>
										<tr style={{ cursor: "pointer" }}>
											<th style={{ width: "10px", fontWeight: "normal" }} className="align-middle py-3 pl-3 pr-0 my-auto text-muted">
												<i className="fa fa-check custom-checkBox" style={{ verticalAlign: 'text-top' }} onClick={() => (Selection('all'))}></i>
												<small style={{ pointerEvents: "none" }} ><i></i></small></th>
											<th style={{ width: "0px", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted px-0"><small style={{ pointerEvents: "none" }} ><i></i></small></th>
											<th style={{ width: "calc(35% - 10px)", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted tableHeader tableRowResponsiveCustom-1" onClick={(e) => { sortTable(2, e) }}><span className="ml-3">Message</span><small style={{ pointerEvents: "none" }} ><i></i></small></th>
											<th style={{ width: "30%", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted tableHeader" onClick={(e) => { sortTable(3, e) }}>Sender<small style={{ pointerEvents: "none" }} ><i></i></small></th>
											<th style={{ width: "15%", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted tableHeader" onClick={(e) => { sortTable(4, e) }}>State<small style={{ pointerEvents: "none" }} ><i></i></small></th>
											<th style={{ width: "20%", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted tableHeader tableRowResponsiveCustom-2" onClick={(e) => { sortTable(5, e) }}>Timestamp<small style={{ pointerEvents: "none" }} ><i className="fa fa-arrow-up ml-3"></i></small></th>
											<th style={{ fontWeight: "normal", cursor: 'default' }} className="align-middle my-auto pb-3 text-muted activeMenu text-center">
												<div className="float-left" >
													<span className="ml-3 mr-1" style={{ verticalAlign: 'middle' }}>Mark as<i className="fa fa-caret-up fa-rotate-90 ml-2"></i></span>
													<button className="btn btn-sm btn-custom rounded-lg mx-1" onClick={AcknowledgeAll}>Acknowledge</button>
													<button className="btn btn-sm btn-cancel-1 rounded-lg mx-1" onClick={Remove}>Remove</button>
												</div>
												<strong className="text-custom-light" style={{ position: 'absolute', left: '50%', transform: 'translate(-50%,0%)' }}><span id="selCounter">0 Row</span> Selected</strong>
												<a className="float-right" onClick={CloseMenu}>Cancel</a>
												<small style={{ pointerEvents: "none" }} ><i></i></small>
											</th>
										</tr>
									</thead>
								</table>
							</div>
							<div className="table-responsive overflow-custom" style={{ height: "calc(100vh - 280px)", marginRight: "-7.5px" }}>
								<table className="table table-hover table-alarm mb-0" id="alarmTable_body">
									<tbody>
										{divData}
									</tbody>
								</table>
							</div>
						</div>
					</div>


					<div className="row d-flex d-sm-none">
						{divDataSmall}
						{/* <div className="col-12">
							<div className="row mx-0 border-bottom border-bottom-dark py-3" style={{ pointerEvents: 'none' }}>
								<div className="col-auto text-center p-0 mx-1" style={{ width: '25px' }}>
									<div className="my-auto mx-auto text-center">
										<i className="fa fa-circle" style={{ transform: 'scale(0.6,0.6)'}}></i>
									</div>

								</div>
								<div className="flex-fill mr-1">
									<div className="d-inline-block">
										<p className="mb-0">
											<span className="text-custom-table">Fault Test</span>
										</p>
									</div>
									<div className="float-right d-inline-block mr-2">
										<p className="mb-0 px-1 text-muted">
											<small className="mx-1 text-custom">{tsFormat(Date.now(), 'auto')}</small>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" className="feather" style={{ strokeWidth: "1.5", pointerEvents: 'none' }}><polyline points="9 18 15 12 9 6"></polyline></svg>
										</p>
									</div>
									<p className="mb-0">
										<small className="text-muted">Sender : </small>
										<small className="ml-1 text-custom">SnapX Test</small>
									</p>
									<p className="mb-0">
										<small className="text-muted">State : </small>
										<small className="ml-1" style={{ color: '#ee525d' }}>Fault</small>
									</p>
								</div>
							</div>
						</div> */}
					</div>
					<div className="modal fade" id="DBSelect" tabIndex="-1" role="dialog" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered modal-sm" role="document">
							<div className="modal-content">
								<div className="modal-body">
									<h4 className="modal-title text-custom-light mb-2">Select time range</h4>
									<div className="flex-fill">
										<div className="row mb-2">
											<div className="col-12 text-center">
												<button className="btn col-auto my-1 pt-0 pb-1 text-center text-custom-light px-2 rounded cursor-pointer mx-1 fixedTimeSel" style={{ background: "rgba(100,100,110,0.30)" }} onClick={(e) => { FixedTime(e, 7) }}>
													<small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>7 days</small>
												</button>
												<button className="btn col-auto my-1 pt-0 pb-1 text-center text-custom-light px-2 rounded cursor-pointer mx-1 fixedTimeSel" style={{ background: "rgba(100,100,110,0.30)" }} onClick={(e) => { FixedTime(e, 30) }}>
													<small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>1 month</small>
												</button>
												<button className="btn col-auto my-1 pt-0 pb-1 text-center text-custom-light px-2 rounded cursor-pointer mx-1 fixedTimeSel" style={{ background: "rgba(100,100,110,0.30)" }} onClick={(e) => { FixedTime(e, 90) }}>
													<small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>3 months</small>
												</button>
												<button className="btn col-auto my-1 pt-0 pb-1 text-center text-custom-light px-2 rounded cursor-pointer mx-1 fixedTimeSel" style={{ background: "rgba(100,100,110,0.30)" }} onClick={(e) => { FixedTime(e, 180) }}>
													<small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>6 months</small>
												</button>
											</div>
										</div>
										<div className="row mx-0 mb-3 flex-fill">
											<div className="col-3 col-md-auto d-flex px-0 mr-md-2" style={{ width: "55px" }}>
												<p className="my-auto py-1">From :</p>
											</div>
											<div className="col-9 col-md-auto d-flex flex-fill px-0">
												<input className="form-control-lg w-100 rounded-lg dateSel px-2" id="startReq" type="datetime-local"></input>
											</div>
										</div>
										<div className="row mx-0 mb-1 flex-fill">
											<div className="col-3 col-md-auto d-flex px-0 mr-md-2" style={{ width: "55px" }}>
												<p className="my-auto py-1">To :</p>
											</div>
											<div className="col-9 col-md-auto d-flex flex-fill px-0">
												<input className="form-control-lg w-100 rounded-lg dateSel px-2" id="endReq" type="datetime-local"></input>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer border-top border-custom">
									<button type="button" className="btn btn-dark text-muted" data-dismiss="modal">Cancel</button>
									<button type="button" className="btn btn-primary" onClick={() => { request() }}>Export</button>
								</div>
							</div>
						</div>
					</div>

					<div className="modal fade" id="Info" tabIndex="-1" role="dialog" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered modal-sm" role="document">
							<div className="modal-content">
								<div className="modal-header border-bottom border-custom-dark">
									<h4 className="modal-title text-custom-light">Alarm Information</h4>
									<div className="btn close text-secondary" style={{ textShadow: "none" }} data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</div>
								</div>
								<div className="modal-body pb-1">
									<div className="flex-fill px-1">
										<p className=""><span style={{ width: '107.5px', display: 'inline-block' }}>Message :</span><strong className="ml-2 text-custom-light">{Info.name}</strong></p>
										<p className=""><span style={{ width: '107.5px', display: 'inline-block' }}>State :</span><strong className="ml-2 text-custom-light" style={{ color: Info.state == "Active" || Info.state == "Fault" ? "#ee525d" : "" }}>{Info.state}</strong></p>
										<p className=""><span style={{ width: '107.5px', display: 'inline-block' }}>Device :</span><strong className="ml-2 text-custom-light">{Info.deviceName}</strong></p>
										<p className=""><span style={{ width: '107.5px', display: 'inline-block' }}>Timestamp :</span><strong className="ml-2 text-custom-light">{tsFormat(Info.timestamp, 'long')}</strong></p>
										<p className=""><span style={{ width: '107.5px', display: 'inline-block' }}>Acknowledged :</span><strong className="ml-2 text-custom-light">{Info.prop ? Info.prop.ack == "" ? "-" : tsFormat(Info.prop.ackTimestamp, 'long') : '-'}</strong></p>
										<p className="mb-1"><span style={{ width: '150px', display: 'inline-block' }}>Server suggestion :</span></p>
									</div>
									<div className="rounded-lg my-2 pt-2 pb-0 px-3 overflow-custom" style={{ background: 'rgba(0,0,0,0.20)', border: 'solid 1px rgba(0,0,0,0.1)', minHeight: '100px', maxHeight: '150px', maxWidth: '100%' }}>
										<p className="mt-1">No suggestion available now.</p>
									</div>
								</div>
								<div className="modal-footer border-top border-custom-dark text-center">
									<div className="flex-fill">
										<div className="row mb-0">
											<div className="col-12 text-center">
												<a className="btn col-auto my-1 pt-1 pb-1 text-center btn-primary px-2 rounded cursor-pointer mx-1" onClick={() => { openScreen(Info.id) }}>
													<span className="mb-1 mx-1" style={{ pointerEvents: 'none' }}>View device</span>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	} else if ((Response.message && Response.message == "No Data") || Object.keys(Alarm).length == 0) {
		return (
			<>
				<div className="container-fluid p-0">
					<div className="row title mb-4 pb-3 mx-0 border-bottom">
						<div className="col-6 px-0">
							<h3>Alarm History</h3>
						</div>
					</div>
					<div className="row" id="alarmTable-wrapper">
						<div className="col-12">
							<div className="table-responsive">
								<table className="table mb-0" id="alarmTable_head">
									<thead>
										<tr style={{ cursor: "default" }}>
											<th style={{ width: "10px", fontWeight: "normal" }} className="align-middle pb-3 pl-3 pr-0 my-auto text-muted">
												<i className="fa fa-check custom-checkBox" onClick={() => (alert('No alarm found'))}></i>
											</th>
											<th style={{ width: "0px", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted px-0"></th>
											<th style={{ width: "calc(45% - 10px)", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted tableHeader"><span className="ml-3">Message</span></th>
											<th style={{ width: "20%", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted tableHeader">From</th>
											<th style={{ width: "15%", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted tableHeader">State</th>
											<th style={{ width: "20%", fontWeight: "normal" }} className="align-middle my-auto pb-3 text-muted tableHeader">Timestamp</th>
										</tr>
									</thead>
								</table>
							</div>
							<div className="table-responsive overflow-custom" style={{ height: "calc(100vh - 280px)", marginRight: "-7.5px" }}>
								<table className="table table-alarm mb-0" id="alarmTable_body">
									<tbody>
										<tr style={{ cursor: "default" }}>
											<td style={{ width: "100%" }} scope="row" className="align-middle py-5 my-auto border-bottom-dark text-center">
												<span>No alarm happened yet ...</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (TimeOut == false) {
		setTimeout(function () {
			setTimeOut(true)
		}, 10000)
		return (
			<>
				<div className="container-fluid p-0 loading">
					<div className="row" style={{ height: 'calc(100vh - 150px)' }}>
					</div>
				</div>
			</>
		);
	} else if (TimeOut) {
		return (
			<>
				<div className="container-fluid p-0">
					<div className="row" style={{ height: 'calc(100vh - 150px)' }}>
						<div className="my-auto mx-auto text-center">
							<h2 className="text-muted">Error</h2>
							<h1 className="text-muted" style={{ fontSize: '4.5rem' }}>524</h1>
							<h3 className="text-muted">Timeout Occurred</h3>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Content
