import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import Menu from '../../menu/menu'
import Topnav from '../../menu/topnav'
import Footer from '../../menu/footer'
import Content from './content'

function App() {
	const Location = useLocation();

	useEffect(() => {
		document.title = "Device chart | SnapX"
		const script = document.createElement('script');
		script.src = "../js/app.js"
		if(Location.value)
		document.body.append(script);
	}, [])

	if (localStorage.getItem("user")) {
		return (
			<div className="wrapper">
				<Menu active={2} />
				<div className="main">
					<Topnav />
					<main className="content loading">
						<Content data={Location.value} />
					</main>
					<Footer />
				</div>
			</div>
		)
	}
	else {
		window.location.replace("/");
	}
}

export default App
