import React, { useState, useEffect } from 'react'
import fire from '../../firebase'
import 'firebase'
import GoogleMapReact from 'google-map-react';
import { tsFormat } from '../../../customLibs/timeStampFormat';
import { RealtimeDataFormat, TimeSeriesDataFormat } from "../../../customLibs/dataFormat";
import ChartCard from './chartCard'
import DataTable from './dataTable'
import { sortTable } from "../../../customLibs/sortTable";
import { useParams, Link } from 'react-router-dom';
import MarkerStyled from '../map/mark/Marker/MarkerStyled';
import XR from '../map/mark/XR';
import Converter from '../../../customLibs/dataConverter'

const $ = require('jquery');

async function getAttr(credentials) {
	return fetch('https://dixell.snapx.cloud/api/deviceByIDSQL', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(credentials)
	})
		.then(response => response.json())
}

async function getTimeSeries(credentials) {
	return fetch('https://dixell.snapx.cloud/api/allTimeSeriesByID', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(credentials)
	})
		.then(response => response.json())
}


function Content() {

	const { id } = useParams();

	const [TimeOut, setTimeOut] = useState(false);
	const [Response, setResponse] = useState({});
	const [deviceInfo, setInfo] = useState({});
	const [deviceData, setData] = useState({});

	useEffect(async () => {
		await getAttr({ id: id, owner: localStorage.user })
			.then((resAttr) => {
				let Attr = JSON.parse(resAttr.attr)
				let Lib = JSON.parse(resAttr.lib)
				let Adr = Attr.address.split(', ')
				let obj = {
					Label: resAttr.label,
					deviceName: Attr.alias,
					Module: resAttr.type,
					Model: Lib.Name,
					Lib: Lib,
					imei: Attr.imei,
					geo: {
						adr1: Adr.length > 4 ? Adr[0] + ' ' + Adr[2].replace(' District', '') : Adr.length > 2 ? Adr[0] : Attr.address,
						adr2: Adr.length > 4 ? Adr[3] + ' ' + Adr[4] : Adr.length > 2 ? Adr[1].replace(' Province', '') + ' ' + Adr[2].replace(' Province', '') : "",
						adr3: Adr.length > 4 ? Adr[5] : Adr.length > 2 ? Adr[3] : "",
						lat: Attr.lat,
						lng: Attr.lon,
						rad: Attr.accuracy,
						ts: Attr.lastActivityTime
					},
					sn: Attr.sn,
					st: resAttr.connect,
					stts: Attr.lastConnectTime
				}
				setInfo(obj)
				if (Object.keys(resAttr).length > 0) {
					getDataFireBase(obj.Lib)
					setResponse({ message: "Success" })
				} else {
					setResponse({ message: "No Data" })
				}
			})
	}, []);

	const getDataFireBase = async (lib) => {
		let database = fire.app('realtime').database().ref('/' + id);
		await database.once('value', (snapshot) => {
			let convertedData = Converter({ data: snapshot.val().d, lib: lib })
			convertedData.info = Object.assign(convertedData.info, snapshot.val().d.ts)
			setData(convertedData)
			setResponse({ message: "Success" })
		})
	}

	useEffect(() => {
		for (let i = 0; i < $('.defaultSort').length; i++) {
			// console.dir($('.defaultSort')[i].offsetParent.parentElement.nextElementSibling.children[0].children[0].children.length)
			for (let j = 0; j < $('.defaultSort')[i].parentElement.children.length; j++) {
				if ($('.defaultSort')[i].parentElement.children[j].childNodes[1].classList.length == 0) {
					if ($('.defaultSort')[i].offsetParent.parentElement.nextElementSibling.children[0].children[0].children.length > 2) {
						$('.defaultSort')[i].click();
					} else {
						$('.defaultSort')[i].click();
						$('.defaultSort')[i].click();
					}
				}
			}
		}
	}, [])


	const srollLock = () => {
		$('.overflow-custom').bind('mousewheel DOMMouseScroll', function (e) {
			var scrollTo = null;

			if (e.type == 'mousewheel') {
				scrollTo = (e.originalEvent.wheelDelta * -0.5);
			} else if (e.type == 'DOMMouseScroll') {
				scrollTo = 40 * e.originalEvent.detail;
			}

			if (scrollTo) {
				e.preventDefault();
				$(this).scrollTop(scrollTo + $(this).scrollTop());
			}
		});
	}

	async function request() {
		let start = $('#startReq')[0].valueAsNumber,
			end = $('#endReq')[0].valueAsNumber;
		// samp = $('#sampReq')[0].valueAsNumber;

		if (!start && !end) {

			alert("No Time range Selected.")

		} else if (!start && end) {

			alert("No Start Time Selected.")

		} else if (start && !end) {

			alert("No End Time Selected.")

		} else if (start > end) {

			alert("Plase Select Time Range Correctly.")

			// } else if (samp <= 0) {

			// 	alert("Plase fill Sampling time Correctly.")

			// } else if (Number.isNaN(samp)) {

			// 	alert("Plase fill Sampling time")

		} else {
			let samp = Math.floor((end - start) / 672)
			let timeOffset = new Date().getTimezoneOffset() * 60 * 1000 * -1;
			start = start - timeOffset
			end = end - timeOffset
			exportDBCSV(start, end, samp)
		}
	}

	const FixedTime = (e, range) => {
		Active(e)

		let local = Date.now();
		let timeOffset = new Date().getTimezoneOffset() * 60 * 1000 * -1;
		let newEndDate = local + timeOffset;
		let newStartDate = new Date(newEndDate);
		if (range < 30) {
			newStartDate.setDate(newStartDate.getDate() - range);
		} else {
			newStartDate.setMonth(newStartDate.getMonth() - range / 30);
		}
		$('#startReq')[0].valueAsNumber = newStartDate -= newStartDate % 60000;
		$('#endReq')[0].valueAsNumber = newEndDate -= newEndDate % 60000;
	}

	const Active = (e) => {
		Inactive()
		e.target.classList.add('active')
	}

	const Inactive = () => {
		$('.fixedTimeSel').removeClass('active')
	}

	const exportDBCSV = async (start, end, samp) => {
		await getTimeSeries({ id: id, timeframe: samp, start: start, end: end }).then(
			(result) => {
				let data = TimeSeriesDataFormat(result, deviceInfo.Lib)
				let header, rowLength, datainputType, values, output
				header = {
					'timeStamp': null
				}
				rowLength = data['timeStamp'].length;
				datainputType = [];
				values = []

				for (let i in data) {
					if (Array.isArray(data[i]) == false) {
						datainputType.push(i)
					}
				}

				for (let i = 0; i < rowLength; i++) {
					let ts = tsFormat(data['timeStamp'][i], 'long')
					values.push(ts)
					for (let j in datainputType) {
						for (let k in data[datainputType[j]].Series) {
							header[data[datainputType[j]].Series[k].name + "_" + data[datainputType[j]].Series[k].key] = null
							values.push(data[datainputType[j]].Series[k].data[i])
						}
					}
				}
				output = ""
				var i, j, temparray, chunk = Object.keys(header).length;
				for (let i = 0, j = values.length; i <= j; i += chunk) {
					output += values.slice(i, i + chunk).join(",") + "\n"
				}
				data = Object.keys(header).join(",") + "\n" + output

				var link = document.createElement("a");
				link.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
				link.download = "SnapX_" + (deviceInfo.Label || deviceInfo.deviceName || deviceInfo.imei) + ".csv";
				link.target = "_blank";
				document.body.appendChild(link);

				link.click();
			}
		);

	}
	// console.log(Response.message)

	if (Response.message && Response.message == "Success" && Object.keys(deviceInfo).length > 0) {
		return (
			<>
				<div className="container-fluid p-0">
					<div className="row title mb-4 pb-3 mx-0 border-bottom">
						<div className="col-6 px-0">
							{/* <h3>{deviceData.st ? deviceData.st.deviceName : deviceData.deviceName || "Undefinded Device"}<small className="ml-2 text-custom">( {deviceData.Model} )</small></h3> */}
							<h3>{deviceInfo.Label == "" ? deviceInfo.deviceName ? deviceInfo.deviceName : "Undefinded Device" : deviceInfo.Label}<small className="ml-2 text-custom">( {deviceInfo.Model} )</small></h3>
						</div>

						<div className="col-6 px-0 text-right">
							<div className="float-right">
								<button type="button" className="btn btn-hover-custom-noBG dropdown-toggle rounded ml-2" id="moreOption" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="More options">
									<i className="fa fa-ellipsis-v"></i>
								</button>
								<div className="dropdown-menu dropdown-menu-right" aria-labelledby="moreOption">
									<a className="dropdown-item px-3" data-toggle="modal" data-target="#DBSelect">
										<i className="align-middle mr-2 my-1 fa fa-file-csv" style={{ transform: "scale(1.2,1.2)" }}></i>
										<span className="ml-1 align-middle">Export CSV</span>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-xl-6">
							<div className="row-12">
								<div className="col-12">
									<div className="row" style={{ minHeight: "322px" }} >
										{/* <div className="card bg-transparent col-12"> */}
										<div className="card bg-transparent shadow-none col-12 col-sm-6 col-xl-4 col-xxl-6 py-4 pr-4">
											<img src="../img/device/XR_outline.svg" id="deviceInfoIcon" className="m-auto" />
										</div>
										<div className="card col-12 col-sm-6 col-xl-8 col-xxl-6 p-3">
											<div className="card-header py-2 px-2" >
												<h4 className="text-custom-important">Device Infomation</h4>
											</div>
											<div className="device-info card-body pt-1 pb-0 px-2 overflow-custom" style={{ maxHeight: "214px" }}>
												<div className="d-flex py-1 mb-1">
													<div className="flex-shrink-1" style={{ width: '200px' }}>
														<span>Label :</span>
													</div>
													<div className="w-100">
														<span className="text-custom-light">{deviceInfo.Label || deviceInfo.deviceName}</span>
													</div>
												</div>
												<div className="d-flex py-1 mb-1">
													<div className="flex-shrink-1" style={{ width: '200px' }}>
														<span>Model :</span>
													</div>
													<div className="w-100">
														<span className="text-custom-light">{deviceInfo.Model}</span>
													</div>
												</div>
												<div className="d-flex py-1 mb-1">
													<div className="flex-shrink-1" style={{ width: '200px' }}>
														<span>Device name :</span>
													</div>
													<div className="w-100">
														<span className="text-custom-light">{deviceInfo.deviceName}</span>
													</div>
												</div>
												<div className="d-flex py-1 mb-1">
													<div className="flex-shrink-1" style={{ width: '200px' }}>
														<span>Serial number :</span>
													</div>
													<div className="w-100">
														<span className="text-custom-light">{deviceInfo.sn}</span>
													</div>
												</div>
												<div className="d-flex py-1 mb-1">
													<div className="flex-shrink-1" style={{ width: '200px' }}>
														<span>Imei :</span>
													</div>
													<div className="w-100">
														<span className="text-custom-light">{deviceInfo.imei}</span>
													</div>
												</div>
												<div className="d-flex py-1 mb-1">
													<div className="flex-shrink-1" style={{ width: '200px' }}>
														<span>Status :</span>
													</div>
													<div className="w-100">
														<span className="text-custom-light">{deviceInfo.st == 1 ? 'Active' : 'Inactive'}</span>
													</div>
												</div>
												<div className="d-flex py-1 mb-1">
													<div className="flex-shrink-1" style={{ width: '200px' }}>
														<span>Last connect :</span>
													</div>
													<div className="w-100">
														<span className="text-custom-light">{tsFormat(deviceInfo.stts, 'long')}</span>
													</div>
												</div>
												{
													deviceData.info && deviceData.info.ticks ?
														<div className="d-flex py-1 mb-1">
															<div className="flex-shrink-1" style={{ width: '200px' }}>
																<span>Last activity :</span>
															</div>
															<div className="w-100">
																<span className="text-custom-light">{tsFormat(deviceData.info.ticks, 'long')}</span>
															</div>
														</div>
														:
														<></>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row-12">
								<div className="card">
									<div className="card-body">
										<ChartCard data={deviceData['Analog Inputs'] ? deviceData['Analog Inputs'] : undefined} lib={deviceInfo.Lib} ></ChartCard>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-xl-6">
							<div className="card">
								<div className="card-body">
									<div id="mapInfo">
										<GoogleMapReact
											bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
											center={{ lat: deviceInfo.geo.lat, lng: deviceInfo.geo.lng }}
											yesIWantToUseGoogleMapApiInternals={true}
											defaultZoom={14}
											options={() => ({
												minZoom: 5
											})}
											onGoogleApiLoaded={({ map, maps }) =>
											(
												new maps.Circle({
													strokeColor: '#548adb',
													strokeOpacity: 0.8,
													strokeWeight: 2,
													fillColor: '#548adb',
													fillOpacity: 0.35,
													map,
													center: { lat: deviceInfo.geo.lat, lng: deviceInfo.geo.lng },
													radius: deviceInfo.geo.rad
												})
												,
												// new maps.Rectangle({
												// 	strokeColor: '#2dccb5',
												// 	strokeOpacity: 0.8,
												// 	strokeWeight: 2,
												// 	fillColor: '#2dccb5',
												// 	fillOpacity: 0.4,
												// 	map,
												// 	bounds: {
												// 		north: deviceData.geo.lat + 0.0045,
												// 		south: deviceData.geo.lat - 0.0045,
												// 		east: deviceData.geo.lng + 0.0045,
												// 		west: deviceData.geo.lng - 0.0045,
												// 	},
												// 	// editable: true
												// })
												setTimeout(() => {
													$('.mapPin-wrapper')[0].removeAttribute('style');
												}, 500)
											)}
										>
											<MarkerStyled lat={deviceInfo.geo.lat} lng={deviceInfo.geo.lng} style={{ border: deviceInfo.st == 1 ? '4.5px solid #11d987' : '2.5px solid #ccc' }}>
												<div className="mapPin-wrapper" style={{ display: 'none' }}>
													<div className="mapPin">
														<h1>{deviceInfo.Label == "" ? deviceInfo.deviceName ? deviceInfo.deviceName : "Undefinded Device" : deviceInfo.Label}<small className="text-muted ml-1">({deviceInfo.Model})</small></h1>
														<p className="map-adr">{deviceInfo.geo.adr1}</p>
														<p className="map-adr">{deviceInfo.geo.adr2}</p>
														<p className="map-adr">{deviceInfo.geo.adr3}</p>
														<p className="map-ts">Last update :<strong className="ml-1 text-muted">{tsFormat(deviceInfo.geo.ts, 'auto')}</strong></p>
														<a href={"https://www.google.com/maps/search/?api=1&query=" + deviceInfo.geo.lat + "," + deviceInfo.geo.lng} target="_blank">View on Google Maps</a>
													</div>
												</div>
												<div>
													<div style={{ pointerEvents: "none" }}>
														<XR scale="0.6" color="#333" />
													</div>
												</div>
											</MarkerStyled>
										</GoogleMapReact>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<DataTable data={deviceData} lib={deviceInfo.Lib} id={id} />
					</div>

					<div className="modal fade" id="DBSelect" tabIndex="-1" role="dialog" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered modal-sm" role="document">
							<div className="modal-content">
								<div className="modal-body">
									<h4 className="modal-title text-custom-light mb-2">Select time range</h4>
									<div className="flex-fill">
										<div className="row mb-2">
											<div className="col-12 text-center">
												<button className="btn col-auto my-1 pt-0 pb-1 text-center text-custom-light px-2 rounded cursor-pointer mx-1 fixedTimeSel" style={{ background: "rgba(100,100,110,0.30)" }} onClick={(e) => { FixedTime(e, 7) }}>
													<small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>7 days</small>
												</button>
												<button className="btn col-auto my-1 pt-0 pb-1 text-center text-custom-light px-2 rounded cursor-pointer mx-1 fixedTimeSel" style={{ background: "rgba(100,100,110,0.30)" }} onClick={(e) => { FixedTime(e, 30) }}>
													<small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>1 month</small>
												</button>
												<button className="btn col-auto my-1 pt-0 pb-1 text-center text-custom-light px-2 rounded cursor-pointer mx-1 fixedTimeSel" style={{ background: "rgba(100,100,110,0.30)" }} onClick={(e) => { FixedTime(e, 90) }}>
													<small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>3 months</small>
												</button>
												<button className="btn col-auto my-1 pt-0 pb-1 text-center text-custom-light px-2 rounded cursor-pointer mx-1 fixedTimeSel" style={{ background: "rgba(100,100,110,0.30)" }} onClick={(e) => { FixedTime(e, 180) }}>
													<small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>6 months</small>
												</button>
											</div>
										</div>
										<div className="row mx-0 mb-3 flex-fill">
											<div className="col-3 col-md-auto d-flex px-0 mr-md-2" style={{ width: "75px" }}>
												<p className="my-auto py-1">From :</p>
											</div>
											<div className="col-9 col-md-auto d-flex flex-fill px-0">
												<input className="form-control-lg w-100 rounded-lg dateSel px-2" id="startReq" type="datetime-local"></input>
											</div>
										</div>
										<div className="row mx-0 mb-1 flex-fill">
											<div className="col-3 col-md-auto d-flex px-0 mr-md-2" style={{ width: "75px" }}>
												<p className="my-auto py-1">To :</p>
											</div>
											<div className="col-9 col-md-auto d-flex flex-fill px-0">
												<input className="form-control-lg w-100 rounded-lg dateSel px-2" id="endReq" type="datetime-local"></input>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer border-top border-custom-dark">
									<button type="button" className="btn btn-dark text-muted" data-dismiss="modal">Cancel</button>
									<button type="button" className="btn btn-primary" onClick={() => { request() }}>Export</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	} else if (TimeOut == false) {
		setTimeout(function () {
			setTimeOut(true)
		}, 10000)
		return (
			<>
				<div className="container-fluid p-0 loading">
					<div className="row" style={{ height: 'calc(100vh - 150px)' }}>
					</div>
				</div>
			</>
		);
	} else if (TimeOut) {
		return (
			<>
				<div className="container-fluid p-0">
					<div className="row" style={{ height: 'calc(100vh - 150px)' }}>
						<div className="my-auto mx-auto text-center">
							<h2 className="text-muted">Error</h2>
							<h1 className="text-muted" style={{ fontSize: '4.5rem' }}>524</h1>
							<h3 className="text-muted">Timeout Occurred</h3>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default Content
