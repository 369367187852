import React from 'react';
import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';

import Marker from './mark/Marker';
import ClusterMarker from './mark/ClusterMarker';

import MapInfo from './mark/mapInfo';
import MapHeat from './MapHeat';

import mapStyles from './mark/mapStyles.json';
const $ = require('jquery');
// console.log(GeoData)

export const MAP = {
  defaultZoom: 6,
  defaultCenter: { lat: 13, lng: 100.5 },
  //defaultCenter: susolvkaCoords,
  options: {
    // styles: new Date().getHours() >= 18 ? mapStyles : null,
    styles: mapStyles,
    minZoom: 3,
    maxZoom: 20,
  },
  options2: {
    // styles: new Date().getHours() >= 18 ? mapStyles : null,
    styles: mapStyles,
    minZoom: 3,
    maxZoom: 18,
  },
  optionsHeat: {
    radius: 20,
    opacity: 0.6
  },
};

async function getAllDeviceList(credentials) {
  return fetch('https://dixell.snapx.cloud/api/deviceListString', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(response => response.json())
}

export class GoogleMap extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      mapOptions: {
        center: MAP.center,
        zoom: MAP.zoom,
      },
      clusters: [],
      geoData: [],
      mapInfo: {},
      groupInfo: [],
      Refresh: false,
      Pos: {},
      heatMap: false,
      heatData: {},
    };
  }

  componentDidMount = props => {
    this.setState({ geoData: this.props.geo });
    this.setState({ Pos: this.props.pos });

    //test
    let data = {};
    let Arr = [];
    this.props.geo.forEach((v, i) => {
      let obj = { lat: v.lat, lng: v.lng }
      Arr.push(obj)
    })
    data.positions = Arr;
    data.options = MAP.optionsHeat;
    this.setState({ heatData: data });
  }

  componentDidUpdate = props => {
    if (this.state.geoData != this.props.geo) {
      this.setState({ geoData: this.props.geo })
      this.setState({ Refresh: this.props.refresh })
    }

    if (this.state.Pos != this.props.pos && this.props.refresh) {
      this.setState({ Pos: this.props.pos })
    }
    if (this.props.Refresh) {
      setTimeout(
        function () {
          this.setState({ Refresh: false })
        }
          .bind(this),
        1000
      );
    };
    if (this.state.Refresh) {
      $('.mapLoading').removeClass('d-none')
      this.setState({ Pos: this.props.pos });
    } else {
      $('.mapLoading').addClass('d-none')
    }

    this.setState({ heatMap: this.props.heat })
  }

  getClusters = props => {
    let geo = this.state.geoData;
    const clusters = supercluster(geo, {
      minZoom: 0,
      maxZoom: 20,
      radius: 150,
    });
    return clusters(this.state.mapOptions);
  };

  createClusters = props => {
    this.setState({
      clusters: this.state.mapOptions.bounds
        ? this.getClusters(props).map(({ wx, wy, numPoints, points }) => (
          {
            lat: wy,
            lng: wx,
            numPoints,
            name: `${points[0].deviceName}`,
            adr1: `${points[0].adr1}`,
            adr2: `${points[0].adr2}`,
            adr3: `${points[0].adr3}`,
            model: `${points[0].Model}`,
            id: `${points[0].id}`,
            status: `${points[0].status}`,
            alarm: points[0].alarm,
            points,
          }
        ))
        : [],
    });
  };

  handleMapChange = ({ center, zoom, bounds }) => {
    this.setState(
      {
        mapOptions: {
          center,
          zoom,
          bounds,
        },
        Pos: {
          lat: center.lat,
          lng: center.lng,
          zoom: zoom
        }, Refresh: false
      },
      () => {
        this.createClusters(this.props);
      }
    );
    let lat = MAP.defaultCenter.lat,
      lng = MAP.defaultCenter.lng,
      defZ = MAP.defaultZoom;
    if (center.lat > lat + 0.005 || center.lat < lat - 0.005 || center.lng > lng + 0.005 || center.lng < lng - 0.005 || zoom != defZ) {
      // $('.relocateBtn').removeClass('d-none')
      $('#reCenter').removeClass('d-none')
    }
  };

  setInfo = (value) => {
    this.setState({ mapInfo: value });
  }

  setDisplay = (value) => {
    this.setState({
      mapInfo: {
        display: value
      }
    });
  }


  setGroupInfo = (value) => {
    this.setState({
      groupInfo: value
    });
  }

  resetMap = () => {
    this.setState({ Pos: { lat: MAP.defaultCenter.lat, lng: MAP.defaultCenter.lng, zoom: MAP.defaultZoom } })
    // $('.relocateBtn').addClass('d-none')
    $('#reCenter').addClass('d-none')
  }



  render() {
    if (this.state.heatMap != true) {
      return (
        <>
          <GoogleMapReact
            defaultZoom={MAP.defaultZoom}
            zoom={this.state.Pos.zoom}
            defaultCenter={MAP.defaultCenter}
            center={{ lat: this.state.Pos.lat, lng: this.state.Pos.lng }}
            options={MAP.options}
            onChange={this.handleMapChange}
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
          >

            {this.state.clusters.map((item, index) => {
              if (item.numPoints === 1) {
                return (
                  <Marker
                    key={index}
                    lat={item.points[0].lat}
                    lng={item.points[0].lng}
                    name={item.points[0].label}
                    adr1={item.points[0].adr1}
                    adr2={item.points[0].adr2}
                    adr3={item.points[0].adr3}
                    model={item.points[0].model}
                    id={item.points[0].id}
                    status={item.points[0].status}
                    alarm={item.points[0].alarm}
                    info={this.setInfo}
                  />
                );
              }

              return (
                <ClusterMarker
                  key={index}
                  lat={item.lat}
                  lng={item.lng}
                  points={item.points}
                  info={this.setGroupInfo}
                />
              );
            })}

            <MapInfo
              lat={this.state.mapInfo.lat}
              lng={this.state.mapInfo.lng}
              name={this.state.mapInfo.name}
              id={this.state.mapInfo.id}
              status={this.state.mapInfo.status}
              group={this.state.mapInfo.group}
              display={this.state.mapInfo.display}
              adr1={this.state.mapInfo.adr1}
              adr2={this.state.mapInfo.adr2}
              adr3={this.state.mapInfo.adr3}
              model={this.state.mapInfo.model}
              alarm={this.state.mapInfo.alarm}
              hide={this.setDisplay}
            />
          </GoogleMapReact>

          <div className="mapLoading d-none" style={{ background: 'rgba(10,11,13,0.75)', position: 'absolute', width: 'calc(100% - 23px)', height: '100%', top: '50%', left: '50%', transform: "translate(-50%,-50%)" }}>
            <div className="h-100">
              <div className="text-center">
                <h2 className="text-muted" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                  <i className="fa fa-circle-notch fa-spin fa-2x fa-fw" style={{ opacity: "0.9" }}></i>
                </h2>
              </div>
            </div>
          </div>

          {/* <div className="px-2 py-1 rounded-pill relocateBtn text-custom-light d-none" style={{ position: 'absolute', top: 'calc(100% - 35px)', left: '50%', transform: "translate(-50%,-100%)", cursor: 'pointer' }} onClick={this.resetMap}>
            <small><i className="fa fa-repeat ml-1 mr-2 " style={{ pointerEvents: 'none' }} aria-hidden="true"></i></small>
            <span className="mr-1" style={{ pointerEvents: 'none' }} >Re-center</span>
          </div> */}

          <div id="reCenter" className="p-2 rounded btn btn-lg btn-light d-none" style={{ position: 'absolute', top: 'calc(100% - 110px)', left: 'calc(100% - 42.5px)', transform: "translate(-50%,-100%)", cursor: 'pointer' }} onClick={this.resetMap}>
            <small><i className="fa fa-lg fa-repeat mx-1" style={{ pointerEvents: 'none' }} aria-hidden="true"></i></small>
          </div>


          <div className="modal fade" id="groupInfo" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
              <div className="modal-content">
                <div className="modal-header  border-bottom border-custom-dark">
                  <h4 className="modal-title text-custom-light">Device in group</h4>
                  <div className="close text-secondary" style={{ textShadow: "none", cursor: 'pointer' }} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </div>
                </div>
                <div className="modal-body pb-3 px-2">
                  <div className="flex-fill pr-0 pl-1">
                    <div className="overflow-custom" style={{ maxHeight: 'calc(100vh - 275px)' }}>
                      {
                        this.state.groupInfo.map((item, index) => {
                          return (
                            <div className="card card-invert rounded-lg mb-2 shadow-none mr-1 " key={index} >
                              <div className="card-body pl-1 pr-1 py-2" style={{ overflowX: 'hidden' }}>
                                <div className="row w-100 m-0">
                                  <div className="col-1 p-0">
                                    <div className="row h-100">
                                      <div className="my-auto mx-auto text-center">
                                        <small className="my-auto p-2 rounded" >
                                          <i className="fa fa-circle" style={{ color: item.alarm == 1 ? "#dd263b" : item.status == 1 ? "#0fbf7c" : "#444", transform: "scale(0.7,0.7)" }}></i>
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-9 p-0">
                                    <p className="mb-1 link-custom d-inline-block rounded" onClick={() => { window.location.href = "/list/" + item.id }}>
                                      <strong className="text-custom-light">{item.label}</strong>
                                      <i className="fa fa-arrow-right ml-1" style={{ transform: "rotate(315deg)" }}></i>
                                    </p>
                                    <p className="text-muted text-wrap-custom my-0 d-flex"><small className="text-muted">({item.model})</small></p>
                                    <p className="text-muted text-wrap-custom my-0 d-flex"><small>{item.adr1}</small></p>
                                    <p className="text-muted text-wrap-custom my-0 d-flex"><small>{item.adr2}</small></p>
                                    <p className="text-muted text-wrap-custom my-0 d-flex"><small>{item.adr3}</small></p>
                                    <div className="d-inline-block">
                                      <a href={"https://www.google.com/maps/search/?api=1&query=" + item.lat + "," + item.lng} target="_blank" className="d-flex mt-1"><small><small>View on Google Maps</small></small></a>
                                    </div>
                                  </div>
                                  <div className="col-2 p-0">
                                    <div className="row h-100">
                                      <div className="my-auto mx-auto text-center">
                                        <small className="my-auto p-2 rounded link-custom" data-dismiss="modal" onClick={() => { this.props.search({ lat: item.lat, lng: item.lng }) }}>
                                          <i className="fa fa-search"></i>
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

      );
    } else {
      let heatData1 = [];
      return (
        <>
          <GoogleMapReact
            defaultZoom={MAP.defaultZoom}
            zoom={this.state.Pos.zoom}
            defaultCenter={MAP.defaultCenter}
            center={{ lat: this.state.Pos.lat, lng: this.state.Pos.lng }}
            options={MAP.options}
            onChange={this.handleMapChange}
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
          >

            {this.state.geoData.map((item, index) => {
              return (
                <MapHeat
                  key={index}
                  lat={item.lat}
                  lng={item.lng}
                  status={item.status}
                  group={item.group}
                  alarm={item.alarm}
                />
              )
            })}
          </GoogleMapReact>
          {/* 
          <div className="px-2 py-1 rounded-pill relocateBtn text-custom-light d-none" style={{ position: 'absolute', top: 'calc(100% - 35px)', left: '50%', transform: "translate(-50%,-100%)", cursor: 'pointer' }} onClick={this.resetMap}>
            <small><i className="fa fa-repeat ml-1 mr-2 " style={{ pointerEvents: 'none' }} aria-hidden="true"></i></small>
            <span className="mr-1" style={{ pointerEvents: 'none' }} >Re-center</span>
          </div> */}

          {/* <div className="p-2 rounded btn btn-lg btn-light relocateBtn2 d-none" style={{ position: 'absolute', top: 'calc(100% - 110px)', left: 'calc(100% - 42.5px)', transform: "translate(-50%,-100%)", cursor: 'pointer' }} onClick={this.resetMap}>
            <small><i className="fa fa-lg fa-repeat mx-1" style={{ pointerEvents: 'none' }} aria-hidden="true"></i></small>
          </div> */}

          <div id="reCenter" className="p-2 rounded btn btn-lg btn-light d-none" style={{ position: 'absolute', top: 'calc(100% - 110px)', left: 'calc(100% - 42.5px)', transform: "translate(-50%,-100%)", cursor: 'pointer' }} onClick={this.resetMap}>
            <small><i className="fa fa-lg fa-repeat mx-1" style={{ pointerEvents: 'none' }} aria-hidden="true"></i></small>
          </div>


        </>
      )
    }
  }
}

export default GoogleMap;