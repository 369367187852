import React from 'react';
import PropTypes from 'prop-types';

function XR(props) {
	const styles = {
		transform: `scale(${props.scale})`,
		stroke: `${props.color}`,
		// stroke:"#333",
	};

	const defaultStyle={		
		fill:"none",
		// stroke:"#333",
	}

	const st0 = {
		fill:defaultStyle.fill,
		// stroke:defaultStyle.stroke,
		strokeWidth:"20.5",
		strokeMiterlimit:"10"
	}

	const st1 = {
		fill:defaultStyle.fill,
		// stroke:defaultStyle.stroke,
		strokeWidth:"25",
		strokeMiterlimit:"10"
	}

	const st2 = {
		fill:defaultStyle.fill,
		// stroke:defaultStyle.stroke,
		strokeWidth:"20.5",
		strokeMiterlimit:"9.9997"
	}
	
	const st3 = {
		fill:defaultStyle.fill,
		// stroke:defaultStyle.stroke,
		strokeWidth:"22.5",
		strokeMiterlimit:"10"
	}
	
	const st4 = {
		fill:defaultStyle.fill,
		// stroke:defaultStyle.stroke,
		strokeWidth:"22",
		trokeMiterlimit:"10"
	}
	
	const st5 = {
		fill:defaultStyle.fill,
		// stroke:defaultStyle.stroke,
		strokeWidth:"21",
		strokeMiterlimit:"10.0004"
	}

	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 512 512"
			xmlns="http://www.w3.org/2000/svg"
			style={styles}
		>
			<g>
				<path style={st0} d="M83.56,276.22L32.8,240.68c-0.29-0.24-0.25-0.81-0.24-1.35l0.02-6.34c0.02-0.59,0.91-0.54,1.35-0.24
		l51.02,35.55c0.33,0.23,0.25,0.88,0.24,1.35v6.34C85.2,276.79,84,276.53,83.56,276.22z"/>
				<path style={st0} d="M83.56,289.44L32.8,253.89c-0.29-0.24-0.25-0.81-0.24-1.35l0.02-6.34c0.02-0.59,0.91-0.54,1.35-0.24
		l51.02,35.55c0.33,0.23,0.25,0.88,0.24,1.35v6.34C85.2,290.01,84,289.75,83.56,289.44z"/>
				<path style={st0} d="M83.56,302.66L32.8,267.11c-0.29-0.24-0.25-0.81-0.24-1.35l0.02-6.34c0.02-0.59,0.91-0.54,1.35-0.24
		l51.02,35.55c0.33,0.23,0.25,0.88,0.24,1.35v6.34C85.2,303.23,84,302.96,83.56,302.66z"/>
				<path style={st0} d="M83.56,315.87L32.8,280.33c-0.29-0.24-0.25-0.81-0.24-1.35l0.02-6.34c0.02-0.59,0.91-0.54,1.35-0.24
		l51.02,35.55c0.33,0.23,0.25,0.88,0.24,1.35v6.34C85.2,316.45,84,316.18,83.56,315.87z"/>
				<path style={st0} d="M83.56,329.09L32.8,293.55c-0.29-0.24-0.25-0.81-0.24-1.35l0.02-6.34c0.02-0.59,0.91-0.54,1.35-0.24
		l51.02,35.55c0.33,0.23,0.25,0.88,0.24,1.35v6.34C85.2,329.67,84,329.4,83.56,329.09z"/>
				<polygon style={st0} points="93.64,356.46 93.64,326.52 137.61,356.76 137.61,386.81 	" />
				<polygon style={st0} points="93.64,278.19 93.64,245.34 153.08,286.55 153.08,319.51 	" />
				<polygon style={st0} points="93.64,321 93.64,282.33 153.08,323.54 153.08,362.32 	" />
				<polyline style={st1} points="447.86,164.49 253.29,29.21 27.9,192.95 27.9,318.23 219.48,453.47 220.44,321.98 	" />
				<line style={st1} x1="219.49" y1="330.51" x2="27.9" y2="192.95" />
				<polyline style={st1} points="178.71,424.49 178.71,300.68 410.42,138.55 	" />
				<path style={st0} d="M389.57,156.11l-20.36,14.52c-0.66,0.47-0.82,1.4-0.35,2.07l8.94,6.73c0.47,0.66,1.4,0.82,2.07,0.35
		l20.36-14.52c0.66-0.47,0.82-1.4,0.35-2.07l-8.94-6.73C391.16,155.79,390.23,155.64,389.57,156.11z"/>
				<path style={st0} d="M312.16,210.97l-20.36,14.52c-0.66,0.47-0.82,1.4-0.35,2.07l8.94,6.73c0.47,0.66,1.4,0.82,2.07,0.35
		l20.36-14.52c0.66-0.47,0.82-1.4,0.35-2.07l-8.94-6.73C313.75,210.65,312.83,210.5,312.16,210.97z"/>
				<path style={st0} d="M234.76,265.83l-20.36,14.52c-0.66,0.47-0.82,1.4-0.35,2.07l8.94,6.73c0.47,0.66,1.4,0.82,2.07,0.35
		l20.36-14.52c0.66-0.47,0.82-1.4,0.35-2.07l-8.94-6.73C236.35,265.51,235.42,265.36,234.76,265.83z"/>

				<ellipse transform="matrix(0.6454 -0.7638 0.7638 0.6454 -116.955 119.2604)" style={st2} cx="69.98" cy="185.6" rx="9.03" ry="12.88" />

				<ellipse transform="matrix(0.6454 -0.7638 0.7638 0.6454 43.3266 211.5487)" style={st2} cx="249.52" cy="59.11" rx="9.03" ry="12.88" />

				<ellipse transform="matrix(0.6454 -0.7638 0.7638 0.6454 28.263 204.2779)" style={st2} cx="234.16" cy="71.7" rx="9.03" ry="12.88" />

				<ellipse transform="matrix(0.6454 -0.7638 0.7638 0.6454 13.7398 195.7748)" style={st2} cx="217.74" cy="83.09" rx="9.03" ry="12.88" />

				<ellipse transform="matrix(0.6454 -0.7638 0.7638 0.6454 -0.7834 187.2719)" style={st2} cx="201.32" cy="94.48" rx="9.03" ry="12.88" />

				<ellipse transform="matrix(0.6454 -0.7638 0.7638 0.6454 -15.2985 178.7711)" style={st2} cx="184.9" cy="105.86" rx="9.03" ry="12.88" />

				<ellipse transform="matrix(0.6454 -0.7638 0.7638 0.6454 -29.8217 170.2681)" style={st2} cx="168.48" cy="117.25" rx="9.03" ry="12.88" />

				<ellipse transform="matrix(0.6454 -0.7638 0.7638 0.6454 -44.3449 161.7651)" style={st2} cx="152.06" cy="128.65" rx="9.03" ry="12.88" />

				<ellipse transform="matrix(0.6454 -0.7638 0.7638 0.6454 -58.8681 153.2621)" style={st2} cx="135.64" cy="140.04" rx="9.03" ry="12.88" />

				<ellipse transform="matrix(0.6454 -0.7638 0.7638 0.6454 -73.3832 144.7613)" style={st2} cx="119.23" cy="151.42" rx="9.03" ry="12.88" />

				<ellipse transform="matrix(0.6454 -0.7638 0.7638 0.6454 -87.9063 136.2583)" style={st2} cx="102.81" cy="162.81" rx="9.03" ry="12.88" />

				<ellipse transform="matrix(0.6454 -0.7638 0.7638 0.6454 -102.4296 127.7552)" style={st2} cx="86.39" cy="174.2" rx="9.03" ry="12.88" />
				<path style={st3} d="M467.66,324.2L238.73,484.07c-8.06,5.69-18.29-1.15-18.29-13.55V323.98c0.81-5.14,8.76-10.33,13.7-13.7
		l230.61-157.11c5.24-3.32,16.61,0.86,16.61,10.79v137.81C481.36,309.31,476.46,318.71,467.66,324.2z"/>
				<path style={st4} d="M459.35,317.49L239.86,471.37c-6.13,3.5-9.17,0.98-9.17-9.45V331c0.69-4.41,7.52-8.86,11.75-11.75
		l223.97-155.64c4.86-3.28,4.69,3.21,4.69,17.17v117.48C471.1,304.72,466.9,312.79,459.35,317.49z"/>
				<polygon style={st1} points="278.48,419 278.48,323.1 431.38,216.37 431.38,315.36 	" />

				<ellipse transform="matrix(0.4114 -0.9115 0.9115 0.4114 66.0054 540.9088)" style={st5} cx="451.8" cy="219.35" rx="14.88" ry="10.43" />

				<ellipse transform="matrix(0.4114 -0.9115 0.9115 0.4114 -168.6016 443.1393)" style={st5} cx="258.8" cy="352.11" rx="14.88" ry="10.43" />

				<ellipse transform="matrix(0.4114 -0.9115 0.9115 0.4114 1.8672 582.3328)" style={st5} cx="451.8" cy="289.72" rx="14.88" ry="10.43" />

				<ellipse transform="matrix(0.4114 -0.9115 0.9115 0.4114 -232.7399 484.5632)" style={st5} cx="258.8" cy="422.48" rx="14.88" ry="10.43" />
			</g>
		</svg>
	);
}

XR.propTypes = {
	scale: PropTypes.string,
	color: PropTypes.string,
};

export default XR;
