import styled from 'styled-components';

const MarkerCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  max-height: 36px;
  height: 36px;
  padding: 8px;
  margin-left: -7px;
  text-align: center;
  font-size: 12px;
  font-weight:bold;
  color: #333;
  border: 2px solid #ddd;
  background-color: rgba(255,255,255,1);
  border-radius: 50%;
  cursor:pointer;
`;

export default MarkerCounter;
