import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';
import { findByRole } from '@testing-library/dom';
const $ = require('jquery');

export const checkActiveDI = (num) => {
    document.getElementById('DIChart-wrapper').setAttribute('style', 'height:' + (num > 1 ? num * 100 : 109) + 'px');
    ApexCharts.exec('DIChart', 'updateOptions', {
        yaxis: {
            labels: {
                show: true,
                minWidth: 32.5,
                style: {
                    colors: 'rgba(255,255,255,0.5)',
                    fontSize: '11px',
                    fontWeight: 400,
                },
                formatter: (val) => { return val % 2 == 1 ? "On" : "Off" },
            },
            max: num * 2 - 1,
            tickAmount: num * 2 - 1
        }
    }, false, true);
    if (window.matchMedia('(min-width: 768px)').matches) {
        $('.dataSel-wrapper').css(
            {
                maxHeight: (num > 1 ? (545 + (num * 45) + 'px') : (545 + 'px'))
            }
        )
    }
}

// const chartDataDI = testDI.map((val, index) => {
//     val = {
//         name: "test" + index,
//         data: val.map((v) => {
//             // let test = [];
//             // test[0] = index += 1;
//             // test[1] = v[1] = v[1] + (index * 2);
//             // return test;
//             return v[1] = v[1] + (index * 2)
//         })
//     };
//     return val;
// })

function ApexChart(props) {

    useEffect(() => {
        if (props.data.AI) {
            if (Object.keys(props.data.AI.series).length > 0) {
                for (let i = 1; i < props.data.AI.series.length; i++) {
                    ApexCharts.exec(props.data.AI.options.chart.id, 'hideSeries', props.data.AI.series[i].name);
                }
                // ApexCharts.exec(props.data.AI.options.chart.id, 'updateOptions', {}, false, true);
                $('#chartLoading').removeAttr('style');
                ApexCharts.exec(props.data.AI.options.chart.id, 'updateOptions', props.data.AI.options, false, true);
                ApexCharts.exec(props.data.DI.options.chart.id, 'updateOptions', props.data.DI.options, false, true);
                ApexCharts.exec(props.data.AI.options.chart.id, 'updateSeries ', props.data.AI.series, true);
                ApexCharts.exec(props.data.DI.options.chart.id, 'updateSeries ', props.data.DI.series, true);
            }
            // checkActiveDI()

        }

        if (props.data.DI && props.data.DI.series.length > 0) {
            checkActiveDI(props.data.DI.series.length)
        }

        // if (props.data.DI) {
        //     if (Object.keys(props.data.DI.series).length > 0) {
        //         for (let i = 1; i < props.data.DI.series.length; i++) {
        //             ApexCharts.exec(props.data.DI.options.chart.id, 'hideSeries', props.data.DI.series[i].name);
        //         }
        //         ApexCharts.exec(props.data.DI.options.chart.id, 'updateOptions', props.data.DI.options, false, true);
        //         $('#chartLoading').removeAttr('style');
        //     }
        // }

    }, [props.data])


    if (Object.keys(props.data).length > 0) {
        return (
            <>
                <div id="chartLoading" style={{ visibility: "hidden" }}>
                    {props.data.AI.series.length > 0 ?
                        <div id="AIChart-wrapper">
                            <Chart
                                options={props.data.AI.options}
                                series={props.data.AI.series}
                                type="area"
                                height="100%"
                            />
                        </div>
                        :
                        <></>
                    }
                    {/* <div id="DIChart-wrapper" style={{ height: "110px" }}> */}
                    {
                        props.data.DI.series.length > 0 ?
                            <div id="DIChart-wrapper" style={{ height: "300px" }}>
                                <Chart
                                    options={props.data.DI.options}
                                    series={props.data.DI.series}
                                    type="area"
                                    height="100%"
                                />
                            </div>
                            :
                            <></>
                    }
                    {/* <div id="DIChart-wrapper" style={{ height: "300px" }}>
                        <Chart
                            options={props.data.DI.options}
                            series={props.data.DI.series}
                            type="area"
                            height="100%"
                        />
                    </div> */}
                </div>
            </>
        )
    }
    else if ($('#startReq').length > 0 && $('#startReq')[0].valueAsNumber && $('#endReq').length > 0 && $('#endReq')[0].valueAsNumber && $('#devReq').length > 0 && $('#devReq')[0].value != $('#devReq')[0][0].innerText) {
        return (
            <>
                <div id="AIChart-wrapper">
                    <div className="row loading" style={{ height: '100%' }}>
                    </div>
                </div>
            </>
        )
    }
    else {
        return (
            <>
                <div id="AIChart-wrapper">
                    <div className="row" style={{ height: '100%' }}>
                        <div className="my-auto mx-auto text-center">
                            <h2 className="text-muted">Please, Select Data...</h2>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default ApexChart