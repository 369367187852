import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, HashRouter } from 'react-router-dom';
import '../src/components/app/css/app.css'
import '../src/components/app/css/custom.css'


import Menu from './components/menu/menu'
import Topnav from './components/menu/topnav'
import Footer from './components/menu/footer'

// Device
import ForgetPass from './forgetPass';

import DeviceOverview from './components/device/overview';
import DeviceList from './components/device/list';
import DeviceChart from './components/device/chart';
import DeviceMap from './components/device/map';
import DeviceInfo from './components/device/info';

import SystemAlarm from './components/system/alarm';

import AccInfo from './components/account/profile';
import AccSubscription from './components/account/subscription';
import AccSetting from './components/account/setting';

import App from './App'

function Routes() {    
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={App} />
                <Route path="/overview" component={DeviceOverview} />
                <Route exact path="/list" component={DeviceList} />
                <Route path="/chart" component={DeviceChart} />
                <Route path="/map" component={DeviceMap} />
                <Route path="/alarm" component={SystemAlarm} />
                <Route path="/list/:id" component={DeviceInfo} />
                <Route path="/FYI" component={ForgetPass} />

                <Route path="/account" component={AccInfo} />
                <Route path="/subscription" component={AccSubscription} />
                <Route path="/setting" component={AccSetting} />
            </Switch>
        </Router>
    );
}

export default Routes;