import React from 'react'

function Footer() {
    return (
        <>
			<footer className="footer bg-transparent">
				<div className="container-fluid">
					<div className="row text-muted">
						<div className="col-sm-6 text-left">
							<p className="mb-0">
								<small>&copy; 2021 Dixell(Asia) Co.,Ltd. All Rights
									Reserved.</small>
							</p>
						</div>
						<div className="col-sm-6 d-none d-sm-inline-block text-sm-right text-left">
							<p className="mb-0">
								<small><i className="fa fa-phone fa-flip-horizontal mr-1" aria-hidden="true"></i>Tel : +66 2017 6900 | +669 5885 4647 <span className="d-none d-md-inline">(24 Hours)</span></small>
							</p>
							{/* <ul className="list-inline">
								<li className="list-inline-item mt-2 mt-sm-0 mx-2">
									<small><a className="text-muted" href="#">Support</a></small>
								</li>
								<small className="text-muted">|</small>
								<li className="list-inline-item mt-2 mt-sm-0 mx-2">
									<small><a className="text-muted" href="#">Privacy</a></small>
								</li>
								<small className="text-muted">|</small>
								<li className="list-inline-item mt-2 mt-sm-0 mx-2">
									<small><a className="text-muted" href="#">Terms</a></small>
								</li>
							</ul> */}
						</div>
					</div>
				</div>
			</footer>
        </>
    )
}

export default Footer
