import React, { useEffect } from 'react'
import Menu from '../../menu/menu'
import Topnav from '../../menu/topnav'
import Footer from '../../menu/footer'
import Content from './content'

function App() {

	useEffect(() => {
		// 	document.title = "Device List | Dixell IOT"
		document.title = "Alarm History | SnapX"
	}, [])

	if (localStorage.getItem("user")) {
		return (
			<div className="wrapper">
				<Menu active={4} />
				<div className="main">
					<Topnav />
					<main className="content">
						<Content />
					</main>
					<Footer />
				</div>
			</div>
		)
	}
	else {
		window.location.replace("/");
	}
}

export default App
