import React, { useState, useEffect } from 'react'
import ApexChart, { checkActiveDI } from "./chart_apex";
import ApexCharts from 'apexcharts';
import fire from '../../firebase'
import { TimeSeriesDataFormat } from "../../../customLibs/dataFormat";
import { tsFormat } from '../../../customLibs/timeStampFormat';

const $ = require('jquery');

async function getTimeSeries(credentials) {
    return fetch('https://dixell.snapx.cloud/api/allTimeSeriesByID', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(response => response.json())
}

async function getDeviceAttr(credentials) {
    return fetch('https://dixell.snapx.cloud/api/deviceByIDSQL', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(response => response.json())
}

async function getAllDeviceList(credentials) {
    return fetch('https://dixell.snapx.cloud/api/deviceListString', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(response => response.json())
}

function Content(id) {
    const [dataReal, setData] = useState([]);
    const [dataChart, setDataChart] = useState({});
    const [DeviceList, setDeviceList] = useState([]);
    const [RequestData, setRequestData] = useState({})

    useEffect(async () => {
        await getAllDeviceList(
            {
                user: localStorage.getItem("user")
            }
        )
            .then(
                (result) => {
                    let data = Object.assign([], result.map);
                    for (let i = 0; i < data.length; i++) {
                        delete data[i].adr;
                        delete data[i].lat;
                        delete data[i].lng;
                        delete data[i].status;
                        delete data[i].model;
                    }
                    // console.log(data)
                    setDeviceList(data)
                }
            )
    }, []);

    useEffect(() => {
        const init = () => {
            if (Object.keys(dataReal.length > 0)) {
                document.getElementsByTagName('main')[0].classList.remove('loading');
                document.getElementsByClassName('waitload')[0].removeAttribute('style');
                // console.log(id.data)
                if (id.data) {
                    setTimeout(function () {
                        let local = Date.now();
                        let timeOffset = new Date().getTimezoneOffset() * 60 * 1000 * -1;
                        let newEndDate = local + timeOffset;
                        let newStartDate = new Date(newEndDate);
                        newStartDate.setDate(newStartDate.getDate() - 7); //7day

                        $('#startReq')[0].valueAsNumber = newStartDate -= newStartDate % 60000;
                        $('#endReq')[0].valueAsNumber = newEndDate -= newEndDate % 60000;
                        // $('#sampReq')[0].valueAsNumber =  Math.floor(((newEndDate -= newEndDate % 60000) - (newStartDate -= newStartDate % 60000)) /672)
                        $('#devReq')[0].value = id.data;
                        setChartData()
                    }, 250)
                }
                clearInterval(retry)
            }
        }
        const retry = setInterval(init, 500);
    }, []);

    async function request() {

        if (!$('#startReq')[0].valueAsNumber && !$('#endReq')[0].valueAsNumber && $('#devReq')[0].value == $('#devReq')[0][0].innerText) {

            alert("No Device and Time range Selected.")

        } else if ($('#startReq')[0].valueAsNumber && !$('#endReq')[0].valueAsNumber && $('#devReq')[0].value == $('#devReq')[0][0].innerText) {

            alert("No Device and End Time Selected.")

        } else if (!$('#startReq')[0].valueAsNumber && $('#endReq')[0].valueAsNumber && $('#devReq')[0].value == $('#devReq')[0][0].innerText) {

            alert("No Device and Start Time Selected.")

        } else if (!$('#startReq')[0].valueAsNumber && !$('#endReq')[0].valueAsNumber && $('#devReq')[0].value != $('#devReq')[0][0].innerText) {

            alert("No Time range Selected.")

        } else if (!$('#startReq')[0].valueAsNumber && $('#endReq')[0].valueAsNumber && $('#devReq')[0].value != $('#devReq')[0][0].innerText) {

            alert("No Start Time Selected.")

        } else if ($('#startReq')[0].valueAsNumber && !$('#endReq')[0].valueAsNumber && $('#devReq')[0].value != $('#devReq')[0][0].innerText) {

            alert("No End Time Selected.")

        } else if ($('#startReq')[0].valueAsNumber && $('#endReq')[0].valueAsNumber && $('#devReq')[0].value == $('#devReq')[0][0].innerText) {

            alert("No Device Selected.")

        } else if ($('#startReq')[0].valueAsNumber > $('#endReq')[0].valueAsNumber) {

            alert("Plase Select Time Range Correctly.")

            // } else if ($('#sampReq')[0].valueAsNumber <= 0) {

            //     alert("Plase fill Sampling time Correctly.")

            // } else if (Number.isNaN($('#sampReq')[0].valueAsNumber)) {

            //     alert("Plase fill Sampling time")

        } else if ($('#endReq')[0].valueAsNumber - $('#startReq')[0].valueAsNumber < 300000) {

            alert("Time range must be atleast 5 minutes minimum.")

        } else {
            setChartData()
        }
    }

    const getRandomColorHLS = () => {
        return "hsl(" + Math.floor(Math.random() * (255 - 0) + 0) + "," + Math.floor(Math.random() * (90 - 10) + 10) + "%," + Math.floor(Math.random() * (70 - 40) + 40) + "%)";
    }

    const setChartData = async () => {

        // $('#chart').addClass('loading')

        let device = $('#devReq')[0].value,
            start = $('#startReq')[0].valueAsNumber,
            end = $('#endReq')[0].valueAsNumber,
            // samp = $('#sampReq')[0].valueAsNumber * 60000,
            lib = await getDeviceAttr({ owner: localStorage.user, id: device }).then(
                (result) => {
                    return (Object.assign({}, JSON.parse(result.lib)));
                }
            )
        let samp = Math.floor((end - start) / 672)

        if (samp < 300000) {
            samp = 300000
        }

        let timeOffset = new Date().getTimezoneOffset() * 60 * 1000 * -1;
        start = start - timeOffset;
        end = end - timeOffset;

        await getTimeSeries({ id: device, timeframe: samp, start: start, end: end }).then(
            (result) => {
                let csv = TimeSeriesDataFormat(result, lib);
                let data = Object.assign({}, csv)
                let Arr = [];

                for (const [i, v] of Object.entries(data.DI.Series)) {
                    if (v.name == "Cooling" || v.name == "Fan" || (v.name == "Defrost" && v.key == "os")) {
                        Arr.push(v)
                        // checkDI[v.name] = true
                    }
                }
                for (const [index, value] of Arr.entries()) {
                    let d = []
                    for (let v of value.data) {
                        v ? d.push((index * 2) + 1) : d.push(index * 2)
                    }
                    value.data = d;
                }
                data.DI.Series = Arr;
                setChart(data, csv)
                setRequestData(csv)
            }
        );
    }

    const capture = (e) => {
        var c = document.getElementById('apexchartsAIChart');
        var t = c.getContext('2d');
        window.open('', t.toDataURL());
        console.log(c)
        // alert('x')
    }

    const setChart = async (chartData) => {
        const colorArr = (type) => {
            let Arr = [];
            if (type == "AI") {
                Arr = ["#33B2DF", "#546E7A", "#D4526E", "#13D8AA", "#A5978B"];
                for (let i = 5; i < chartData.AI.Series.length; i++) {
                    Arr.push(getRandomColorHLS());
                }
                return Arr
            }
            else if (type == "DI") {
                Arr = ["#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7"];
                for (let i = 5; i < chartData.DI.Series.length; i++) {
                    Arr.push(getRandomColorHLS());
                }
                return Arr
            }
        }

        const chartConfig = {
            AI: {
                series: chartData.AI ? chartData.AI.Series : [],
                options: {
                    chart: {
                        id: "AIChart",
                        type: 'area',
                        group: "SyncChart",
                        offsetX: 0,
                        offsetY: -10,
                        zoom: {
                            type: 'x',
                            enabled: true,
                            autoScaleYaxis: true
                        },
                        toolbar: {
                            autoSelected: 'zoom',
                            show: true,
                            offsetX: 25,
                            offsetY: -35,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: '<i class="fa fa-search"></i>',
                                zoomin: '<i class="fa fa-search-plus"></i>',
                                zoomout: '<i class="fa fa-search-minus" ></i>',
                                pan: '<i class="fa fa-hand-paper"></i>',
                                reset: '<i class="fa fa-repeat ml-1"></i>',
                                // customIcons: [{
                                //     icon: '<i class="fa fa-download ml-2"></i>',
                                //     index: 4,
                                //     title: 'Download CSV',
                                //     class: 'custom-icon',
                                //     click: (e) => { capture(e) }
                                // }],
                                // zoom: '',
                                // zoomin: '',
                                // zoomout: '',
                                // pan: '',
                                // reset: '',
                            },
                            export: {
                                csv: {
                                    filename: 'SnapX_Data',
                                    columnDelimiter: ',',
                                    headerCategory: 'Timestamp',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return new Date(timestamp).toDateString()
                                    }
                                },
                                svg: {
                                    filename: 'SnapX_Chart',
                                },
                                png: {
                                    filename: "SnapX_Chart",
                                }
                            },
                        },
                        background: '#2d3036',
                        animations: {
                            enabled: false,
                            easing: 'easeinout',
                            speed: 800,
                            animateGradually: {
                                enabled: false,
                                delay: 100
                            },
                            dynamicAnimation: {
                                enabled: false,
                                speed: 350
                            }
                        },
                    },
                    colors: colorArr('AI'),
                    stroke: {
                        show: true,
                        curve: 'straight',
                        lineCap: 'round',
                        width: 1.5,
                        dashArray: 0,
                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 0,
                        strokeWidth: 0,
                        fillOpacity: 0.75,
                        hover: {
                            size: 0,
                        }
                    },
                    legend: {
                        show: false,
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            inverseColors: false,
                            gradientToColors: ['rgba(44, 47, 53,0)'],
                            opacityFrom: 0.4,
                            opacityTo: 0,
                            stops: [20, 100]
                        },
                    },
                    yaxis: {
                        showForNullSeries: false,
                        labels: {
                            show: true,
                            hideOverlappingLabels: true,
                            showDuplicates: false,
                            trim: false,
                            minWidth: 35,
                            style: {
                                colors: 'rgba(255,255,255,0.5)',
                                fontSize: '11px',
                                fontWeight: 400,
                            },
                            formatter: (value) => { return value.toFixed(1) },
                        },

                        forceNiceScale: true,
                        // title: {
                        //     text: "Temperature (°C)",
                        //     rotate: -90,
                        //     offsetX: -5,
                        //     offsetY: 0,
                        //     style: {
                        //         color: "rgba(255,255,255,0.5)",
                        //         fontSize: '12px',
                        //         fontWeight: 400,
                        //     },
                        // },
                        // min: 0,
                        // max: 100,
                        // tickAmount: 4,
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: chartData.timeStamp ? chartData.timeStamp : [],
                        // categories: DataAI.timeStamp,
                        tooltip: {
                            enabled: false,
                        },
                        labels: {
                            show: true,
                            hideOverlappingLabels: true,
                            showDuplicates: false,
                            trim: true,
                            style: {
                                colors: 'rgba(255,255,255,0.5)',
                                fontSize: '11px',
                                fontWeight: 400,
                            },
                            // format: 'dd MMM - hh:mm TT',
                            // format: 'dd MMM yyyy',
                            formatter: undefined,
                            datetimeUTC: false,
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: "MMM 'yy",
                                day: 'dd MMM',
                                hour: 'HH:mm',
                            },
                        },
                        axisTicks: {
                            show: false,
                            color: 'rgba(255, 255, 255, 0.2)',
                            height: 5,
                        },
                        axisBorder: {
                            show: false
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: 'rgba(255, 255, 255, 0.05)',
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },
                    theme: {
                        mode: 'dark',
                    },
                    tooltip: {
                        enabled: true,
                        style: {
                            fontSize: '12px'
                        },
                        x: {
                            show: true,
                            format: 'dd MMM - hh:mm TT',
                            formatter: undefined,
                        },
                        y: {
                            formatter: function (y, obj) {
                                if (typeof y !== "undefined") {
                                    return y + " " + chartData.AI.Series[obj.seriesIndex].unit;
                                }
                                return y;
                            },
                            title: {
                                formatter: (seriesName) => seriesName + " : ",
                            },
                        },
                    },
                    annotations: {
                        yaxis: [
                            {
                                y: 0,
                                strokeDashArray: 0,
                                borderColor: 'rgba(255, 255, 255, 0.05)',
                                borderWidth: 1.5,
                                opacity: 1
                            }
                        ]
                    }
                },
            },
            DI: {
                series: chartData.DI ? chartData.DI.Series : [],
                options: {
                    chart: {
                        id: "DIChart",
                        type: 'area',
                        group: "SyncChart",
                        redrawOnParentResize: true,
                        toolbar: {
                            autoSelected: 'zoom',
                            show: true,
                            offsetX: 10,
                            offsetY: -10,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: false,
                                zoomin: '',
                                zoomout: '',
                                pan: '',
                                reset: '',
                            },
                            export: {
                                csv: {
                                    filename: 'SnapX_Data',
                                    columnDelimiter: ',',
                                    headerCategory: 'Timestamp',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return new Date(timestamp).toDateString()
                                    }
                                },
                                svg: {
                                    filename: 'SnapX_Chart',
                                },
                                png: {
                                    filename: "SnapX_Chart",
                                }
                            },
                        },
                        background: '#2d3036',
                        animations: {
                            enabled: false,
                            easing: 'easeinout',
                            speed: 800,
                            animateGradually: {
                                enabled: false,
                                delay: 100
                            },
                            dynamicAnimation: {
                                enabled: false,
                                speed: 350
                            }
                        },
                    },
                    colors: colorArr('DI'),
                    stroke: {
                        curve: 'stepline',
                        lineCap: 'round',
                        width: 1.5,
                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 0,
                        hover: {
                            size: 0,
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            gradientToColors: ['rgba(44, 47, 53,0)'],
                            inverseColors: false,
                            opacityFrom: 0.4,
                            opacityTo: 0,
                            stops: [20, 100]
                        },
                    },
                    legend: {
                        show: false,
                    },
                    yaxis: {
                        showForNullSeries: false,
                        labels: {
                            show: true,
                            minWidth: 35,
                            style: {
                                colors: 'rgba(255,255,255,0.5)',
                                fontSize: '11px',
                                fontWeight: 400,
                            },
                            formatter: (val) => { return val % 2 == 1 ? "On" : "Off" },
                        },
                        // min: 0,
                        // max: 1,
                        // tickAmount: 1
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: chartData.timeStamp ? chartData.timeStamp : [],
                        tooltip: {
                            enabled: false,
                        },
                        labels: {
                            show: true,
                            hideOverlappingLabels: true,
                            showDuplicates: false,
                            trim: true,
                            style: {
                                colors: 'rgba(255,255,255,0.5)',
                                fontSize: '11px',
                                fontWeight: 400,
                            },
                            format: 'dd MMM yyyy',
                            formatter: undefined,
                            datetimeUTC: false,
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: "MMM 'yy",
                                day: 'dd MMM',
                                hour: 'HH:mm',
                            },
                        },
                        axisTicks: {
                            show: false,
                            color: 'rgba(255, 255, 255, 0.2)',
                            height: 5,
                        },
                        axisBorder: {
                            show: false
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: 'rgba(255, 255, 255, 0.05)',
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                        yaxis: {
                            lines: {
                                show: false
                            }
                        },
                    },
                    theme: {
                        mode: 'dark',
                    },
                    tooltip: {
                        enabled: true,
                        inverseOrder: true,
                        style: {
                            fontSize: '12px'
                        },
                        x: {
                            show: false,
                        },
                        y: {
                            title: {
                                formatter: (seriesName) => seriesName + " :",
                            },
                        },
                    },
                    annotations: {
                        yaxis: [
                            {
                                y: 0,
                                strokeDashArray: 0,
                                borderColor: 'rgba(255, 255, 255, 0.05)',
                                borderWidth: 1.5,
                                opacity: 1
                            }
                        ]
                    }
                }
            }
        };

        setDataChart(chartConfig)

        ApexCharts.exec('AIChart', 'updateOptions', chartConfig.AI, false, true);
        ApexCharts.exec('DIChart', 'updateOptions', chartConfig.DI, false, true);

        $('#chartLoading').attr('style', 'visibility:hidden');
        if ($('.chartLegend.DI-legend')[0]) {
            $('.chartLegend.DI-legend')[0].classList.remove('chartLegend-collapse')
        }
        if ($('.chartLegend.DI-legend')[1]) {
            $('.chartLegend.DI-legend')[1].classList.remove('chartLegend-collapse')
        }
        if ($('.chartLegend.DI-legend')[2]) {
            $('.chartLegend.DI-legend')[2].classList.remove('chartLegend-collapse')
        }

        for (let i = 1; i < $('.chartLegend.AI-legend').length; i++) {
            $('.chartLegend.AI-legend')[i].classList.add('chartLegend-collapse')
        }
        for (let i = 1; i < $('.AI-sel-group')[0].children.length; i++) {
            $('.AI-sel-group')[0].children[i].children[1].classList.remove('active');
            $('.AI-sel-group')[0].children[i].children[1].children[0].innerText = "Select"
        }

    }

    const exportDBCSV = () => {
        let data = RequestData
        if (Object.keys(data).length > 0) {
            let selDevice = $('#devReq')[0].selectedOptions[0].innerText
            let header, rowLength, datainputType, values, output
            header = {
                'timeStamp': null
            }
            rowLength = data['timeStamp'].length;
            datainputType = [];
            values = []

            for (let i in data) {
                if (Array.isArray(data[i]) == false) {
                    datainputType.push(i)
                }
            }

            for (let i = 0; i < rowLength; i++) {
                let ts = tsFormat(data['timeStamp'][i], 'long')
                values.push(ts)
                for (let j in datainputType) {
                    for (let k in data[datainputType[j]].Series) {
                        let unit = data[datainputType[j]].Series[k].unit
                        unit = unit ? "(" + unit.replace("°", "") + ")" : ""
                        header[data[datainputType[j]].Series[k].name + "_" + data[datainputType[j]].Series[k].key + unit] = null
                        if (datainputType[j] === "DI") {
                            values.push(data[datainputType[j]].Series[k].data[i] % 2)
                        } else {
                            values.push(data[datainputType[j]].Series[k].data[i])
                        }
                    }
                }
            }
            output = ""
            var i, j, temparray, chunk = Object.keys(header).length;
            for (let i = 0, j = values.length; i <= j; i += chunk) {
                output += values.slice(i, i + chunk).join(",") + "\n"
            }
            data = Object.keys(header).join(",") + "\n" + output

            var link = document.createElement("a");
            link.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
            link.download = "SnapX_" + selDevice + ".csv";
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
        } else {
            alert("No Data Selected")
        }
    }

    // console.log(JSON.parse(deviceAttr.lib))

    // let DataAI = await formatData.singleTimeSeries(device, fam, start, end, "ai");
    // let DataDS = await formatData.singleTimeSeries(device, fam, start, end, "os");


    // // let DataALL = await formatData.allTimeSeries(device, fam, start, end);
    // const DataALL = await formatData.allTimeSeries(device, fam, start, end);
    // // console.log(DataALL)

    // let length = DataALL.DI.Series.length;
    // for (let i = 3; i < length; i++) {
    //     DataALL.DI.Series.pop()
    // }

    // console.log(DataAI)
    // console.log(DataDS)


    // const getRandomColorHLS = () => {
    //     return "hsl(" + Math.floor(Math.random() * (255 - 0) + 0) + "," + Math.floor(Math.random() * (90 - 10) + 10) + "%," + Math.floor(Math.random() * (70 - 40) + 40) + "%)";
    // }

    // const colorArr = (type) => {
    //     let Arr = [];
    //     if (type == "AI") {
    //         Arr = ["#33B2DF", "#546E7A", "#D4526E", "#13D8AA", "#A5978B"];
    //         for (let i = 5; i < DataALL.AI.Series.length; i++) {
    //             Arr.push(getRandomColorHLS());
    //         }
    //         return Arr
    //     }
    //     else if (type == "DI") {
    //         Arr = ["#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7"];
    //         for (let i = 5; i < DataALL.DI.Series.length; i++) {
    //             Arr.push(getRandomColorHLS());
    //         }
    //         return Arr
    //     }
    // }

    // const chartConfig = {
    //     AI: {
    //         series: DataALL.AI ? DataALL.AI.Series : [],
    //         // series: DataAI.Series,
    //         options: {
    //             chart: {
    //                 id: "AIChart",
    //                 type: 'area',
    //                 group: "SyncChart",
    //                 offsetX: 0,
    //                 offsetY: -10,
    //                 zoom: {
    //                     type: 'x',
    //                     enabled: true,
    //                     autoScaleYaxis: true
    //                 },
    //                 toolbar: {
    //                     autoSelected: 'zoom',
    //                     show: true,
    //                     offsetX: 25,
    //                     offsetY: -35,
    //                     tools: {
    //                         download: '<i class="fa fa-download ml-2"></i>',
    //                         selection: false,
    //                         zoom: '<i class="fa fa-search"></i>',
    //                         zoomin: '<i class="fa fa-search-plus"></i>',
    //                         zoomout: '<i class="fa fa-search-minus" ></i>',
    //                         pan: '<i class="fa fa-hand-paper"></i>',
    //                         reset: '<i class="fa fa-repeat ml-1"></i>',
    //                         // zoom: '',
    //                         // zoomin: '',
    //                         // zoomout: '',
    //                         // pan: '',
    //                         // reset: '',
    //                     },
    //                     export: {
    //                         csv: {
    //                             filename: 'SnapX_Data',
    //                             columnDelimiter: ',',
    //                             headerCategory: 'Timestamp',
    //                             headerValue: 'value',
    //                             dateFormatter(timestamp) {
    //                                 return new Date(timestamp).toDateString()
    //                             }
    //                         },
    //                         svg: {
    //                             filename: 'SnapX_Chart',
    //                         },
    //                         png: {
    //                             filename: "SnapX_Chart",
    //                         }
    //                     },
    //                 },
    //                 background: '#2d3036',
    //                 animations: {
    //                     enabled: false,
    //                     easing: 'easeinout',
    //                     speed: 800,
    //                     animateGradually: {
    //                         enabled: false,
    //                         delay: 100
    //                     },
    //                     dynamicAnimation: {
    //                         enabled: false,
    //                         speed: 350
    //                     }
    //                 },
    //             },
    //             colors: colorArr('AI'),
    //             stroke: {
    //                 show: true,
    //                 curve: 'straight',
    //                 lineCap: 'round',
    //                 width: 1.5,
    //                 dashArray: 0,
    //             },
    //             dataLabels: {
    //                 enabled: false
    //             },
    //             markers: {
    //                 size: 0,
    //                 strokeWidth: 0,
    //                 fillOpacity: 0.75,
    //                 hover: {
    //                     size: 0,
    //                 }
    //             },
    //             legend: {
    //                 show: false,
    //             },
    //             fill: {
    //                 type: 'gradient',
    //                 gradient: {
    //                     inverseColors: false,
    //                     gradientToColors: ['rgba(44, 47, 53,0)'],
    //                     opacityFrom: 0.4,
    //                     opacityTo: 0,
    //                     stops: [20, 100]
    //                 },
    //             },
    //             yaxis: {
    //                 showForNullSeries: false,
    //                 labels: {
    //                     show: true,
    //                     hideOverlappingLabels: true,
    //                     showDuplicates: false,
    //                     trim: false,
    //                     minWidth: 35,
    //                     style: {
    //                         colors: 'rgba(255,255,255,0.5)',
    //                         fontSize: '11px',
    //                         fontWeight: 400,
    //                     },
    //                     formatter: (value) => { return value },
    //                 },
    //                 // title: {
    //                 //     text: "Temperature (°C)",
    //                 //     rotate: -90,
    //                 //     offsetX: -5,
    //                 //     offsetY: 0,
    //                 //     style: {
    //                 //         color: "rgba(255,255,255,0.5)",
    //                 //         fontSize: '12px',
    //                 //         fontWeight: 400,
    //                 //     },
    //                 // },
    //                 // min: 0,
    //                 // max: 100,
    //                 // tickAmount: 4,
    //             },
    //             xaxis: {
    //                 type: 'datetime',
    //                 categories: DataALL.timeStamp ? DataALL.timeStamp : [],
    //                 // categories: DataAI.timeStamp,
    //                 tooltip: {
    //                     enabled: false,
    //                 },
    //                 labels: {
    //                     show: true,
    //                     hideOverlappingLabels: true,
    //                     showDuplicates: false,
    //                     trim: true,
    //                     style: {
    //                         colors: 'rgba(255,255,255,0.5)',
    //                         fontSize: '11px',
    //                         fontWeight: 400,
    //                     },
    //                     // format: 'dd MMM - hh:mm TT',
    //                     format: 'dd MMM yyyy',
    //                     formatter: undefined,
    //                     datetimeUTC: false,
    //                     datetimeFormatter: {
    //                         year: 'yyyy',
    //                         month: "MMM 'yy",
    //                         day: 'dd MMM',
    //                         hour: 'HH:mm',
    //                     },
    //                 },
    //                 axisTicks: {
    //                     show: false,
    //                     color: 'rgba(255, 255, 255, 0.2)',
    //                     height: 5,
    //                 },
    //                 axisBorder: {
    //                     show: false
    //                 }
    //             },
    //             grid: {
    //                 show: true,
    //                 borderColor: 'rgba(255, 255, 255, 0.05)',
    //                 xaxis: {
    //                     lines: {
    //                         show: true
    //                     }
    //                 },
    //                 yaxis: {
    //                     lines: {
    //                         show: true
    //                     }
    //                 },
    //             },
    //             theme: {
    //                 mode: 'dark',
    //             },
    //             tooltip: {
    //                 enabled: true,
    //                 style: {
    //                     fontSize: '12px'
    //                 },
    //                 x: {
    //                     show: true,
    //                     format: 'dd MMM - hh:mm TT',
    //                     formatter: undefined,
    //                 },
    //                 y: {
    //                     formatter: function (y) {
    //                         if (typeof y !== "undefined") {
    //                             return y + " °C";
    //                         }
    //                         return y;
    //                     },
    //                     title: {
    //                         formatter: (seriesName) => seriesName + " : ",
    //                     },
    //                 },
    //             },
    //             annotations: {
    //                 yaxis: [
    //                     {
    //                         y: 0,
    //                         strokeDashArray: 0,
    //                         borderColor: 'rgba(255, 255, 255, 0.05)',
    //                         borderWidth: 1.5,
    //                         opacity: 1
    //                     }
    //                 ]
    //             }
    //         },
    //     },
    //     DI: {
    //         series: DataALL.DI ? DataALL.DI.Series : [],
    //         options: {
    //             chart: {
    //                 id: "DIChart",
    //                 type: 'area',
    //                 group: "SyncChart",
    //                 redrawOnParentResize: true,
    //                 toolbar: {
    //                     autoSelected: 'zoom',
    //                     show: true,
    //                     offsetX: 10,
    //                     offsetY: -10,
    //                     tools: {
    //                         download: '<i class="fa fa-download ml-2"></i>',
    //                         selection: false,
    //                         zoom: false,
    //                         zoomin: '',
    //                         zoomout: '',
    //                         pan: '',
    //                         reset: '',
    //                     },
    //                     export: {
    //                         csv: {
    //                             filename: 'SnapX_Data',
    //                             columnDelimiter: ',',
    //                             headerCategory: 'Timestamp',
    //                             headerValue: 'value',
    //                             dateFormatter(timestamp) {
    //                                 return new Date(timestamp).toDateString()
    //                             }
    //                         },
    //                         svg: {
    //                             filename: 'SnapX_Chart',
    //                         },
    //                         png: {
    //                             filename: "SnapX_Chart",
    //                         }
    //                     },
    //                 },
    //                 background: '#2d3036',
    //                 animations: {
    //                     enabled: false,
    //                     easing: 'easeinout',
    //                     speed: 800,
    //                     animateGradually: {
    //                         enabled: false,
    //                         delay: 100
    //                     },
    //                     dynamicAnimation: {
    //                         enabled: false,
    //                         speed: 350
    //                     }
    //                 },
    //             },
    //             colors: colorArr('DI'),
    //             stroke: {
    //                 curve: 'stepline',
    //                 lineCap: 'round',
    //                 width: 1.5,
    //             },
    //             dataLabels: {
    //                 enabled: false
    //             },
    //             markers: {
    //                 size: 0,
    //                 hover: {
    //                     size: 0,
    //                 }
    //             },
    //             fill: {
    //                 type: 'gradient',
    //                 gradient: {
    //                     gradientToColors: ['rgba(44, 47, 53,0)'],
    //                     inverseColors: false,
    //                     opacityFrom: 0.4,
    //                     opacityTo: 0,
    //                     stops: [20, 100]
    //                 },
    //             },
    //             legend: {
    //                 show: false,
    //             },
    //             yaxis: {
    //                 showForNullSeries: false,
    //                 labels: {
    //                     show: true,
    //                     minWidth: 35,
    //                     style: {
    //                         colors: 'rgba(255,255,255,0.5)',
    //                         fontSize: '11px',
    //                         fontWeight: 400,
    //                     },
    //                     formatter: (val) => { return val % 2 == 1 ? "On" : "Off" },
    //                 },
    //                 // min: 0,
    //                 // max: 1,
    //                 // tickAmount: 1
    //             },
    //             xaxis: {
    //                 type: 'datetime',
    //                 categories: DataALL.timeStamp ? DataALL.timeStamp : [],
    //                 tooltip: {
    //                     enabled: false,
    //                 },
    //                 labels: {
    //                     show: true,
    //                     hideOverlappingLabels: true,
    //                     showDuplicates: false,
    //                     trim: true,
    //                     style: {
    //                         colors: 'rgba(255,255,255,0.5)',
    //                         fontSize: '11px',
    //                         fontWeight: 400,
    //                     },
    //                     // format: 'dd MMM - hh:mm TT',
    //                     format: 'dd MMM yyyy',
    //                     formatter: undefined,
    //                     datetimeUTC: false,
    //                     datetimeFormatter: {
    //                         year: 'yyyy',
    //                         month: "MMM 'yy",
    //                         day: 'dd MMM',
    //                         hour: 'HH:mm',
    //                     },
    //                 },
    //                 axisTicks: {
    //                     show: false,
    //                     color: 'rgba(255, 255, 255, 0.2)',
    //                     height: 5,
    //                 },
    //                 axisBorder: {
    //                     show: false
    //                 }
    //             },
    //             grid: {
    //                 show: true,
    //                 borderColor: 'rgba(255, 255, 255, 0.05)',
    //                 xaxis: {
    //                     lines: {
    //                         show: true
    //                     }
    //                 },
    //                 yaxis: {
    //                     lines: {
    //                         show: false
    //                     }
    //                 },
    //             },
    //             theme: {
    //                 mode: 'dark',
    //             },
    //             tooltip: {
    //                 enabled: true,
    //                 inverseOrder: true,
    //                 style: {
    //                     fontSize: '12px'
    //                 },
    //                 x: {
    //                     show: false,
    //                 },
    //                 y: {
    //                     title: {
    //                         formatter: (seriesName) => seriesName + " :",
    //                     },
    //                 },
    //             },
    //             annotations: {
    //                 yaxis: [
    //                     {
    //                         y: 0,
    //                         strokeDashArray: 0,
    //                         borderColor: 'rgba(255, 255, 255, 0.05)',
    //                         borderWidth: 1.5,
    //                         opacity: 1
    //                     }
    //                 ]
    //             }
    //         }
    //     }
    // };
    // setDataChart(chartConfig)
    // // ApexCharts.exec('AIChart', 'updateOptions', chartConfig.AI, false, true);
    // // ApexCharts.exec('DIChart', 'updateOptions', chartConfig.DI, false, true);

    // $('#chartLoading').attr('style', 'visibility:hidden');
    // if ($('.chartLegend.DI-legend')[0]) {
    //     $('.chartLegend.DI-legend')[0].classList.remove('chartLegend-collapse')
    // }
    // if ($('.chartLegend.DI-legend')[1]) {
    //     $('.chartLegend.DI-legend')[1].classList.remove('chartLegend-collapse')
    // }
    // if ($('.chartLegend.DI-legend')[2]) {
    //     $('.chartLegend.DI-legend')[2].classList.remove('chartLegend-collapse')
    // }

    // // $('.chartLegend.' + type)[index].classList.add('chartLegend-collapse')
    // for (let i = 1; i < $('.chartLegend.AI-legend').length; i++) {
    //     $('.chartLegend.AI-legend')[i].classList.add('chartLegend-collapse')
    // }
    // for (let i = 1; i < $('.AI-sel-group')[0].children.length; i++) {
    //     $('.AI-sel-group')[0].children[i].children[1].classList.remove('active');
    //     $('.AI-sel-group')[0].children[i].children[1].children[0].innerText = "Select"
    //     // console.dir($('.AI-sel-group')[0].children[i].children[1])
    // }


    function toggleChart(e, index, type) {
        if (e.target.children[1].innerText == "Selected") {
            if ($('.chartLegend.' + type).length - $('.chartLegend.' + type + '.chartLegend-collapse').length > 1) {
                e.target.children[1].classList.remove('active');
                e.target.children[1].children[0].innerText = "Select"
                toggle(e, type.split('-')[0] + 'Chart')
                if ($('.chartLegend.' + type)[index].matches(".chartLegend-collapse")) {
                    $('.chartLegend.' + type)[index].classList.remove('chartLegend-collapse')
                } else {
                    $('.chartLegend.' + type)[index].classList.add('chartLegend-collapse')
                }
            }
        } else {
            e.target.children[1].classList.add('active');
            e.target.children[1].children[0].innerText = "Selected"
            toggle(e, type.split('-')[0] + 'Chart')
            if ($('.chartLegend.' + type)[index].matches(".chartLegend-collapse")) {
                $('.chartLegend.' + type)[index].classList.remove('chartLegend-collapse')
            } else {
                $('.chartLegend.' + type)[index].classList.add('chartLegend-collapse')
            }
        }
        // console.log(dataChart.DI.series)
        // console.log(type)
        // if (type == "DI-legend") {
        //     let length = 0;
        //     for (let i = 0; i < $('.DI-sel-group')[0].children.length; i++) {
        //         // console.log(i)
        //         // let obj = {
        //         //     active: $('.DI-sel-group')[0].children[i].children[1].innerText == "Selected" ? "Active" : "Inactive"
        //         // }
        //         // Arr.push(obj)
        //         // dataChart.DI.series[i].data
        //         if ($('.DI-sel-group')[0].children[i].children[1].innerText == "Selected") {
        //             for (let [k, d] of Object.entries(dataChart.DI.series[i].data)) {
        //                 dataChart.DI.series[i].data[k] = d + (length * 2)
        //                 console.log(length)
        //             }
        //             // dataChart.DI.series[i].data = d;
        //             length += 1;
        //         }
        //         // console.log(dataChart.DI.series)

        // ApexCharts.exec('DIChart', 'updateSeries', dataChart.DI.series, false, true);
        //         if ($('.DI-sel-group')[0].children[i].children[1].innerText == "Select") {
        //             // console.log($('.DI-sel-group')[0].children[i].children[0].innerText)
        //             for (let i = 1; i < dataChart.DI.series.length; i++) {
        //                 ApexCharts.exec('DIChart', 'hideSeries', $('.DI-sel-group')[0].children[i].children[0].innerText);
        //             }
        //             ApexCharts.exec('DIChart', 'updateOptions', {}, false, true);
        //         }
        //     }
        //     // 
        // }
    }

    const toggle = (e, type) => {
        const name = e.target.children[0].innerText;
        ApexCharts.exec(type, 'toggleSeries', name);
        ApexCharts.exec(type, 'updateOptions', {}, false, true);
    }

    function hoverChart(e, action, type, index) {
        if (e.target.children[1].innerText == "Selected") {
            if (action == "in") {
                $('#apexcharts' + type + ' .apexcharts-area-series .apexcharts-series:not([rel="' + (index + 1) + '"])').addClass('legend-mouseover-inactive');
            } else {
                $('#apexcharts' + type + ' .apexcharts-area-series .apexcharts-series:not([seriesName="' + (index + 1) + '"])').removeClass('legend-mouseover-inactive');
            }
        } else if (e.target.children[1].classList[3] == undefined && e.target.children[1].tagName == "SPAN") {
            if (action == "in") {
                $('#apexcharts' + type + ' .apexcharts-area-series .apexcharts-series:not([rel="' + (index + 1) + '"])').addClass('legend-mouseover-inactive');
            } else {
                $('#apexcharts' + type + ' .apexcharts-area-series .apexcharts-series:not([seriesName="' + (index + 1) + '"])').removeClass('legend-mouseover-inactive');
            }
        }
    }

    const FixedTime = (e, range) => {
        // if ($('.optionTimeSel')[0].className.includes('collapsed') == false) {
        // $('.optionTimeSel')[0].click()
        // }
        Active(e)

        let local = Date.now();
        let timeOffset = new Date().getTimezoneOffset() * 60 * 1000 * -1;
        let newEndDate = local + timeOffset;
        let newStartDate = new Date(newEndDate);
        if (range < 30) {
            newStartDate.setDate(newStartDate.getDate() - range);
        } else {
            newStartDate.setMonth(newStartDate.getMonth() - range / 30);
        }
        $('#startReq')[0].valueAsNumber = newStartDate -= newStartDate % 60000;
        $('#endReq')[0].valueAsNumber = newEndDate -= newEndDate % 60000;
    }


    const Active = (e) => {
        // $('.fixedTimeSel').removeClass('active')
        Inactive()
        // $('.optionTimeSel').removeClass('active')
        // if (e.target.className.includes('optionTimeSel')) {
        // $('.optionTimeSel').toggleClass('active')
        // } else {
        e.target.classList.add('active')
        // }
    }

    const Inactive = () => {
        $('.fixedTimeSel').removeClass('active')
    }

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row title mb-4 pb-3 mx-0 border-bottom">
                    <div className="col-6 px-0">
                        <h3>Device Chart</h3>
                    </div>
                    <div className="col-6 px-0 text-right">
                        <div className="float-right">
                            <button type="button" className="btn btn-sm text-custom-light rounded ml-2 fixedTimeSel" style={{ background: "rgba(100, 100, 110, 0.3)" }} onClick={exportDBCSV}>
                                <small className='mb-0'>Export CSV</small>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row waitload" style={{ visibility: 'hidden', height: 'calc(100vh - 250px)' }}>
                    <div className="col-12 col-xl-4 col-xxl-3">
                        <div className="row mx-0 h-100">
                            <div className="card flex-fill p-3 mb-xl-0">
                                <div className="card-header py-2 px-2">
                                    <h4 className="text-custom-important">Device Data Selector</h4>
                                </div>
                                <div className="card-body py-0 px-1">
                                    <div className="row mx-0 mb-3 border-bottom">
                                        <div className="flex-fill">
                                            <div className="row mx-0 mb-2 flex-fill">
                                                <div className="col-3 col-md-auto d-flex px-0 mr-md-2" style={{ width: "55px" }}>
                                                    <p className="text-custom my-auto py-1">Device :</p>
                                                </div>
                                                <div className="col-9 col-md-auto d-flex flex-fill px-0">
                                                    {/* <input className="form-control-lg w-100 rounded-lg deviceSel px-2" type="search" id="devReq" list="device-search" placeholder="Select Device...">
                                                    </input> */}
                                                    <select className="form-control-lg w-100 rounded-lg deviceSel px-2" id="devReq">
                                                        <option defaultValue className="text-muted">Select Device...</option>
                                                        {/* <datalist id="device-search"> */}
                                                        {
                                                            DeviceList.map((val, index) => (
                                                                <option value={val.id} key={index}>{val.label}</option>
                                                            ))
                                                        }
                                                        {/* </datalist> */}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-12 text-center">
                                                    <button className="btn col-auto my-1 pt-0 pb-1 text-center text-custom-light px-2 rounded cursor-pointer mx-1 fixedTimeSel" style={{ background: "rgba(100,100,110,0.30)" }} onClick={(e) => { FixedTime(e, 7) }}>
                                                        <small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>7 days</small>
                                                    </button>
                                                    <button className="btn col-auto my-1 pt-0 pb-1 text-center text-custom-light px-2 rounded cursor-pointer mx-1 fixedTimeSel" style={{ background: "rgba(100,100,110,0.30)" }} onClick={(e) => { FixedTime(e, 30) }}>
                                                        <small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>1 month</small>
                                                    </button>
                                                    <button className="btn col-auto my-1 pt-0 pb-1 text-center text-custom-light px-2 rounded cursor-pointer mx-1 fixedTimeSel" style={{ background: "rgba(100,100,110,0.30)" }} onClick={(e) => { FixedTime(e, 90) }}>
                                                        <small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>3 months</small>
                                                    </button>
                                                    <button className="btn col-auto my-1 pt-0 pb-1 text-center text-custom-light px-2 rounded cursor-pointer mx-1 fixedTimeSel" style={{ background: "rgba(100,100,110,0.30)" }} onClick={(e) => { FixedTime(e, 180) }}>
                                                        <small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>6 months</small>
                                                    </button>
                                                    {/* <button className="btn col-auto my-2 pt-0 pb-1 text-center text-custom px-2 rounded-pill cursor-pointer mx-1 optionTimeSel" style={{ background: "rgba(0,0,0,0.25)" }} onClick={(e) => { Active(e) }} data-toggle="collapse" href="#timeSelCustom" aria-expanded="false">
                                                        <small className="pt-0 mb-1 mx-1" style={{ pointerEvents: 'none' }}>More option</small>
                                                    </button> */}
                                                </div>
                                            </div>
                                            <div id="timeSelCustom">
                                                <div className="row mx-0 mb-2 flex-fill">
                                                    <div className="col-3 col-md-auto d-flex px-0 mr-md-2" style={{ width: "75px" }}>
                                                        <p className="text-custom my-auto py-1">From :</p>
                                                    </div>
                                                    <div className="col-9 col-md-auto d-flex flex-fill px-0">
                                                        <input className="form-control-lg w-100 rounded-lg dateSel px-2" id="startReq" onChange={Inactive} type="datetime-local"></input>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 mb-3 flex-fill">
                                                    <div className="col-3 col-md-auto d-flex px-0 mr-md-2" style={{ width: "75px" }}>
                                                        <p className="text-custom my-auto py-1">To :</p>
                                                    </div>
                                                    <div className="col-9 col-md-auto d-flex flex-fill px-0">
                                                        <input className="form-control-lg w-100 rounded-lg dateSel px-2" id="endReq" onChange={Inactive} type="datetime-local"></input>
                                                    </div>
                                                </div>
                                                {/* <div className="row mx-0 mb-3 flex-fill">
                                                    <div className="col-3 col-md-auto d-flex px-0 mr-md-2" style={{ width: "75px" }}>
                                                        <p className="text-custom my-auto py-1">Sampling :</p>
                                                    </div>
                                                    <div className="col-9 col-md-auto d-flex flex-fill px-0">
                                                        <input className="form-control-lg w-100 rounded-lg dateSel px-2" id="sampReq" onChange={Inactive} type="number" autoComplete="off"></input>
                                                        <span className="pt-2" style={{ marginLeft: '-65px' }}>Minutes</span>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <button className="btn mt-0 mb-4 btn-lg btn-primary w-100" type="submit" onClick={request}>Confirm</button>
                                            {/* <button className="btn mt-0 mb-4 btn-lg btn-primary w-100" type="submit">Confirm</button> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 dataSel-wrapper overflow-custom">
                                            <div className="row mx-0" style={dataChart.AI ? { display: "flex" } : { display: "none" }}>
                                                <div className="w-100 mt-2 mb-0 text-custom py-2 px-3 rounded-lg cursor-pointer AI-group" style={{ background: "rgba(0,0,0,0.2)" }} data-toggle="collapse" href="#collapseAI" aria-expanded="false">
                                                    <strong className="text-custom-light">Analog Value</strong>
                                                    <i className="fa fa-angle-down float-right pt-1"></i>
                                                </div>
                                                <div className="collapse w-100 rounded-bottom-lg mx-1 AI-sel-group" style={{ background: "rgba(0,0,0,0.075)" }} id="collapseAI">
                                                    {
                                                        dataChart.AI ? dataChart.AI.series.map((val, index) => (
                                                            <div className={index > 0 ? "text-custom px-2 py-2 my-1 mx-2 border-top" : "text-custom px-2 py-2 my-1 mx-2"} style={{ cursor: "pointer" }} key={index} onClick={(e) => { toggleChart(e, index, 'AI-legend') }} onMouseEnter={(e) => { hoverChart(e, "in", dataChart.AI.options.chart.id, index) }} onMouseLeave={(e) => { hoverChart(e, "out", dataChart.AI.options.chart.id, index) }}>
                                                                <p className="d-inline" style={{ pointerEvents: "none" }}>{val.name}</p>
                                                                <span className={index == 0 ? "float-right text-muted text-center AI-toggle active" : "float-right text-muted text-center AI-toggle"} style={{ width: "50px", pointerEvents: "none" }}>
                                                                    <small style={{ pointerEvents: "none" }}>{index == 0 ? "Selected" : "Select"}</small>
                                                                </span>
                                                            </div>
                                                        )) :
                                                            <div style={{ display: "none" }}>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className="row mx-0">
                                                <div className="w-100 mt-2 mb-0 text-custom py-2 px-3 rounded-lg cursor-pointer DI-group" style={{ background: "rgba(0,0,0,0.2)" }} data-toggle="collapse" href="#collapseDI" aria-expanded="false" aria-controls="collapseDI">
                                                    <strong className="text-custom-light">Digital Value</strong>
                                                    <i className="fa fa-angle-down float-right pt-1"></i>
                                                </div>
                                                <div className="collapse w-100 rounded-bottom-lg mx-1 DI-sel-group" style={{ background: "rgba(0,0,0,0.075)" }} id="collapseDI">
                                                    {
                                                        dataChart.DI ? dataChart.DI.series.map((val, index) => (
                                                            <div className={index > 0 ? "text-custom px-2 py-2 my-1 mx-2 border-top" : "text-custom px-2 py-2 my-1 mx-2"} style={{ cursor: "pointer" }} key={index} onClick={(e) => { toggleChart(e, index, 'DI-legend'); checkActiveDI($('.chartLegend.DI-legend:not(.chartLegend-collapse)').length) }} onMouseEnter={(e) => { hoverChart(e, "in", dataChart.DI.options.chart.id, index) }} onMouseLeave={(e) => { hoverChart(e, "out", dataChart.DI.options.chart.id, index) }}>
                                                                <p className="d-inline" style={{ pointerEvents: "none" }}>{val.name}</p>
                                                                <span className={index == 0 ? "float-right text-muted text-center DI-toggle active" : "float-right text-muted text-center DI-toggle"} style={{ width: "50px", pointerEvents: "none" }}>
                                                                    <small style={{ pointerEvents: "none" }}>{index == 0 ? "Selected" : "Select"}</small>
                                                                </span>
                                                            </div>
                                                        )) :
                                                            <div style={{ display: "none" }}>
                                                            </div>
                                                    }
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-8 col-xxl-9 px-4">
                        <div className="row">
                            <div className="card flex-fill w-100">
                                <div className="card-header pt-2 pb-0 px-2 mt-3 mx-3">
                                    <h4 className="text-custom-important">Chart</h4>
                                </div>
                                <div className="card-body px-0 px-md-2 pt-0 pb-4">
                                    <div id="chart" className="col-12" style={{ height: "auto" }} >
                                        <ApexChart data={dataChart} />
                                    </div>
                                    {/* <div id="chart" className="col-12" style={{ height: "auto" }} >
                                        <div id="AIChart-wrapper">
                                            <div className="row" style={{ height: '100%' }}>
                                                <div className="my-auto mx-auto text-center">
                                                    <h2 className="text-muted">Please, Select Data...</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="load" className="col-12 d-none" style={{ height: "auto" }} >
                                        <div id="AIChart-wrapper">
                                            <div className="row" style={{ height: '100%' }}>
                                                <div className="my-auto mx-auto text-center">
                                                    <i className="fa fa-circle-notch fa-spin fa-3x fa-fw" style={{ opacity: "0.5" }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="test" className="col-12 d-none" style={{ height: "auto" }} >
                                        <div id="AIChart-wrapper">
                                            <div className="row" style={{ height: '100%' }}>
                                                <div className="my-auto mx-auto text-center">
                                                    <h2 className="text-muted">No data</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="card flex-fill p-3 mb-0">
                                <div className="card-header py-2 px-2">
                                    <h4 className="text-custom-important">Legend</h4>
                                </div>
                                <div className="card-body px-0 px-md-2 py-0 py-md-1 mb-1">
                                    <div className="row px-1">
                                        {
                                            dataChart.AI ? dataChart.AI.series.map((val, index) => (
                                                <div className="col-auto my-2 my-xl-2 mx-2 text-center text-sm-left chartLegend AI-legend" key={index} style={{ cursor: "default" }} onMouseEnter={(e) => { hoverChart(e, "in", dataChart.AI.options.chart.id, index) }} onMouseLeave={(e) => { hoverChart(e, "out", dataChart.AI.options.chart.id, index) }}>
                                                    <svg style={{ pointerEvents: 'none' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20" fill="none" stroke={dataChart.AI.options.colors[index]} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><path d="M 0.35355339,14.500001 7.2878812,7.5656732 12.712119,12.434327 19.646447,5.499999" /></svg>
                                                    <span className="ml-2" style={{ pointerEvents: 'none' }}>{val.name}</span>
                                                </div>
                                            )) :
                                                <div style={{ display: "none" }}></div>
                                        }
                                        {
                                            dataChart.DI ? dataChart.DI.series.map((val, index) => (
                                                // console.log(val)
                                                <div className="col-auto my-2 my-xl-2 mx-2 text-center text-sm-left chartLegend DI-legend" key={index} style={{ cursor: "default" }} onMouseEnter={(e) => { hoverChart(e, "in", dataChart.DI.options.chart.id, index) }} onMouseLeave={(e) => { hoverChart(e, "out", dataChart.DI.options.chart.id, index) }}>
                                                    <svg style={{ pointerEvents: 'none' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20" fill="none" stroke={dataChart.DI.options.colors[index]} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><path d="M 1.2306976e-7,14.500001 H 5.2240297 V 5.499999 H 14.77597 v 9.000002 H 20" /></svg>
                                                    <span className="ml-2" style={{ pointerEvents: 'none' }}>{val.name}</span>
                                                </div>
                                            )) :
                                                <div style={{ display: "none" }}></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Content
