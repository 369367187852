import React, { useState, useEffect } from 'react'
import ApexChart from "./chart_apex";

function ChartCard(props) {
    const [TimeOut, setTimeOut] = useState(false);

    if (props.data) {
        return (
            <>
                <div className="row border-bottom border-custom">
                    <div className={Object.keys(props.data).length > 1 ? "col-12 col-sm-6" : "col-12"}>
                        <div className={Object.keys(props.data).length > 1 ? "col-12 text-center mb-4 mt-2 py-1" : "col-12 text-center mb-4 mt-2 py-1"}>
                            <p className="text-custom-light mb-1">{Object.keys(props.data)[0]}</p>
                            <h3 className="mt-2 mb-1" style={{ color: '#4ECDC4' }}>{props.data[Object.keys(props.data)[0]]}</h3>
                        </div>
                    </div>
                    {
                        Object.keys(props.data).length > 1 ?
                            <div className="d-none d-sm-flex col-6">
                                <div className="col-12 text-center mb-4 mt-2 py-1 border-left border-custom">
                                    <p className="text-custom-light mb-1">{Object.keys(props.data)[1]}</p>
                                    <h3 className="mt-2 mb-1" style={{ color: '#5F81E5' }}>{props.data[Object.keys(props.data)[1]]}</h3>
                                    {/* <p className={(props.data[Object.keys(props.data)[1]].val - deviceData.data.sp[Object.keys(deviceData.data.sp)[0]].val) > 0 ? "text-success mb-0" : "text-danger mb-0"}>{(deviceData.data.ai[Object.keys(deviceData.data.ai)[1]].val - deviceData.data.sp[Object.keys(deviceData.data.sp)[0]].val) < 0 ? (deviceData.data.ai[Object.keys(deviceData.data.ai)[1]].val - deviceData.data.sp[Object.keys(deviceData.data.sp)[0]].val).toFixed(1) : "+" + (deviceData.data.ai[Object.keys(deviceData.data.ai)[1]].val - deviceData.data.sp[Object.keys(deviceData.data.sp)[0]].val).toFixed(1)} {deviceData.data.ai[Object.keys(deviceData.data.ai)[1]].unit}</p> */}
                                </div>
                            </div> :
                            <></>
                    }
                </div>
                <div className="row mt-4 text-center">
                    <div id="chart" className="col-12" style={{ height: "auto" }} >
                        <ApexChart lib={props.lib} />
                    </div>
                </div>
            </>
        )
    } else if (TimeOut == false) {
        setTimeout(function () {
            setTimeOut(true)
        }, 5000)
        return (
            <div className="row my-0">
                <div className="col-12 px-0">
                    <div className="flex-fill text-center my-0 mx-0">
                        <div className="loading my-0 mx-0" style={{ height: '357.5px' }}></div>
                    </div>
                </div>
            </div>
        )
    } else if (TimeOut) {
        return (
            <div className="container-fluid p-0">
                <div className="row my-0" style={{ height: '357.5px' }}>
                    <div className="my-auto mx-auto text-center">
                        <h3 className="text-muted">No data</h3>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChartCard